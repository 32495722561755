import DeleteButton from '../../DeleteButton/button';
import IconButton from '../../IconButton/button';
import './style.scss';
import { CartData } from '../../../../data/cart-content';

const CartRow = ({ id, urlImg, name, size, count, price, onDeleteItem}) => {

    return (
        <>
            <div className="cart-row">
                <div className="product-image">
                    <img src={typeof (urlImg) == typeof ([]) ? urlImg[0] : urlImg} />
                </div>
                <div class='product-code'>{name} ({size})</div>
                <div>Шт: {count}</div>
                <div className="price-column">
                    <div className="single-row-price">Ціна за одиницю: <span className='bold-text'>{typeof(price) == typeof(0) ? `${price} грн` : `${price}`}</span></div>
                    <div className="total-row-price">Всього: <span className='bold-text'>{typeof(price) == typeof(0) ? `${price * count} ₴` : `${price}`}</span></div>
                </div>
                <div onClick={(e) => onDeleteItem(e, id)} style={{cursor:'pointer'}} >
                    <DeleteButton width={'30px'} height={'30px'} />
                </div>
            </div>
        </>

    )
}

export default CartRow;