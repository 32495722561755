import React from 'react';
import './style.scss';
import {
    BronzeProducts, PolishingProducts, ForArtisticWorks, BronzeProductsForFilterBlock, PolissingProductsForFilterBlock, ForArtisticWorksForFilterBlock, Perm, PermForFilterBlock, DecorativeProductsGranite, DecorativeProductsGraniteForFilterBlock, DecorativeProducts, DecorativeProductsForFilterBlock, PersonalEquipment, PersonalEquipmentForFilterBlock, Tools, ToolsForFilterBlock, Others, OthersForFilterBlock
} from '../../../../logic/const/constants';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../../logic/hooks/useQuery';

const FilterBlock = ({ visible, setVisible, onChangeMinPrice, onChangeMaxPrice }) => {
    const query = useQuery();
    const category = query.get('category')
    let subCategory = BronzeProductsForFilterBlock;

    switch (category) {
        case (BronzeProducts):
            subCategory = BronzeProductsForFilterBlock;
            break;
        case (PolishingProducts):
            subCategory = PolissingProductsForFilterBlock;
            break;
        case (ForArtisticWorks):
            subCategory = ForArtisticWorksForFilterBlock;
            break;
        case (Perm):
            subCategory = PermForFilterBlock;
            break;
        case (DecorativeProductsGranite):
            subCategory = DecorativeProductsGraniteForFilterBlock;
            break;
        case (PersonalEquipment):
            subCategory = PersonalEquipmentForFilterBlock;
            break;
        case (Tools):
            subCategory = ToolsForFilterBlock;
            break;
        case (Others):
            subCategory = OthersForFilterBlock;
            break;
        default: subCategory = [];
    }

    const renderItems = () => {
        return subCategory.map(x => {
            return <li><Link to={`?category=${category}&subCategory=${x[0]}`}>{x[1]}</Link></li>
        });
    }



    return (
        <>
            <div className={`filter-block ${visible == true ? 'show' : ''}`}>
                <div className="close-button" onClick={() => setVisible(false)}>X</div>
                <div className="filter">
                    <div className="title">
                        КАТЕГОРІЯ
                    </div>
                    <ul>
                        <li><Link to={`?category=${BronzeProducts}`}>Вироби з бронзи, латуні</Link></li>
                        <li><Link to={`?category=${PolishingProducts}`}>Вироби для полірування та шліфування</Link></li>
                        <li><Link to={`?category=${ForArtisticWorks}`}>Для художніх робіт</Link></li>
                        <li><Link to={`?category=${Perm}`}>Хімія</Link></li>
                        <li><Link to={`?category=${DecorativeProductsGranite}`}>Декоративні вироби з граніту</Link></li>
                        <li><Link to={`?category=${PersonalEquipment}`}>Засоби індивідуального захисту</Link></li>
                        <li><Link to={`?category=${Tools}`}>Інструменти</Link></li>
                        <li><Link to={`?category=${Others}`}>Інше</Link></li>
                    </ul>
                </div>
                <div className="filter">
                    <div className="title">
                        ТИП ТОВАРА
                    </div>
                    <ul>
                        {renderItems()}
                    </ul>
                </div>
                <div className="filter">
                    <div className="title">
                        ЦІНА
                    </div>
                    <div className="price-input-group">
                        <input type="number" placeholder='Від' onChange={e => onChangeMinPrice(e.target.value)}></input>
                        <input type="number" placeholder='До' onChange={e => onChangeMaxPrice(e.target.value)}></input>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterBlock;