import React from 'react';
import './style.scss';

const CommonLayout = (props) => {

    const background = props.backgroundColor ? props.backgroundColor : "#181819";

    return (
        <>
            <main className="content" style={{ background: background }}>
                {props.children}
            </main>
        </>
    )
}

export default CommonLayout;