import React from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';

const DeliveryPage = () => {
    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="delivery-container">
                    <h2>ДОСТАВКА І ОПЛАТА</h2>
                    <section className='delivery'>
                        <p>СПОСОБИ ДОСТАВКИ</p>
                        <div className="info-wrapper">
                            <div className="info-item">
                                <div className="info-item-col">
                                    <div className="info-item-col-image">
                                        <img src='/images/nova-poshta.svg' height="auto" width="100%" />
                                    </div>
                                    <div className="info-item-col-value">
                                        Доставка на віділення Нової Пошти
                                    </div>
                                </div>
                            </div>
                            <div className="info-item">
                                <div className="info-item-col">
                                    <div className="info-item-col-image">
                                        <img src='/images/urk-poshta.png' height="auto" width="100%" />
                                    </div>
                                    <div className="info-item-col-value">
                                        Доставка на віділення Укр Пошти
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='revert-text'>Повернення здіснюється протягом 14-ти днів</p>
                    </section>
                    <section className='payments'>
                        <p>СПОСОБИ ОПЛАТИ</p>
                        <div className="info-wrapper second">
                            <div className="info-item">
                                <div className="info-item-col">
                                    <div className="info-item-col-image">
                                        <img src='/icons/nalozhka.svg' width="150px" />
                                    </div>
                                    <div className="info-item-col-value">
                                        Накладеним платежом у відділенні поштової служби
                                    </div>
                                </div>
                            </div>
                            <div className="info-item">
                                <div className="info-item-col">
                                    <div className="info-item-col-image">
                                        <img src='/icons/cards.svg' width="150px"  />
                                    </div>
                                    <div className="info-item-col-value">
                                        Переказом на банківську карту
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </CommonLayout>
        </>
    )
}

export default DeliveryPage;