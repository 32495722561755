import React, { useEffect, useState } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { CatalogData } from '../../data/catalog';
import Icon from '../../components/Common/Icon/icon';
import PrimaryButton from '../../components/Common/Button/primary-button';
import { useQuery } from '../../logic/hooks/useQuery';
import { CartData } from '../../data/cart-content';

const DetailProduct = ({ cartData, setCartData }) => {
    const { id } = useParams();
    const query = useQuery();
    const product = CatalogData.find(x => x.id == id);
    const navigate = useNavigate();
    const [imageCount, setImageCount] = useState(0)


    const AddProductToCart = () => {
        let index = cartData.findIndex(x => x.id == product.id);
        debugger
        if (index != -1) {
            var newCart = [...cartData];
            newCart[index].count += 1
            setCartData(newCart)
        } else {
            setCartData(cartData => [...cartData, { ...product, count: 1 }]);
        }
        console.log(CartData)
    }

    const nextImage = () => {
        if (product.urlImg.length - 1 > imageCount) {
            setImageCount(imageCount + 1)
        } else {
            setImageCount(0)
        }
    }

    const previousImage = () => {
        if (imageCount != 0) {
            setImageCount(imageCount - 1)
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {

    }, [imageCount])

    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="detail-product-container">
                    <div className='navigation' onClick={() => navigate(-1)}>← Повернутись до каталогу</div>
                    <div className="product-block">
                        <div className="product-image">
                            <div className="arrow-wrapper" onClick={previousImage} style={{ display: typeof (product.urlImg) == typeof ([]) ? "" : "none" }}>
                                <Icon iconUrl={"/icons/arrow-left.svg"} width={"40px"} height={"40px"}></Icon>
                            </div>

                            {
                                product.urlImg[imageCount].endsWith('.mp4') ?
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        poster="system/loader.gif"
                                        style={{ display: !product.urlImg[imageCount].endsWith('.mp4') && 'none' }}
                                    >
                                        <source
                                            src={product.urlImg[imageCount]}
                                            type="video/mp4"
                                        />
                                    </video>
                                    :
                                    <img src={typeof (product.urlImg) == typeof ([])
                                        ? product.urlImg[imageCount]
                                        : product.urlImg} style={{ display: product.urlImg[imageCount].endsWith('.mp4') && 'none' }}></img>
                            }




                            <div className="arrow-wrapper" onClick={nextImage} style={{ display: typeof (product.urlImg) == typeof ([]) ? "" : "none" }}>
                                <Icon iconUrl={"/icons/arrow-right.svg"} width={"40px"} height={"40px"}></Icon>
                            </div>
                        </div>
                        <div className="product-detail">
                            <h2>{product.name}</h2>
                            <div className="price">Ціна: {typeof (product.price) == typeof (0) ? `${product.price} грн` : `${product.price}`}</div>
                            <div className="sizes">
                                Розміри: {product.sizes}
                            </div>
                            <div className="desc">
                                {product.desc}
                            </div>
                            <div onClick={AddProductToCart}>
                                <PrimaryButton width={"50%"} height={"50px"} text={'Додати в кошик'}></PrimaryButton>
                            </div>
                            <div className="line"></div>
                            <div className="conditions">
                                <div className="temp">
                                    <Icon iconUrl={"/icons/delivery.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Доставка в межах України здійснюється собі вартістю транспорту або поштовими службами (Нова Пошта, УкрПошта, ІнТайм та інші).
                                    </p>
                                </div>
                                <div className="temp">
                                    <Icon iconUrl={"/icons/money.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Ми приймаємо різні види платежів: готівка, наложений платіж, переказ на банківський рахунок, оплата картками Visa/MasterCard                                </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default DetailProduct;