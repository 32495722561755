import './style.scss';

const DeleteButton = ({width, height}) => {
    return (
        <>
            <button className="delete-icon-button" >
                <img src={'/icons/delete-icon.svg'} width={width} height={height}/>
            </button>
        </>
    )
}

export default DeleteButton;