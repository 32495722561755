import IconButton from '../Common/IconButton/button';
import './style.scss';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-wrapper">
                    <div className="logo-footer-menu">
                        <img src={'images/logo.png'}></img>
                    </div>
                    <div className="footer-contact">
                        <div className="contact-item"><IconButton iconUrl={'icons/phone.svg'} width={25} height={25} />
                            <div>
                                <a href="callto:+380665109085">(066) 510 90 85</a><br />
                                <a href="callto:+380965272215">(096) 527 22 15</a>
                            </div>
                        </div>

                        <div className="contact-item"><IconButton iconUrl={'icons/house.svg'} width={25} height={25} />
                            Україна, Житомирська обл., м.Коростишів, вул.Героїв Небесної Сотні, 54.</div>
                    </div>
                    <div className="footer-contact">
                        <div className="contact-item"><IconButton iconUrl={'icons/time.svg'} width={25} height={25} />
                            Пн. - Пт. <br />8:00 - 19:00<br />

                            Сб.<br />8:00 - 18:00</div>
                        <div className="contact-item"><IconButton iconUrl={'icons/email.svg'} width={25} height={25} />diatools@email.ua</div>
                    </div>
                </div>
                <p className='developed-by'>Developed by <a href='https://krst-it.com/'>KRST IT</a></p>
            </footer>
        </>
    )
}

export default Footer;