import React from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';
import IconButton from '../../components/Common/IconButton/button';

const ContactsPage = () => {
    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="contacts-container">
                    <h2>КОНТАКТИ</h2>
                    <p>ІНТЕРНЕТ-МАГАЗИН</p>
                    <div className="info-wrapper">
                        <div className="info-item">
                            <div className="info-item-icon">
                                <IconButton iconUrl={'icons/phone.svg'} width={25} height={25} />
                            </div>
                            <div className="info-item-col">
                                <div className="info-item-col-title">
                                    Телефон:
                                </div>
                                <div className="info-item-col-value">
                                    +38 (066) 510 90 85
                                </div>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="info-item-icon">
                                <IconButton iconUrl={'icons/email.svg'} width={25} height={25} />
                            </div>
                            <div className="info-item-col">
                                <div className="info-item-col-title">
                                    Почта:
                                </div>
                                <div className="info-item-col-value">
                                    diatools@email.ua
                                </div>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="info-item-icon">
                                <IconButton iconUrl={'icons/time.svg'} width={25} height={25} />
                            </div>
                            <div className="info-item-col">
                                <div className="info-item-col-title">
                                    Робочі години:
                                </div>
                                <div className="info-item-col-value">
                                    Пн - Пт: 8:00 - 19:00<br />
                                    Сб: 8:00 - 18:00
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contact-maps'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36314.50422662317!2d29.062627857615134!3d50.31799412490929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472c8186d45b48e3%3A0x233ea1b6fc637101!2z0LLRg9C70LjRhtGPINCT0LXRgNC-0ZfQsiDQndC10LHQtdGB0L3QvtGXINCh0L7RgtC90ZYsIDU0LCDQmtC-0YDQvtGB0YLQuNGI0ZbQsiwg0JbQuNGC0L7QvNC40YDRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgMTI1MDE!5e0!3m2!1sru!2sua!4v1617944605629!5m2!1sru!2sua" width="100%" height="500" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default ContactsPage;