import { Aprons, Balls, Beets, Books, BronzeProducts, Buffs, Butchers, Catalogs, Chisels, Cleaner, Corners, Crosses, CrossesGranite, Crucifixes, Crystallizers, DecorativeProducts, DecorativeProductsGranite, DiamondCutter, DiamondDrills, DiamondSharpeningWheels, Double, Drills, DryCuts, EngravingMachines, Faces, Felts, Fickerts, ForArtisticWorks, Frames, Glasses, Gloves, Glue, Headphones, Icons, Impregnations, Lamps, Layers, Letters, Maps, Matrices, Monuments, Others, Overlays, PackagingMaterials, Paint, Paintings, Perm, PersonalEquipment, Plastic, Points, Polishes, PolishingProducts, Powder, Respirators, Roses, Saws, Scraps, Single, Souvenirs, Spheres, Statuettes, Stupki, Tools, Tridents, Vases, Velcro } from "../logic/const/constants";

export const CatalogData = [
    

{
    id: "8",
    name: "Хрест з розп Алб",
    sizes: "30см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест 30см з розп Альб 600грн.jpg",
    price: 600
},
{
    id: "9",
    name: "Хрест Алб б.р",
    sizes: "30х12",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест 30х12 Алб б.р550грн.jpg",
    price: 550
},
{
    id: "10",
    name: "Хрест Алб хром",
    sizes: "40см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест 40см Алб(хром) 2400грн.jpg",
    price: 2400
},
{
    id: "11",
    name: "Хрест б.р Алб",
    sizes: "50см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест 50см б.р Алб 2000грн.jpg",
    price: 2000
},
{
    id: "12",
    name: "Хрест б.р Алб(хром)",
    sizes: "50см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест 50см б.р Алб(хром).jpg",
    price: 1700
},
{
    id: "18",
    name: "Хрест NAZ H3",
    sizes: "20см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест NAZ H3 20см 370грн.jpg",
    price: 420
},
{
    id: "20",
    name: "Хрест NAZ H5 б.р",
    sizes: "40см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест NAZ H5 40см б.р 750грн.jpg",
    price: 750
},
{
    id: "21",
    name: "Хрест №2 б.р",
    sizes: "17х8.5",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест №2 б.р 17х8.5 360грн.jpg",
    price: 360
},
{
    id: "22",
    name: "Хрест №21 з розп'яттям",
    sizes: "28.5х11.5",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест №21 з розп_яттям 28.5х11.5 550грн.jpg",
    price: 550
},
{
    id: "23",
    name: "Хрест №46",
    sizes: "42х16.5",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест №46 42х16.5 650грн.jpg",
    price: 650
},
{
    id: "24",
    name: "Хрест №63",
    sizes: "60х26.5",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест №63 60х26.5 3500грн.jpg",
    price: 3500
},
{
    id: "25",
    name: "Хрест Альб П-02",
    sizes: "25см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест Альб 25см П-02 550грн.jpg",
    price: 550
},
{
    id: "26",
    name: "Хрест Альб хром б.р",
    sizes: "30см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест альб 30см хром б.р 850грн.jpg",
    price: 850
},
{
    id: "27",
    name: "Хрест до лампадки(2)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест до лампадки (2).jpg",
    price: 60
},
{
    id: "28",
    name: "Хрест для лампадки",
    sizes: "",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест до лампадки.jpg",
    price: 60
},
{
    id: "35",
    name: "Хрест Італія Р",
    sizes: "40см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест Італія Р.40см 1100грн.jpg",
    price: 1100
},
{
    id: "36",
    name: "Хрест К01 ELITE",
    sizes: "20х8",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К01 20х8 ELITE 1150грн.jpg",
    price: 1150
},
{
    id: "37",
    name: "Хрест К01 ELITE",
    sizes: "39х15",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К01 39х15 ELITE.jpg",
    price: 1400
},
{
    id: "39",
    name: "Хрест К03  ELITE ",
    sizes: "25х12",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К03 25х12 ELITE.jpg",
    price: 900
},
{
    id: "40",
    name: "Хрест К04  ELITE ",
    sizes: "28х15",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К04 28х15 ELITE.jpg",
    price: 1000
},
{
    id: "41",
    name: "Хрест К06  ELITE",
    sizes: "20х8",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К06 20х8 ELITE 470грн.jpg",
    price: 470
},
{
    id: "42",
    name: "Хрест К07  ELITE",
    sizes: "15х7",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К07 15х7 ELITE 530грн.jpg",
    price: 530
},
{
    id: "43",
    name: "Хрест К07  ELITE",
    sizes: "20х8",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К07 20х8 ELITE 535грн.jpg",
    price: 600
},
{
    id: "44",
    name: "Хрест К08 б.р  ELITE",
    sizes: "40х19",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К08 40х19 б.р ELITE 750грн.jpg",
    price: 900
},
{
    id: "4417",
    name: "Хрест К09  ELITE",
    sizes: "40х17",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К09 40х17 ELITE.jpg",
    price: 1100
},
{
    id: "4418",
    name: "Хрест К13 Gaggiati",
    sizes: "43x23",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К13 43x23 Gaggiati.jpg",
    price: 1400
},
{
    id: "4419",
    name: "Хрест К14 ELITE",
    sizes: "49х19",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест К14 49х19 ELITE.jpg",
    price: 1450
},
{
    id: "4420",
    name: "Хрест КР1 ELITE",
    sizes: "22х9",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест КР1 22х9 ELITE 640грн.jpg",
    price: 680
},
{
    id: "4421",
    name: "Хрест КP2 з квіткою",
    sizes: "29x15",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест КР2 29х15 з квіткою.jpg",
    price: 900
},
{
    id: "4422",
    name: "Хрест КР3",
    sizes: "40х19",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест КР3 40х19.jpg",
    price: 1400
},
{
    id: "4424",
    name: "Хрест КР6 ELITE",
    sizes: "30х12",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест КР6 30х12 ELITE.jpg",
    price: 1130
},
{
    id: "4426",
    name: "Хрест на підставці А",
    sizes: "55см",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест на підставці 55см А.jpg",
    price: 3000
},
{
    id: "4427",
    name: "Хрест ХП1 ELITE",
    sizes: "20x7",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест ХП1 20х7 ELITE 520грн.jpg",
    price: 550
},
{
    id: "4428",
    name: "Хрест ХП2 ELITE",
    sizes: "35х17",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест ХП2 35х17 ELITE.jpg",
    price: 1150
},
{
    id: "4429",
    name: "Хрест хром Алб б.р",
    sizes: "40х17",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрест хром 40х17 Алб б.р 850грн.jpg",
    price: 880
},
{
    id: "4430",
    name: "Хрест православні до лампадки",
    sizes: "",
    category: BronzeProducts,
    subCategory: Crosses,
    urlImg: "/images/Хрести православні до лампадки.jpg",
    price:90
},
{
    id: "45",
    name: "Троянда Альб П-47(права)",
    sizes: "45см",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда 45см Альб П-47 права 3500грн.jpg",
    price: 3700
},
{
    id: "46",
    name: "Троянда Алб 1987(ліва)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Алб 1987 1300грн.jpg",
    price: 1300
},
{
    id: "47",
    name: "Троянда Алб 1987(права)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Алб 1987 1300грн.jpg",
    price: 1300
},
{
    id: "48",
    name: "Троянда Алб 1989(ліва)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Алб 1989 ліва 1350грн.jpg",
    price: 1350
},
{
    id: "49",
    name: "Троянда Алб 1989(права)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Алб 1989 права 1350грн.jpg",
    price: 1350
},
{
    id: "50",
    name: "Троянда Алб П13 П14(ліва)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Алб П13 П14 ліва 650грн.jpg",
    price: 650
},
{
    id: "51",
    name: "Троянда Альб хром(права)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Альб хром права 730грн.jpg",
    price: 730
},
{
    id: "55",
    name: "Троянда P3",
    sizes: "21х8",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р3 21х8 500грн.jpg",
    price: 500
},
{
    id: "56",
    name: "Троянда P4(права)",
    sizes: "24х10",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р4 24х10(права) 700грн.jpg",
    price: 700
},
{
    id: "57",
    name: "Троянда P4(ліва)",
    sizes: "32x12",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р4 32х12 ліва 1250грн.jpg",
    price: 1250
},
{
    id: "58",
    name: "Троянда P4(права)",
    sizes: "32x12",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р4 32х12 права 1250грн.jpg",
    price: 1250
},
{
    id: "59",
    name: "Троянда P4(ліва)",
    sizes: "29x9",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р8 29х9 ліва 1180грн.jpg",
    price: 1180
},
{
    id: "60",
    name: "Троянда P4(права)",
    sizes: "29x9",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р8 29х9 Права 1180грн.jpg",
    price: 1180
},
{
    id: "61",
    name: "Троянда P9",
    sizes: "22x6",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р9 22х6 500грн.jpg",
    price: 500
},
{
    id: "62",
    name: "Троянда P11",
    sizes: "35см",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Р11 35см 2000грн.jpg",
    price: 2000
},
{
    id: "63",
    name: "Троянда Рука П-40(ліва)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда рука П-40 ліва 1330грн.jpg",
    price: 1330
},
{
    id: "64",
    name: "Троянда хром альб",
    sizes: "30см",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда хром 30см Альб ліва 2300грн.jpg",
    price: 2300
},
{
    id: "65",
    name: "Троянда Хром Альб",
    sizes: "45см",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда хром 45см альб ліва 4200грн.jpg",
    price: 4200
},
{
    id: "66",
    name: "Троянда Gg Італія(29346/19)",
    sizes: "",
    category: BronzeProducts,
    subCategory: Roses,
    urlImg: "/images/Троянда Gg Італія 1800грн.jpg",
    price: 1800
},
{
    id: "73",
    name: "Лик 315-М",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик 315-М 670грн.jpg",
    price: 670
},
{
    id: "74",
    name: "Лик 3125 ДЖ Ісус",
    sizes: "Висота 25",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик 3125 h25 ДЖ Ісус 1800грн.jpg",
    price: 1800
},
{
    id: "75",
    name: "Лик Fracaro 1148",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик FRACARO 1148 1950 грн.jpg",
    price: 1950
},
{
    id: "76",
    name: "Лик МАР",
    sizes: "23х17",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик MAP-23x17 1000 грн.jpg",
    price: 1000
},
{
    id: "77",
    name: "Лик Альб МБ",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик Альб.МБ 950грн.jpg",
    price: 950
},
{
    id: "78",
    name: "Лик Ісус",
    sizes: "13см",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик Ісус 13см 1150грн.jpg",
    price: 1150
},
{
    id: "79",
    name: "Лик Ісуса Альб",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик Ісуса Альб.945грн.jpg",
    price: 945
},
{
    id: "80",
    name: "Лик Ісуса без круга",
    sizes: "120мм",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик Ісуса без круга 120мм 630грн.jpg",
    price: 630
},
{
    id: "81",
    name: "Лик Ісуса в крузі",
    sizes: "125м",
    category: BronzeProducts,
    subCategory: Faces,
    urlImg: "/images/Лик Ісуса в крузі 125мм 650грн.jpg",
    price: 650
},
{
    id: "82",
    name: "Розп'яття",
    sizes: "16х11",
    category: BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп_яття 16х11 200грн.jpg",
    price: 200
},
{
    id: "83",
    name: "Розп'яття",
    sizes: "30см",
    category: BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп_яття 30см 1200грн.jpg",
    price: 1200
},
{
    id: "84",
    name: "Розп'яття Альб",
    sizes: "30см",
    category: BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп_яття 30см Альб 1050грн.jpg",
    price: 1050
},
{
    id: "88",
    name: "Розп'яття РП-16",
    sizes: "16х11",
    category: BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп_яття РП-16 16х11 500грн.jpg",
    price: 500
},
{
    id: "89",
    name: "Розп'яття РСП-17",
    sizes: "17х11",
    category: BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп_яття РСП-17 17х11 160грн.jpg",
    price: 160
},
{
    id: "91",
    name: "Тризуб",
    sizes: "19х12",
    category: BronzeProducts,
    subCategory: Tridents,
    urlImg: "/images/ТРИЗУБ 19х12 1050грн.jpg",
    price: 1050
},
{
    id: "93",
    name: "Тризуб",
    sizes: "40x24",
    category: BronzeProducts,
    subCategory: Tridents,
    urlImg: "/images/ТРИЗУБ 40х28 3250грн.jpg",
    price: 4000
},
{
    id: "94",
    name: "Рамка овал Іспанія 1094 Альб",
    sizes: "18х13",
    category: BronzeProducts,
    subCategory: Frames,
    urlImg: "/images/Рамка овал 18х13 Іспанія 1094Альб 1200грн.jpg",
    price: 1200
},
{
    id: "95",
    name: "Рамка овал",
    sizes: "24х18",
    category: BronzeProducts,
    subCategory: Frames,
    urlImg: "/images/Рамка овал 24х18 1095 Алб 1850грн.jpg",
    price: 1850
},
{
    id: "96",
    name: "Рамка овал",
    sizes: "30x24",
    category: BronzeProducts,
    subCategory: Frames,
    urlImg: "/images/Рамка овал 30х24 Алб 2500грн.jpg",
    price: 2500
},
{
    id: "97",
    name: "Рамка овал",
    sizes: "40х30",
    category: BronzeProducts,
    subCategory: Frames,
    urlImg: "/images/Рамка овал 40х30 Алб 3900грн.jpg",
    price: 3900
},
{
    id: "98",
    name: "Рамка овал пера Gg",
    sizes: "18х24",
    category: BronzeProducts,
    subCategory: Frames,
    urlImg: "/images/Рамка овал пера 18х24 Gg 1800грн.jpg",
    price: 1800
},
{
    id: "99",
    name: "Рамка прямокутна Іспанія",
    sizes: "24х18",
    category: BronzeProducts,
    subCategory: Frames,
    urlImg: "/images/Рамка прямокутна 24х18  1107 Алб 1850грн.jpg",
    price: 1850
},
{
    id: "102",
    name: "Книга лізник",
    sizes: "25х30х10",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга лізник 25х30х10 4600грн.jpg",
    price: 4600
},
{
    id: "103",
    name: "Книга колір закрита(біблія, кобзар)",
    sizes: "30х20",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга колір закрита 30х20 (біблія, кобзар) 2000грн.jpg",
    price: 2500
},
{
    id: "104",
    name: "Книга колір закрита(Біблія, Кобзар)",
    sizes: "30х20",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга колір закрита 30з20 (Біблія, Кобзар) 2000грн.jpg",
    price: 2500
},
{
    id: "108",
    name: "Книга габро (закрита)",
    sizes: "30х20",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга габро (закрита) 30х20 900грн.jpg",
    price: 900
},
{
    id: "10811",
    name: "Книга габро",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга габро 30х20х5 2200грн.jpg",
    price: 2200
},
{
    id: "10812",
    name: "Книга габро (закрита) Святе письмо",
    sizes: "20х30",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга габро закрита 20х30 Святе письмо 1400грн.jpg",
    price: 2000
},
{
    id: "10813",
    name: "Книга покостовка без с.",
    sizes: "25х16х5",
    category: DecorativeProductsGranite,
    subCategory: Books,
    urlImg: "/images/Книга покостовка 30х20х5 2200грн.jpg",
    price: 2200
},
{
    id: "109",
    name: "Кути покостівка",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 30см покостівка 680грн.jpg",
    price: 750
},
{
    id: "110",
    name: "Кути габро",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 30см габро 900грн.jpg",
    price: 750
},
{
    id: "111",
    name: "Кути покостівка",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 25см покостівка 600грн.jpg",
    price: 650
},
{
    id: "112",
    name: "Кути набір 4шт кольорові полімер прямі",
    sizes: "20см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 20см набір 4шт кольорові полімер прямі 1050грн.jpg",
    price: 1050
},
{
    id: "113",
    name: "Кути лєзнік",
    sizes: "20см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 20см лєзнік 750грн.jpg",
    price: 840
},
{
    id: "114",
    name: "Кути габро",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 20см габро 550грн.jpg",
    price: 700
},
{
    id: "115",
    name: "Кути габро",
    sizes: "20см",
    category: DecorativeProductsGranite,
    subCategory: Corners,
    urlImg: "/images/Кути 20см габро 550грн.jpg",
    price: 650
},
{
    id: "116",
    name: "Бірюши 3М",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Headphones,
    urlImg: "/images/Бірюши 3М 15грн.jpg",
    price: 15
},
{
    id: "117",
    name: "Навушники VOREL",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Headphones,
    urlImg: "/images/Навушники VOREL 250грн.jpg",
    price: 250
},
{
    id: "118",
    name: "Навушники YATO",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Headphones,
    urlImg: "/images/Навушники YATO 280грн.jpg",
    price: 280
},
{
    id: "119",
    name: "Окуляри Sigma",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри 9410631 Sigma 60грн.jpg",
    price: 60
},
{
    id: "120",
    name: "Окуляри Sigma",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри 9411001 Sigma 150грн.jpg",
    price: 150
},
{
    id: "121",
    name: "Окуляри VITA Дельта",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри VITA Дельта 45грн.jpg",
    price: 45
},
{
    id: "122",
    name: "Окуляри для сварщика",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри для сварщика 45грн.jpg",
    price: 45
},
{
    id: "123",
    name: "Окуляри захисні силіконові",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри захисні силіконові 20грн.jpg",
    price: 20
},
{
    id: "124",
    name: "Окуляри пластик Intertool",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри пластик Intertool 60грн.jpg",
    price: 60
},
{
    id: "125",
    name: "Окуляри СИЛА",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Glasses,
    urlImg: "/images/Окуляри СИЛА 45грн.jpg",
    price: 45
},
{
    id: "126",
    name: "Комплект SM",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Respirators,
    urlImg: "/images/sm450.jpg",
    price: 450
},
{
    id: "127",
    name: "Распіратор SIZAM 7500",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Respirators,
    urlImg: "/images/Распіратор SIZAM 7500 600грн.jpg",
    price: 600
},
{
    id: "128",
    name: "Распіратор аналог 3M №600",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Respirators,
    urlImg: "/images/Распіратор аналог 3М №6000 300грн.jpg",
    price: 300
},
{
    id: "129",
    name: "Распіратор без клапана",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Respirators,
    urlImg: "/images/Распіратор без клапана 40грн.jpg",
    price: 40
},
{
    id: "130",
    name: "Распіратор 3М 7503 оригінал",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Respirators,
    urlImg: "/images/Респіратор 3М 7503 оригінал 650грн.jpg",
    price: 650
},
{
    id: "131",
    name: "Респіратори з 2-ма фільтрами Blak Star",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Respirators,
    urlImg: "/images/Респіратори з 2-ма фільтрами Blak Star 150грн.jpg",
    price: 150
},
{
    id: "132",
    name: "Фартух Сірий довгий ОЗК",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Aprons,
    urlImg: "/images/Фартух Сірий довгий О3К 350грн.jpg",
    price: 350
},
{
    id: "134",
    name: "Накладка букет габро ЧПУ",
    sizes: "55см",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка букет габро ЧПУ 55см 3200грн.jpg",
    price: 3200
},
{
    id: "135",
    name: "Накладка кала біла ДН-006",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка кала біла ДН-006 1750грн.jpg",
    price: 2000
},
{
    id: "136",
    name: "Накладка свічка біла ДН-003",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка свічка біла ДН-003 1050грн.jpg",
    price: 1050
},
{
    id: "137",
    name: "Накладка свічка бронза ДН-003",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка свічка бронза ДН-003 1050грн.jpg",
    price: 1050
},
{
    id: "138",
    name: "Накладка троянда біла ДН-001",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка троянда біла ДН-001 1200грн.jpg",
    price: 1400
},
{
    id: "139",
    name: "Накладка троянда ДН-0111",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка троянда ДН-0111 б 1120грн.jpg",
    price: 1120
},
{
    id: "140",
    name: "Накладка троянда під золото ДН-001",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка троянда під золото ДН-001 1280грн.jpg",
    price: 1500
},
{
    id: "141",
    name: "Накладка троянди ДН-0112",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/ДН-0112.jpg",
    price: 1000
},
{
    id: "142",
    name: "Накладка янгол ДН-009(бронза, білий) правий",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка Янгол ДН-009(бронза, білий) правий 1050грн.jpg",
    price: 1050
},
{
    id: "143",
    name: "Накладка янгол Н-008(білий, бронза) лівий",
    sizes: "50см",
    category: BronzeProducts,
    subCategory: Overlays,
    urlImg: "/images/Накладка Янгол Н-008 (білий, бронза) лівий 1050грн.jpg",
    price: 1050
},
{
    id: "144",
    name: "Ангел 112 І.В",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел 112 І.В 1950грн.jpg",
    price: 1950
},
{
    id: "145",
    name: "Ангел 1254-Т Бровари",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел 1254-Т Бровари 1500грн.jpg",
    price: 1600
},
{
    id: "146",
    name: "Ангел 1373 Бровари (ліве крило)",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел 1373 Бровари (ліве крило) 1300грн.jpg",
    price: 1400
},
{
    id: "147",
    name: "Ангел 1624-Т Бровари (на коліні) Бронза",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел 1624-Т Бровари (на коліні) Бронза 2000грн.jpg",
    price: 2200
},
{
    id: "148",
    name: "Ангел 1624-Т Бровари(на коліні)",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел 1624-Т Бровари(на коліні) 2000грн.jpg",
    price: 2200
},
{
    id: "149",
    name: "Ангел 2997 Бровари",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел 2997 Бровари  1580грн.jpg",
    price: 1700
},
{
    id: "150",
    name: "Ангел білий з квітами гіпс",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел білий з квітами гіпс 1100грн.jpg",
    price: 1100
},
{
    id: "151",
    name: "Ангел ІВ А-208",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел ІВ А-208 2950грн.jpg",
    price: 2950
},
{
    id: "152",
    name: "Голуб складені крила",
    sizes: "230х250х180",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Голуб ІВ 2600грн.jpg",
    price: 4500
},
{
    id: "153",
    name: "Голуб крила відкриті СГ-020",
    sizes: "300х410х150",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Голуб ІВ складені крила відкриті 3250грн.jpg",
    price: 5200
},
{
    id: "154",
    name: "Голуб СГ-020 бронза",
    sizes: "300х4 10х150",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Голуб СГ-020 300х4 10х150 бронза 4650грн.jpg",
    price: 5700
},
{
    id: "155",
    name: "Лампадка з ангелом А-01 ІВ",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Лампадка з ангелом А-01 ІВ 1300грн.jpg",
    price: 1300
},
{
    id: "156",
    name: "Матір божа",
    sizes: "15см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Матір божа 15см 700грн.jpg",
    price: 700
},
{
    id: "157",
    name: "Матір божа (7070)",
    sizes: "40см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Матір божа 40см (7070) 9000грн.jpg",
    price: 9000
},
{
    id: "158",
    name: "Матір Божа(7483)",
    sizes: "42см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/7483.jpg",
    price: 10700
},
{
    id: "159",
    name: "Матір Божа(7071)",
    sizes: "50см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Матір Божа 50см (7071) 11000грн.jpg",
    price: 11000
},
{
    id: "160",
    name: "Матір Божа полімер",
    sizes: "70см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/мб-полімер7000.jpg",
    price: 7000
},
{
    id: "164",
    name: "Матір Божа 508",
    sizes: "42см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Матір Божа 508 42см 3600грн.jpg",
    price: 3600
},
{
    id: "166",
    name: "Матір Божа СК-019",
    sizes: "650х170х160",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Матір Божа 650х170х160 СК-019 5800грн.jpg",
    price: 6500
},
{
    id: "169",
    name: "Фігура Ангел А-115 ІВ",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Ангел115.jpg",
    price: 2900
},
{
    id: "170",
    name: "Фігура Ангел А-116 ІВ",
    sizes: "h30",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Ангел А-116 Н30 ІВ 2600грн.jpg",
    price: 2600
},
{
    id: "171",
    name: "Фігура Ангел пол №207",
    sizes: "32см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Ангел пол 32см  №207 2600грн.jpg",
    price: 2600
},
{
    id: "172",
    name: "Фігура Ангел пол 211",
    sizes: "52см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Ангел пол 52см 211 5200грн.jpg",
    price: 5200
},
{
    id: "173",
    name: "ФІгура Ангел пол №204",
    sizes: "59см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Ангел пол 59см №204 4000грн.jpg",
    price: 4000
},
{
    id: "174",
    name: "Фігура Ангел сидить",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Ангел сидить 111 5850грн.jpg",
    price: 5850
},
{
    id: "177",
    name: "Фігура Матір Божа №303",
    sizes: "40см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Матір Божа 40см №303 2500грн (1).jpg",
    price: 2500
},
{
    id: "179",
    name: "Фігура Матір Божа №404",
    sizes: "50см",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Матір Божа 50см №404 3000грн.jpg",
    price: 3000
},
{
    id: "180",
    name: "Фігура Матір Божа бронза GT-102 ІВ",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Фігура Матір Божа бронза GT-102 ІВ 2600грн.jpg",
    price: 2600
},
{
    id: "181",
    name: "Янгол білий СК-005",
    sizes: "150х100х130",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Янгол 150х100х130 білий СК-005 2150грн.jpg",
    price: 2150
},
{
    id: "182",
    name: "Янгол білий СК-008",
    sizes: "200х180х80",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Янгол 200х180х80 білий СК-008 1600грн.jpg",
    price: 1600
},
{
    id: "183",
    name: "Янгол білий СК-003",
    sizes: "490х240х170",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Янгол 490х240х170 білий СК-003 5150грн.jpg",
    price: 5150
},
{
    id: "184",
    name: "Янгол з кроликом СК-015",
    sizes: " ",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Янгол з кроликом СК-015 4950грн.jpg",
    price: 7400
},
{
    id: "185",
    name: "Янгол з трояндами СК-006 білий",
    sizes: "570х230х170",
    category: BronzeProducts,
    subCategory: Statuettes,
    urlImg: "/images/Янгол з трояндами СК-006 570х230х170 білий 5150грн.jpg",
    price: 6500
},
{
    id: "186",
    name: "Ваза лізник різана",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза 25см лізник різана 950грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Лізник (червоний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 1100
},
{
    id: "187",
    name: "Ваза маславка аладін",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза 25см маславка аладін 700грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Маславка (зелений) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 1000
},
{
    id: "188",
    name: "Ваза ток аладін",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза 25см ток аладін 700грн.jpg",
    desc: ``,
    price: 1000
},
{
    id: "189",
    name: "Ваза ток різана",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза 25см ток різана 950грн.jpg",
    desc: ``,
    price: 1100
},
{
    id: "190",
    name: "Ваза ток аладін",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза 30см ток аладін 800грн.jpg",
    desc: ``,
    price: 1100
},
{
    id: "191",
    name: "Ваза габро",
    sizes: "Висота 25см Ширина 13см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: ["/images/191 (1).jpg", "/images/191 (4).jpg", "/images/191 (5).jpg", "/images/191.mp4"],
    desc: `Ціна за гранітну вазу з граніту Габро (чорний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 550
},
{
    id: "192",
    name: "Ваза габро",
    sizes: "25х16",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза габро 25х16 850грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Габро (чорний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 850
},
{
    id: "193",
    name: "Ваза габро",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза габро 30см 550грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Габро (чорний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 600
},
{
    id: "195",
    name: "Ваза Індійський граніт",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза Індійський граніт 25см 1000грн.jpg",
    desc: ``,
    price: 1000
},
{
    id: "198",
    name: "Ваза лізник",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза лєзнік 25см 680грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Лізник (червоний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 750
},
{
    id: "199",
    name: "Ваза лізник (тип 2)",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза лєзнік 30см 650грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Лізник (червоний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 800
},
{
    id: "200",
    name: "Ваза лізник",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза лєзнік 30см К 700грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Лізник (червоний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 800
},
{
    id: "202",
    name: "Ваза покостівка",
    sizes: "25см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза покостівка 25см 480грн.jpg",
    desc: ``,
    price: 550
},
{
    id: "203",
    name: "Ваза покостівка",
    sizes: "Висота 30см Ширина 14см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: ["/images/203.jpg", "/images/203 (1).jpg", "/images/203 (2).jpg", "https://i.imgur.com/NC5D0vs.mp4"],
    desc: ``,
    price: 600
},
{
    id: "204",
    name: "Ваза прорізна лізник",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза прорізна кольорова 1100грн.jpg",
    desc: `Ціна за гранітну вазу з граніту Лізник (червоний) висота від 25 до 40 см діаметр 15-20см

    Ваза з граніту виготовляється в трьох колірних категоріях (чорний, сірий, червоний) всі вази, виготовлені вручну, тому можуть неістотно відрізнятися від ваз на фото. Висота вази від 20 см. При замовленні обов'язково уточніть форму, колір, висоту, діаметр`,
    price: 1400
},
{
    id: "205",
    name: "Пропітка Uniblack TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Uniblack 1 0.250мл TENAX 620грн.jpg",
    price: 575,
    desc: `Коригуючий засіб Для зовнішніх і внутрішніх робіт. 
Усуває дрібні дефекти на чорних полірованих гранітах. 
Надає поверхні однорідність кольору. 
Захищає від проникнення води, жирів, забруднень. покращує колір чорного каменю, приховує дрібні подряпини і дефекти полірування. 
Наноситься на стадії шліфування поверхні.`
},
{
    id: "206",
    name: "Пропітка Pectro прозора TENAX",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Pectro 1л прозора TENAX 1400Грн.jpg",
    price: 1320,
    desc: `Пропітка Pectro - прозора рідина, усуває і затягує мікротріщини на поверхні каменя.
В упаковці міститься - 1 л. 
Використовується для зовнішніх і внутрішніх робіт.`
},
{
    id: "207",
    name: "Пропітка Pectro чорна TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Pectro 0,250мл чорна TENAX 590Грн.jpg",
    price: 575,
    desc: `Пропітка Pectro - усуває і затягує мікротріщини на поверхні каменя.
В упаковці міститься - 250мл. 
Використовується для зовнішніх і внутрішніх робіт.`
},
{
    id: "208",
    name: "Пропітка OLD AGE",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка OLD AGE 250мл 570грн.jpg",
    price: 570,
    desc: `OLD AGE - кам'яне просочення для натурального каменю, використовується для захисту від води та бруду поверхні, з ефектом посилення кольору.
Підходить для обробки всіх типів мармуру, травертину, граніту та агломерату з метою насичення кольору «мокрий» ефект, для використання як усередині приміщення, так і зовні. 
"Мокрий ефект" добре видно на шліфованому камені, а також на термообробленому, при цьому посилюється колір каменю, підкреслюється його структура.`
},
{
    id: "209",
    name: "Пропітка Leznik+",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/leznik1900.jpg",
    price: 1900,
    desc: `LEZNIK+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: лізник, капуста. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики. 
-Морозостійка
-Вологовідштовхувальна`
},
{
    id: "210",
    name: "Пропітка Leznik+",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/leznik550.jpg",
    price: 550,
    desc: `LEZNIK+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: лізник, капуста. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики. 
-Морозостійка
-Вологовідштовхувальна`
},
{
    id: "211",
    name: "Пропітка Leznik+",
    sizes: "100мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/leznik220.jpg",
    price: 220,
    desc: `LEZNIK+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: лізник, капуста. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики. 
-Морозостійка
-Вологовідштовхувальна`
},
{
    id: "212",
    name: "Пропітка Leznik+",
    sizes: "50мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/leznik130.jpg",
    price: 120,
    desc: `LEZNIK+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: лізник, капуста. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики. 
-Морозостійка
-Вологовідштовхувальна`
},
{
    id: "213",
    name: "Пропітка Gabbro+",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/gabro1900.jpg",
    price: 1900,
    desc: `GABBRO+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: граніт, габро, лабрадорит. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. 
Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики.`
},
{
    id: "214",
    name: "Пропітка Gabbro+",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/gabro550.jpg",
    price: 550,
    desc: `GABBRO+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: граніт, габро, лабрадорит. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. 
Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики.`
},
{
    id: "215",
    name: "Пропітка Gabbro+",
    sizes: "100мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/gabro220.jpg",
    price: 220,
    desc: `GABBRO+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: граніт, габро, лабрадорит. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. 
Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики.`
},
{
    id: "216",
    name: "Пропітка Gabbro+",
    sizes: "50мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/gabro120.jpg",
    price: 120,
    desc: `GABBRO+ має унікальні проникаючі властивості на основі кремнеорганічних молекулярних сполук. 
Застосовується для виробів з натурального каменю, таких як: граніт, габро, лабрадорит. 
Просочення глибоко проникає та промбує пори, мікротріщини, захищаючи камінь від руйнування. 
Підсилює та насичує колір каменю. 
Надає каменю делікатний блиск. 
На тривалий термін зберігає свої характеристики.`
},
{
    id: "217",
    name: "Пропітка Extra Drops",
    sizes: "200мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Extra Drops 0,200 230грн.jpg",
    price: 230,
    desc: ``
},
{
    id: "218",
    name: "Пропітка EVERCLEAN",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка EVERCLEAN 1L 1480грн.jpg",
    price: 1480,
    desc: `Спеціальний рідкий, безбарвний засіб на основі розчинника для просочення граніту, мармуру, іншого природного та штучного каменю, шліфованої та полірованої поверхні, зовні та всередині приміщення.
Захищає поверхню оброблюваного матеріалу від впливу води, жиру, залишає можливість «дихати» матеріалу, не змінюючи його колір та натуральні властивості, робить поверхню стійкою до утворення плям.
Особливо добре підходить для білого та світлого мармуру, а також захисту гравірованих портретів та пейзажів на габро та граніті від впливу зовнішніх факторів.`
},
{
    id: "219",
    name: "Пропітка EVERCLEAN",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка EVERCLEAN 0,250ml 520грн.jpg",
    price: 520,
    desc: `Спеціальний рідкий, безбарвний засіб на основі розчинника для просочення граніту, мармуру, іншого природного та штучного каменю, шліфованої та полірованої поверхні, зовні та всередині приміщення.
Захищає поверхню оброблюваного матеріалу від впливу води, жиру, залишає можливість «дихати» матеріалу, не змінюючи його колір та натуральні властивості, робить поверхню стійкою до утворення плям.
Особливо добре підходить для білого та світлого мармуру, а також захисту гравірованих портретів та пейзажів на габро та граніті від впливу зовнішніх факторів.`
},
{
    id: "220",
    name: "Пропітка Drexy TENAX",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Drexy 1л TENAX 1530Грн.jpg",
    price: 1455,
    desc: `Tenax DREXY проникає в глибину і фіксується в порах матеріалу, надаючи йому водовідштовхувальні та масловідштовхувальні властивості.
Швидко сохне, не змінює кольори матеріалу.`
},
{
    id: "221",
    name: "Пропітка Ager 1л TENAX",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Ager 1л TENAX 3150Грн.jpg",
    price: 2995,
    desc: `Для оброблення всіх різновидів мармуру, граніту, травертину й агломерату. 
Для посилення кольору  і підкреслення фактури матеріалу, надаючи «мокрий» ефект.
Швидковисихне просочення.`
},
{
    id: "222",
    name: "Пропітка Ager TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Ager 0,250мл TENAX 920Грн.jpg",
    price: 875,
    desc: `Для оброблення всіх різновидів мармуру, граніту, травертину й агломерату. 
Для посилення кольору  і підкреслення фактури матеріалу, надаючи «мокрий» ефект.
Швидковисихне просочення.`
},
{
    id: "223",
    name: "Полір порошок BRILLUX відро середне",
    sizes: " ",
    category: Perm,
    subCategory: Powder,
    urlImg: "/images/Полір порошок BRILLUX відро середне 470грн.jpg",
    price: 470,
    desc: ``
},
{
    id: "224",
    name: "Полір порошок BRILLUX відро велике",
    sizes: " ",
    category: Perm,
    subCategory: Powder,
    urlImg: "/images/Полір порошок BRILLUX відро велике 730грн.jpg",
    price: 800,
    desc: ``
},
{
    id: "225",
    name: "Полір порошок BRILLUX білий (відро)",
    sizes: "4кг",
    category: Perm,
    subCategory: Powder,
    urlImg: "/images/Полір порошок BRILLUX білий 4кг (відро) 730грн.jpg",
    price: 730,
    desc: `Порошок полірувальний BRILLUX призначений для фінішної обробки граніту та мармуру. 
За допомогою використання речовини з великою абразивною можливістю виходить чудове полірування механічного типу.`
},
{
    id: "226",
    name: "Полір порошок BRILLUX білий (відро, банка)",
    sizes: "1кг",
    category: Perm,
    subCategory: Powder,
    urlImg: "/images/Полір порошок BRILLUX білий 1кг (відро, банка) 220грн.jpg",
    price: 220,
    desc: `Порошок полірувальний BRILLUX призначений для фінішної обробки граніту та мармуру. 
За допомогою використання речовини з великою абразивною можливістю виходить чудове полірування механічного типу.`
},
{
    id: "227",
    name: "Лак Universal spray General",
    sizes: "400мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Лак Universal spray 0,400мл General 500грн.jpg",
    price: 500,
    desc: `Самополіруючий лак General Universal -це суміш високоякісних смол і восків, що забезпечує якісне полірування мармуру,
граніту та іншого природного каменю.
Склад даного препарату підібраний таким чином, щоб оброблена поверхня тривалий час зберігала блиск і не
змінювалася під впливом атмосферних впливів.
General Universal – широко застосовується там, де механічна поліровка неможлива або утруднена: кути, ребра,
кромки, обода, стійки та інше.
Крім того, лак рекомендується для отримання «мокрого ефекту» на лицьових неполірованих поверхнях блоків,
слябів, плитки.`
},
{
    id: "228",
    name: "Лак Extra lux спрей",
    sizes: "400мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Лак Extra lux 0,400мл спрей 410грн.jpg",
    price: 410,
    desc: `Лак EXTRA LUX, що самополірується, є сумішшю високоякісних смол і восків, що забезпечує високоякісне полірування каменю,
мармуру, граніту. Склад даного препарату підібраний таким чином, щоб оброблена поверхня тривалий час зберігала блиск і не змінювалась під впливом атмосферних впливів. 
EXTRA LUX широко застосовується там, де механічне полірування неможливе або утруднене: кути, ребра, кромки, обода, стійки та ін.`
},
{
    id: "229",
    name: "Лак Extra lux 200мл прозорий",
    sizes: "200мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Лак Extra lux 0,200мл прозорий 300грн.jpg",
    price: 300,
    desc: `Лак для каменю. Створює ефект полірування у важкодоступних місцях на виробах з мармуру, граніту та іншого натурального каміння.
Рідина без кольору підходить для будь-якого кольору та виду каменю.`
},
{
    id: "230",
    name: "Кристалізатор STEIN WAX 1Л з воском",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор STEIN WAX 1Л з воском 580грн.jpg",
    price: 560,
    desc: `Stein Kristalizer Politur Specjal VAX. Це однокомпонентний препарат, який кристалізує натуральний і штучний камінь.
Основними перевагами цього засобу є: 
- Максимальне проникнення в матеріал, самотвердіюча хімія (без створення температури в мінералі),
- поглиблення кольору та надання стійкого блиску .
- Стійкий до УФ-випромінювання.
- Камінь повністю кристалізується через 20 годин`
},
{
    id: "231",
    name: "Кристалізатор STEIN TEFLONU 1Л",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор STEIN TEFLONU 1Л 580грн.jpg",
    price: 560,
    desc: `Stein Teflonu це однокомпонентний препарат, що кристалізує натуральний та штучний камінь.
Основними перевагами цього засобу є:
 максимальне проникнення в матеріал, 
 автоматичне хімічне затвердіння (без створення температури в мінералі), 
 поглиблення кольору та стійке полірування. 
 Стійкий до УФ-випромінювання.`
},
{
    id: "232",
    name: "Кристалізатор STEIN BF прозорий",
    sizes: "330мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор STEIN BF 0,33мл прозорий 180грн.jpg",
    price: 180,
    desc: `Кристалізатор для граніту BF, - призначений для всіх видів каменю та його обробки та мармуру.
Добре підкреслює структуру каменю, надаючи деякий ефект мокрого каменю. 
Сприяє пилевідштовхувальній дії, приховування дефектів у вигляді мікротріщин, насичення кольору.`
},
{
    id: "233",
    name: "Кристалізатор STEIN прозорий",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор STEIN 1л прозорий 740грн.jpg",
    price: 740,
    desc: ``
},
{
    id: "234",
    name: "Кристалізатор SPECJAL WAX з воском",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор SPECJAL WAX 1л з воском 650грн.jpg",
    price: 650,
    desc: `Кристалізатор SPECIAL WAX 1л служит для догляду за гранітом і мармуром, підкреслює структуру каменю, а також дає ефект мокрого каменю.`
},
{
    id: "235",
    name: "Кристалізатор GraniLux Nero чорний",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор GraniLux Nero 1л чорний 560грн.jpg",
    price: 650,
    desc: `GRANI LUX — Силіконова поліроль для чорних і кольорових каменів. 
Кристалізатор із удосконаленою формулою суміші воску та силікону.
Засіб проникає в оброблювану поверхню, водночас відновлюючи колір і малюнок каменю, надає блиску, а також відштовхує воду, олію, вино та ін.
Прекрасний засіб для догляду за виробами з чорного мармуру, граніту та інших натуральних каменів. 
колір — чорний
об'єм — 1L`
},
{
    id: "236",
    name: "Кристалізатор GraniLux Nero чорний",
    sizes: "200мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор GraniLux Nero 0,200 чорний 250грн.jpg",
    price: 250,
    desc: `GRANI LUX — Силіконова поліроль для чорних і кольорових каменів. 
Кристалізатор із удосконаленою формулою суміші воску та силікону.
Засіб проникає в оброблювану поверхню, водночас відновлюючи колір і малюнок каменю, надає блиску, а також відштовхує воду, олію, вино та ін.
Прекрасний засіб для догляду за виробами з чорного мармуру, граніту та інших натуральних каменів.
колір — чорний
об'єм — 200 ml `
},
{
    id: "237",
    name: "Кристалізатор GraniLux прозорий",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор GraniLux 1Л прозорий 560грн.jpg",
    price: 650,
    desc: `GRANILUX NEUTRAL (нейтральний) на основі розчинника - виріб, який вживається, щоб зробити колір граніту однорідним. Розроблено із сумішшю модифікованої силіконової смоли та воску. 
Має відмінний полірувальний і жиростійкий ефект, що надає чудовий блиск обробленому граніту.
колір - прозорий
об'єм 1л`
},
{
    id: "238",
    name: "Кристалізатор GraniLux прозорий",
    sizes: "200мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор GraniLux 0,200 прозорий 250грн.jpg",
    price: 250,
    desc: `GRANILUX NEUTRAL (нейтральний) на основі розчинника - виріб, який вживається, щоб зробити колір граніту однорідним. Розроблено із сумішшю модифікованої силіконової смоли та воску. 
Має відмінний полірувальний і жиростійкий ефект, що надає чудовий блиск обробленому граніту.  
колір - прозорий
об'єм 200мл`
},
{
    id: "239",
    name: "Кристалізатор BRILLO прозорий",
    sizes: "1л",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор BRILLO 1л прозорий 630грн.jpg",
    price: 650,
    desc: `Кристалізатор з воску на основі силікону.
Засіб 3 в 1 - чистить жир і бруд, полірує, проявляє колір та структуру каменю, а також є засобом, що відштовхує воду, олію, вино та ін
Прекрасний засіб для догляду за виробами з мармуру, граніту та інших натуральних каменів.`
},
{
    id: "240",
    name: "Кристалізатор BRILLO спрей",
    sizes: "400мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор BRILLO 0,400спрей 410грн.jpg",
    price: 410,
    desc: `BRILLO КРИСТАЛІЗАТОР З ВОСКУ НА ОСНОВІ СИЛІКОНУ ДЛЯ МАРМУРУ І ГРАНІТАBRILLO – віск на силіконовій основі. 
Прекрасний засіб для догляду за виробами з мармуру, граніту та іншого натурального каміння. Крім цього рекомендується для захисту керамічної плитки від бруду та жиру.
Компоненти, що входять до складу даного засобу, забезпечують потрійну дію на оброблювану поверхню каменю. BRILLO чистить жир і бруд, полірує і виявляє колір і малюнок каменю і, нарешті, є засобом, що відштовхує воду, масло, кава, вино та ін.
Засіб повністю висихає за кілька хвилин, після чого необхідно натерти чистою м'якою ганчірочкою для отримання блиску.`
},
{
    id: "241",
    name: "Кристалізатор BRILLO прозорий",
    sizes: "200мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор BRILLO 0,200мл прозорий 280грн.jpg",
    price: 300,
    desc: `Засіб для захисту граніту та мармуру – три в одному.
Поєднує три функції - очищувач каменю, поліроль для каменю і підсилювач кольору для каменю. 
Також Brillo – це відмінний водовідштовхувальний склад для натурального каменю. 
Також може використовуватися для чищення та захисту керамограніту та керамічної плитки.
Підходить для зовнішніх та внутрішніх робіт по каменю.`
},
{
    id: "242",
    name: "Кристалізатор для мрамуру POLITUR MARMOR",
    sizes: " ",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор для мрамуру POLITUR MARMOR 190Грн.jpg",
    price: 190,
    desc: `POLITUR MARMOR це полірувальний засіб, що використовується для обслуговування та полірування поверхні з граніту та мармуру.
Він містить віск - що дає миттєве пожвавлення полірування каменю. 
Видаляє бруд, захищає просочену поверхню від проникнення води.`
},
{
    id: "243",
    name: "Кристалізатор для мрамора TENAX",
    sizes: " ",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор для мрамора TENAX 750Грн.jpg",
    price: 850,
    desc: `Кристалізатор KRISTALL-T – один із найкращих засобів подібного роду, який випускає італійська компанія TENAX.
Кристалізатор мармуру KRISTALL-T TENAX – це готовий до застосування склад, який відрізняється простотою та зручністю застосування, високою ефективністю, доступною ціною.
 Для відновлення кам'яної поверхні:
 Підлоги з натурального каменю користуються в даний час найширшою популярністю завдяки чудовому природному малюнку, унікальному та різноманітному забарвленню, своїй міцності.
 Але крім плюсів, мармур, вапняк, травертин, терраццо не позбавлені і мінусів – до яких варто віднести насамперед стирання.`
},
{
    id: "244",
    name: "Кристалізатор BF",
    sizes: "250мл",
    category: Perm,
    subCategory: Crystallizers,
    urlImg: "/images/Кристалізатор 0,25 мл BF 150грн.jpg",
    price: 150,
    desc: `Stein Kristalizer Politur. Це однокомпонентний препарат, що кристалізує натуральний та штучний камінь.
Основними перевагами цього засобу є:
- Максимальне проникнення в матеріал, автоматичне хімічне затвердіння (без утворення температури в мінералі), поглиблення кольору та надання стійкого полірування.
- Повна кристалізація каменю відбувається через 20 хвилин (при температурі близько 10 градусів Цельсія).`
},
{
    id: "245",
    name: "Клей Solido Blanco TENAX білий",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей Solido Blanco 1L TENAX 580Грн.jpg",
    price: 580,
    desc: ``
},
{
    id: "246",
    name: "Клей Rivo-50 A+B бежевий TENAX",
    sizes: " ",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/rivo1850.jpg",
    price: 1880,
    desc: ``
},
{
    id: "247",
    name: "Клей Jolly Liquido Nero Jolly чорний рідкий",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей Jolly 1л Liquido Nero Jolly чорний рідкий 300грн.jpg",
    price: 300,
    desc: ``
},
{
    id: "248",
    name: "Клей Jolly Liquido Transparente Jolly",
    sizes: "250мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей Jolly 0,250мл Liquido Transparente Jolly пр,р 140грн.jpg",
    price: 140,
    desc: ``
},
{
    id: "249",
    name: "Клей General Transparente Verticale",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 1л Transparente Verticale пр,г 700грн.jpg",
    price: 700,
    desc: `GENERAL® Verticale - поліефірний двокомпонентний клей для склеювання в площинах, шпаклювання, реставраційних робіт.
Підходить для натурального та штучного каменю, мармуру, граніту, травертину.`
},
{
    id: "250",
    name: "Клей General Nero Verticale чорний",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 1л Nero Verticale чорний 490грн.jpg",
    price: 530,
    desc: ``
},
{
    id: "251",
    name: "Клей General Transparente Verticale",
    sizes: "750мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 0,750мл Transparente Verticale пр,г 620грн.jpg",
    price: 620,
    desc: ``
},
{
    id: "252",
    name: "Клей General Transparente Fluido",
    sizes: "750мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 0,750мл Transparente Fluido пр,р 580грн.jpg",
    price: 580,
    desc: ``
},
{
    id: "253",
    name: "Клей General Pagilerino Vertic (бєж)",
    sizes: "750мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 0,750 мл Pagilerino Vertic бєж 480грн.jpg",
    price: 480,
    desc: ``
},
{
    id: "254",
    name: "Клей General Nero Verticale чорний",
    sizes: "125мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 0,125 мл Nero Verticale чорний 180грн.jpg",
    price: 180,
    desc: ``
},
{
    id: "255",
    name: "Клей General Transparente Fluido",
    sizes: "125мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей General 0,125 м,л Transparente Fluido пр,р 220грн.jpg",
    price: 220,
    desc: ``
},
{
    id: "256",
    name: "Клей Bern чорний",
    sizes: " ",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей Bern чорний 320грн.jpg",
    price: 320,
    desc: ``
},
{
    id: "257",
    name: "Клей універсальний 88",
    sizes: "200мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей універсальний 88 200мл 110грн.jpg",
    price: 110,
    desc: `Клей «88» призначений для склеювання гуми, шкіри, металу, пластику, дерева, пробки, тканини, картону, скла, бетону та багатьох інших матеріалів у різному поєднанні.
Не підходить для склеювання посуду, що контактує з їжею.`
},
{
    id: "258",
    name: "Клей титаніум Tenax чорний",
    sizes: "л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей титаніум чорний 1400грн.jpg",
    price: 1500,
    desc: ``
},
{
    id: "259",
    name: "Клей титаніум Tenax Extra Clear прозорий",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей титаніум 1470грн.jpg",
    price: 1550,
    desc: ``
},
{
    id: "260",
    name: "Клей 88",
    sizes: "600мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 88 600мл ж,б 330грн.jpg",
    price: 330,
    desc: ``
},
{
    id: "261",
    name: "Клей Solido Transparente (прозорий, густий) TENAX",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 1л Solido Transparente TENAX пр,г 690грн.jpg",
    price: 720,
    desc: ``
},
{
    id: "262",
    name: "Клей Solido Nero TENAX чорний",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 1л Solido Nero TENAX чорний 530грн.jpg",
    price: 580,
    desc: ``
},
{
    id: "263",
    name: "Клей TENAX світлий бєж",
    sizes: "750мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 0,750 TENAX світлий бєж 460грн.jpg",
    price: 500,
    desc: ``
},
{
    id: "264",
    name: "Клей Solido Paglierino TENAX бєж",
    sizes: "125мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 0,125 мл Solido Paglierino TENAX бєж 300грн.jpg",
    price: 320,
    desc: ``
},
{
    id: "265",
    name: "Клей Solido Nero TENAX чорний",
    sizes: "125мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 0,125 мл Solido Nero TENAX чорний 280грн.jpg",
    price: 320,
    desc: ``
},
{
    id: "266",
    name: "Клей Fluido Transparente TENAX",
    sizes: "125мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей 0,125 мл Fluido Transparente TENAX пр,р 280грн.jpg",
    price: 320,
    desc: ``
},
{
    id: "267",
    name: "Герметик(клей)-катрідж Sikadyr сірий",
    sizes: "600мг",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик(клей)-катрідж Sikadyr сірий 600мг 420грн.jpg",
    price: 420,
    desc: `Герметик поліуретановий "Sikadyr сірий 600мг" - це однокомпонентний, вологотвердний, еластичний шовний герметик та універсальний клей на основі поліуретану або поліуретановий клей. 
Матеріал придатний для внутрішніх та зовнішніх робіт.`
},
{
    id: "268",
    name: "Герметик(клей) -катрідж Sikadyr чорний",
    sizes: "600мг",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик(клей) -катрідж Sikadyr чорний 600мг 420грн.jpg",
    price: 420,
    desc: `Герметик поліуретановий "Sikadyr чорний 600мг" - це однокомпонентний, вологотвердний, еластичний шовний герметик та універсальний клей на основі поліуретану або поліуретановий клей. 
Матеріал придатний для внутрішніх та зовнішніх робіт.`
},
{
    id: "269",
    name: "Герметик TENAX прозорий",
    sizes: " ",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик TENAX прозорий 270грн.jpg",
    price: 270,
    desc: `Однокомпонентний силіконовий герметик нейтрального затвердіння.
Ідеально підходить для мармуру (в тому числі білого і кристалічного мармуру), гранітів, пористих каменів, кварцу і кераміки.`
},
{
    id: "270",
    name: "Герметик STEIN морозостійкий прозорий",
    sizes: " ",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик STEIN морозостійкий прозорий 450грн.jpg",
    price: 450,
    desc: ``
},
{
    id: "271",
    name: "Герметик SIKAFLEX чорний",
    sizes: "310мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик SIKAFLEX чорний 310мл 325грн.jpg",
    price: 310,
    desc: ``
},
{
    id: "272",
    name: "Герметик SIKAFLEX сірий",
    sizes: "310мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик SIKAFLEX сірий 310мл 325грн.jpg",
    price: 310,
    desc: ``
},
{
    id: "273",
    name: "Герметик CROCODILE чорний",
    sizes: "310мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик CROCODILE чорний 310мл 185грн.jpg",
    price: 185,
    desc: ``
},
{
    id: "274",
    name: "Герметик CROCODILE сірий",
    sizes: "310мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик CROCODILE сірий 310мл 185грн.jpg",
    price: 185,
    desc: ``
},
{
    id: "275",
    name: "Герметик універсальний Akfix прозорий",
    sizes: "280мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик універсальний Akfix 180мл прозорий 130грн.jpg",
    price: 130,
    desc: `Однокомпонентний універсальний силіконовий герметик.Прекрасна адгезія до емалі, скла, кераміки, дерева, металу, фарбованих поверхонь і багатьох інших непористих поверхонь. `
},
{
    id: "276",
    name: "Герметик акваріумний Akfix чорний",
    sizes: "280мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик акваріумний Akfix 280мл чорний 190грн.jpg",
    price: 190,
    desc: `Однокомпонентний силіконовий герметик, призначений для виготовлення та ремонту акваріумів. 
Під впливом вологості повітря перетворюється на нетоксичний ущільнювач, що забезпечує чудове склеювання скла та багатьох інших непористих матеріалів.`
},
{
    id: "277",
    name: "Герметик акваріумний Akfix прозорий",
    sizes: "280мл",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Герметик акваріумний Akfix 280мл прозорий 190грн.jpg",
    price: 190,
    desc: `Однокомпонентний силіконовий герметик, призначений для виготовлення та ремонту акваріумів. 
Під впливом вологості повітря перетворюється на нетоксичний ущільнювач, що забезпечує чудове склеювання скла та багатьох інших непористих матеріалів.`
},
{
    id: "278",
    name: "Віск Monocera General чорний",
    sizes: "1л",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 1Л General чорний 840грн.jpg",
    price: 790,
    desc: `Професійна чорна паста-віск 1л. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з високою, середньою, низькою пористістю.
Надає чудовий блиск та захист на будь-якій полірованій поверхні.`
},
{
    id: "279",
    name: "Віск Monocera General прозорий",
    sizes: "1л",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 1л General прозорий 840грн.jpg",
    price: 720,
    desc: `Професійна прозора паста-віск 1л. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з високою, середньою, низькою пористістю. 
Надає чудовий блиск та захист на будь-якій полірованій поверхні.`
},
{
    id: "280",
    name: "Віск Monocera General чорний",
    sizes: "250мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 0,250 ml General чорний 310грн.jpg",
    price: 300,
    desc: `Засіб рекомендовано для всіх видів натурального каменю з високою і малої пористістю і поглинанням,
утворює непроникну плівку тим самим захищає камінь від впливу жирів,вологи,пилу.
Надає каменю відновлювальний ефект, насичує і оживляє колір, надає блиск, приховує невеликі дефекти.`
},
{
    id: "281",
    name: "Віск Monocera General прозорий",
    sizes: "250мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 0,250 ml General прозорий 310грн.jpg",
    price: 290,
    desc: `Професійна прозора паста-віск 250мл. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з високою, середньою, низькою пористістю. `
},
{
    id: "282",
    name: "Віск Monocera General чорний",
    sizes: "500мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 0,5мл General чорний 465грн.jpg",
    price: 450,
    desc: `Професійна чорна паста-віск 500мл. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з пористістю.
Надає чудовий блиск та захист.`
},
{
    id: "283",
    name: "Віск Monocera General червоний",
    sizes: "500мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 0,5мл General червоний 485грн.jpg",
    price: 485,
    desc: `Професійна червона паста-віск 0.5л. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з високою, середньою, низькою пористістю. 
Надає чудовий блиск та захист на будь-якій полірованій поверхні. 
Спосіб застосування: 
Нанести на суху та чисту поверхню круговими рухами за допомогою чистої бавовняної тканини, залишити на 10-20 хвилин (залежно від умов навколишнього середовища). 
Після висихання полірувати іншою чистою тканиною, або полірувальною машиною.`
},
{
    id: "284",
    name: "Віск Monocera General прозорий",
    sizes: "500мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 0,5 мл General прозорий 465грн.jpg",
    price: 435,
    desc: `Професійна  паста-віск 0.5л. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з високою, середньою, низькою пористістю. 
Надає чудовий блиск та захист на будь-якій полірованій поверхні. 
Спосіб застосування: 
Нанести на суху та чисту поверхню круговими рухами за допомогою чистої бавовняної тканини, залишити на 10-20 хвилин (залежно від умов навколишнього середовища). 
Після висихання полірувати іншою чистою тканиною, або полірувальною машиною.`
},
{
    id: "285",
    name: "Віск Monocera General зелений",
    sizes: "500мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Monocera 0,5 мл General зелений 465грн.jpg",
    price: 465,
    desc: `Професійна зелена паста-віск 0.5л. для граніту, мармуру, природного та штучного каменю.
Використовується для надання глянсового ефекту на поверхнях з високою, середньою, низькою пористістю. 
Надає чудовий блиск та захист на будь-якій полірованій поверхні. 
Спосіб застосування: 
Нанести на суху та чисту поверхню круговими рухами за допомогою чистої бавовняної тканини, залишити на 10-20 хвилин (залежно від умов навколишнього середовища). 
Після висихання полірувати іншою чистою тканиною, або полірувальною машиною.`
},
{
    id: "286",
    name: "Віск Cera Pasta TENAX прозорий",
    sizes: "1л",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Cera Pasta 1Л TENAX прозорий 820грн.jpg",
    price: 860,
    desc: `Віск EXTRA WAX для натирання граніту, мармуру, натурального каменю.
Monocera віск для надання блиску на поверхні мармуру і граніту.
Віск є гелеобразної консистенції з нейтральним відтінком.
Також доступний віск в кольорах:
-чорний
-прозорий`
},
{
    id: "287",
    name: "Віск Cera Pasta TENAX (чорний)",
    sizes: "1л",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск Cera Pasta 1л TENAX (чорний) 820грн.jpg",
    price: 860,
    desc: `Віск EXTRA WAX для натирання граніту, мармуру, натурального каменю.
Monocera віск для надання блиску на поверхні мармуру і граніту.
Віск є гелеобразної консистенції з нейтральним відтінком.
Також доступний віск в кольорах:
-прозорий
-чорний`
},
{
    id: "288",
    name: "Віск Bellizoni червоний",
    sizes: "250мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск 0,250 мл Bellizoni червоний 180грн.jpg",
    price: 180,
    desc: `Густий віск Bellinzoni (Червоний) - призначений для полірування будь-яких видів каміння.
Завдяки високоякісним компонентам та особливому хімічному складу, 
препарат має особливу полірувальну та захисну здатність, швидко висихає, 
що дає можливість у найкоротші терміни виконувати наступні роботи з обробки поверхні.`
},
{
    id: "289",
    name: "Віск Bellizoni прозорий",
    sizes: "250мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск 0,250 мл Bellizoni прозорий 170грн.jpg",
    price: 170,
    desc: `Густий віск Bellinzoni (Прозорий) - призначений для полірування будь-яких видів каміння.
Завдяки високоякісним компонентам та особливому хімічному складу, 
препарат має особливу полірувальну та захисну здатність, швидко висихає, 
що дає можливість у найкоротші терміни виконувати наступні роботи з обробки поверхні.`
},
{
    id: "290",
    name: "Віск Bellizoni зелений",
    sizes: "250мл",
    category: Perm,
    subCategory: Polishes,
    urlImg: "/images/Віск 0,250 мл Bellizoni зелений 180грн.jpg",
    price: 180,
    desc: `Густий віск Bellinzoni (Зелений) - призначений для полірування будь-яких видів каміння.
Завдяки високоякісним компонентам та особливому хімічному складу, 
препарат має особливу полірувальну та захисну здатність, швидко висихає, 
що дає можливість у найкоротші терміни виконувати наступні роботи з обробки поверхні.`
},
{
    id: "291",
    name: "Каталоги дитячі пам'ятники",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: "/images/Каталоги дитячі пам_ятники 650грн.jpg",
    price: 650
},
{
    id: "292",
    name: "Каталог тваринам",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: ["/images/Каталог тваринам 550грн (1).jpg", "/images/Каталог тваринам 550грн (2).jpg", "/images/Каталог тваринам 550грн (3).jpg"],
    price: 550
},
{
    id: "293",
    name: "Каталог Коростишів(800фото)",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: ["/images/Каталог Коростиші(800фото) 1600грн (1).jpg", "/images/Каталог Коростишів(800фото) 1600грн(2).jpg", "/images/Каталог Коростишів(800фото) 1600грн(3).jpg"],
    price: 2000
},
{
    id: "294",
    name: "Каталог Київ Міське",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: ["/images/Каталог Київ Міське 900грн (1).jpg", "/images/Каталог Київ Міське 900грн (2)"],
    price: 1300
},
{
    id: "295",
    name: "Каталог Київ Лісове",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: ["/images/Каталог Київ Лісове 900грн (1).jpg", "/images/Каталог Київ Лісове 900грн (2)", "/images/Каталог Київ Лісове 900грн (3)"],
    price: 1100
},
{
    id: "296",
    name: "Каталог Київ Байковое",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: ["/images/Каталог Київ Байковое 850грн (1).jpg", "/images/Каталог Київ Байковое 850грн (2)"],
    price: 1000
},
{
    id: "297",
    name: "Каталог Каменетес №2,3",
    sizes: " ",
    category: Others,
    subCategory: Catalogs,
    urlImg: ["/images/Каталог Каменетес №2,3 60грн (1).jpg", "/images/Каталог Каменетес №2,3 60грн (2)", "/images/Каталог Каменетес №2,3 60грн (3)"],
    price: 60
},
{
    id: "303",
    name: "Шарошки KRZEMEX B",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки KRZEMEX D100 B 215грн (1).jpg", "/images/Шарошки KRZEMEX D100 B 215грн (2).jpg"],
    price: 215
},
{
    id: "304",
    name: "Шарошки KRZEMEX B",
    sizes: "D70",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки KRZEMEX D70 B 190грн (1).jpg", "/images/Шарошки KRZEMEX D70 B 190грн (2).jpg"],
    price: 190
},
{
    id: "307",
    name: "Шарошки FOX",
    sizes: "D100 80x55",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки FOX D100 80x55 200грн (1).jpg", "/images/Шарошки FOX D100 80x55 200грн (2).jpg"],
    price: 200
},
{
    id: "308",
    name: "Шарошки FOX конус",
    sizes: "D76x40",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки FOX D76x40 конус 200грн (1).jpg", "/images/Шарошки FOX D76x40 конус 200грн (2).jpg"],
    price: 200
},
{
    id: "309",
    name: "Шарошки FOX конус",
    sizes: "110x90",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки FOX 110x90 конус 290грн (1).jpg", "/images/Шарошки FOX 110x90 конус 290грн (2).jpg"],
    price: 290
},
{
    id: "310",
    name: "Шарошки FEN метал",
    sizes: "D76x40",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки FEN D76x40 метал 170грн (1).jpg", "/images/Шарошки FEN D76x40 метал 170грн (2).jpg"],
    price: 170
},
{
    id: "311",
    name: "Шарошки FEN Abrasivi пряма",
    sizes: "D100x46",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки FEN Abrasivi D100x46 пряма 215грн (1).jpg", "/images/Шарошки FEN Abrasivi D100x46 пряма 215грн (2).jpg"],
    price: 215
},
{
    id: "313",
    name: "Шарошки Abrasini конус",
    sizes: "D110х90",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки Abrasini D110x90 конус 250грн (1).jpg", "/images/Шарошки Abrasini D110x90 конус 250грн (2).jpg"],
    price: 250
},
{
    id: "314",
    name: "Шарошки Abrasini прямі",
    sizes: "D80х46",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Шарошки Abrasini D80x46 прямі 200грн (1).jpg", "/images/Шарошки Abrasini D80x46 прямі 200грн (2).jpg"],
    price: 200
},
{
    id: "315",
    name: "Шарошки Abrasini M14",
    sizes: "D100 80х50",
    category: PolishingProducts,
    subCategory: Layers,
    urlImg: ["/images/Фікерти Abrasini D100 80x50 M14 190грн (1).jpg", "/images/Фікерти Abrasini D100 80x50 M14 190грн (2).jpg"],
    price: 190
},
{
    id: "316",
    name: "Фреза Palmina №3(100.200)",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза D100 Palmina №3(100.200) 750грн (1).jpg", "/images/Фреза D100 Palmina №3(100.200) 750грн (2).jpg"],
    price: 750
},
{
    id: "317",
    name: "Фреза Baumesser синя",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: "/images/Фреза Baumesser D100 синя 750грн.jpg",
    price: 750
},
{
    id: "318",
    name: "Фреза для фігурної обробки граніту 5С",
    sizes: " ",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза для фігурної обробки граніту 5С 3810грн (1).jpg", "/images/Фреза для фігурної обробки граніту 5С 3810грн (2).jpg"],
    price: 3810
},
{
    id: "319",
    name: "Фреза алмазна StoneCraft №2",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна StoneCraft D100 №2 600грн (1).jpg", "/images/Фреза алмазна StoneCraft D100 №2 600грн (2).jpg"],
    price: 600
},
{
    id: "320",
    name: "Фреза алмазна FY №2",
    sizes: " ",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна FY №2 1000грн (1).jpg", "/images/Фреза алмазна FY №2 1000грн (2).jpg"],
    price: 1000
},
{
    id: "321",
    name: "Фреза алмазна FY №00(двохрядка)",
    sizes: " ",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна FY №00(двохрядка) 1000грн (1).jpg", "/images/Фреза алмазна FY №00(двохрядка) 1000грн (2).jpg"],
    price: 1000
},
{
    id: "322",
    name: "Фреза алмазна FY №00",
    sizes: " ",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна FY №00 1000грн (1).jpg", "/images/Фреза алмазна FY №00 1000грн (2).jpg"],
    price: 1000
},
{
    id: "323",
    name: "Фреза алмазна ELITE DIAMOND №5",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна ELITE DIAMOND D100 №5 1050грн (1).jpg", "/images/Фреза алмазна ELITE DIAMOND D100 №5 1050грн (2).jpg"],
    price: 1050
},
{
    id: "324",
    name: "Фреза алмазна ELITE DIAMOND №0 30.40",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна ELITE DIAMOND D100 №0 30.40 1550грн (1).jpg", "/images/Фреза алмазна ELITE DIAMOND D100 №0 30.40 1550грн (2).jpg"],
    price: 1550
},
{
    id: "325",
    name: "Фреза алмазна ELITE DIAMOND №00 25.30",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна ELITE DIAMOND D100 №00 25.30 1550грн (2).jpg", "/images/Фреза алмазна ELITE DIAMOND D100 №00 25.30 1550грн (1).jpg"],
    price: 1550
},
{
    id: "326",
    name: "Фреза алмазна Dialink №4",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Dialink D100 №4 900грн (1).jpg", "/images/Фреза алмазна Dialink D100 №4 900грн (2).jpg"],
    price: 900
},
{
    id: "327",
    name: "Фреза алмазна Інватех прорізний мрамур",
    sizes: "D130",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D130 прорізний мрамур 1630грн (1).jpg", "/images/Фреза алмазна Інватех D130 прорізний мрамур 1630грн (2).jpg"],
    price: 1630
},
{
    id: "328",
    name: "Фреза алмазна Інватех тайфун №0",
    sizes: "D127",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D127 тайфун №0 1670грн (1).jpg", "/images/Фреза алмазна Інватех D127 тайфун №0 1670грн (2).jpg"],
    price: 1670
},
{
    id: "329",
    name: "Фреза алмазна Інватех циклон 0",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D125 циклон 0 2330грн (1).jpg", "/images/Фреза алмазна Інватех D125 циклон 0 2330грн (2).jpg"],
    price: 2630
},
{
    id: "330",
    name: "Фреза алмазна Інватех ромашка 0",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D125 ромашка 0 2520грн (1).jpg", "/images/Фреза алмазна Інватех D125 ромашка 0 2520грн (2).jpg"],
    price: 2840
},
{
    id: "331",
    name: "Фреза алмазна Інватех циклон 0",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D100 циклон 0 2040грн (1).jpg", "/images/Фреза алмазна Інватех D100 циклон 0 2040грн (2).jpg"],
    price: 2300
},
{
    id: "332",
    name: "Фреза алмазна Інватех фат 1",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D100 фат 1 1480грн (1).jpg", "/images/Фреза алмазна Інватех D100 фат 1 1480грн (2).jpg"],
    price: 1480
},
{
    id: "333",
    name: "Фреза алмазна Інватех тайфун",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D100 тайфун 1480грн (1).jpg", "/images/Фреза алмазна Інватех D100 тайфун 1480грн (2).jpg"],
    price: 1670
},
{
    id: "334",
    name: "Фреза алмазна Інватех ромашка 0",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D100 ромашка 0 2260грн (1).jpg", "/images/Фреза алмазна Інватех D100 ромашка 0 2260грн (2).jpg"],
    price: 2550
},
{
    id: "335",
    name: "Фреза алмазна Інватех прорізний",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D100 прорізний 1220грн (1).jpg", "/images/Фреза алмазна Інватех D100 прорізний 1220грн (2).jpg"],
    price: 1220
},
{
    id: "336",
    name: "Фреза алмазна Інватех циклон 0",
    sizes: "D80",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D80 циклон 0 1520грн (1).jpg", "/images/Фреза алмазна Інватех D80 циклон 0 1520грн (2).jpg"],
    price: 1710
},
{
    id: "337",
    name: "Фреза алмазна Інватех ромашка 0",
    sizes: "D80",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D80 ромашка 0 1700грн (1).jpg", "/images/Фреза алмазна Інватех D80 ромашка 0 1700грн (2).jpg"],
    price: 1920
},
{
    id: "338",
    name: "Фреза алмазна Інватех циклон 0",
    sizes: "D50",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D50 циклон 0 1220грн (1).jpg", "/images/Фреза алмазна Інватех D50 циклон 0 1220грн (2).jpg"],
    price: 1380
},
{
    id: "339",
    name: "Фреза алмазна Інватех тайфун міні плоский №2",
    sizes: "D50",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D50 тайфун міні плоский №2 1480грн (1).jpg", "/images/Фреза алмазна Інватех D50 тайфун міні плоский №2 1480грн (2).jpg", "/images/Фреза алмазна Інватех D50 тайфун міні плоский №2 1480грн (3).jpg"],
    price: 1480
},
{
    id: "340",
    name: "Фреза алмазна Інватех тайфун випуклий №4",
    sizes: "D40",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D40 тайфун випуклий №4 1300грн (1).jpg", "/images/Фреза алмазна Інватех D40 тайфун випуклий №4 1300грн (2).jpg", "/images/Фреза алмазна Інватех D40 тайфун випуклий №4 1300грн (3).jpg"],
    price: 1300
},
{
    id: "341",
    name: "Фреза алмазна Інватех тайфун міні плоский №1",
    sizes: "D35",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D35 тайфун міні плоский №1 1300грн (1).jpg", "/images/Фреза алмазна Інватех D35 тайфун міні плоский №1 1300грн (2).jpg", "/images/Фреза алмазна Інватех D35 тайфун міні плоский №1 1300грн (3).jpg"],
    price: 1300
},
{
    id: "342",
    name: "Фреза алмазна Інватех тайфун міні граніт обернений конус №7",
    sizes: "D35",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D35 тайфун міні граніт обернений конус №7 1300грн (1).jpg", "/images/Фреза алмазна Інватех D35 тайфун міні граніт обернений конус №7 1300грн (2).jpg", "/images/Фреза алмазна Інватех D35 тайфун міні граніт обернений конус №7 1300грн (3).jpg"],
    price: 1300
},
{
    id: "343",
    name: "Фреза алмазна Інватех циклон 0",
    sizes: "D32",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D32 циклон 0 1040грн (1).jpg", "/images/Фреза алмазна Інватех D32 циклон 0 1040грн (2).jpg"],
    price: 1170
},
{
    id: "344",
    name: "Фреза алмазна Інватех тайфун циліндр №5",
    sizes: "D22",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D22 тайфун циліндр №5 1480грн (1).jpg", "/images/Фреза алмазна Інватех D22 тайфун циліндр №5 1480грн (2).jpg", "/images/Фреза алмазна Інватех D22 тайфун циліндр №5 1480грн (3).jpg"],
    price: 1670
},
{
    id: "345",
    name: "Фреза алмазна Інватех тайфун міні №12",
    sizes: "D22",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D22 тайфун міні №12 1480грн (1).jpg", "/images/Фреза алмазна Інватех D22 тайфун міні №12 1480грн (2).jpg", "/images/Фреза алмазна Інватех D22 тайфун міні №12 1480грн (3).jpg"],
    price: 1670
},
{
    id: "346",
    name: "Фреза алмазна Інватех тайфун міні граніт Пламя №9",
    sizes: "D22",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D22 тайфун міні граніт Пламя №9 1480грн (1).jpg", "/images/Фреза алмазна Інватех D22 тайфун міні граніт Пламя №9 1480грн (2).jpg"],
    price: 1670
},
{
    id: "347",
    name: "Фреза алмазна Інватех тайфун міні граніт гострий конус №11",
    sizes: "D22",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Інватех D22 тайфун міні граніт гострий конус №11 1480грн (1).jpg", "/images/Фреза алмазна Інватех D22 тайфун міні граніт гострий конус №11 1480грн (2).jpg", "/images/Фреза алмазна Інватех D22 тайфун міні граніт гострий конус №11 1480грн (3).jpg"],
    price: 1670
},
{
    id: "348",
    name: "Фреза алмазна Дельфін сферична №000",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна ДельфінD100 сферична №000 950грн (1).jpg", "/images/Фреза алмазна ДельфінD100 сферична №000 950грн (2).jpg"],
    price: 950
},
{
    id: "349",
    name: "Фреза алмазна Дельфін №00 гумова улітка",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Дельфін D125 №00 гумова улітка 1500грн (1).jpg", "/images/Фреза алмазна Дельфін D125 №00 гумова улітка 1500грн (2).jpg"],
    price: 1500
},
{
    id: "350",
    name: "Фреза алмазна Дельфін метал",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Дельфін D100 метал 1100грн (1).jpg", "/images/Фреза алмазна Дельфін D100 метал 1100грн (2).jpg"],
    price: 1100
},
{
    id: "351",
    name: "Фреза алмазна Дельфін кольорові №1",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Дельфін D100 кольорові №1 930грн (1).jpg", "/images/Фреза алмазна Дельфін D100 кольорові №1 930грн (2).jpg"],
    price: 970
},
{
    id: "352",
    name: "Фреза алмазна Дельфін гумова улітка№00",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: DiamondCutter,
    urlImg: ["/images/Фреза алмазна Дельфін D100 гумова улітка№00 960грн (1).jpg", "/images/Фреза алмазна Дельфін D100 гумова улітка№00 960грн (2).jpg"],
    price: 960
},
{
    id: "353",
    name: "Фікерти №1200 Схід Діамант",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти №1200 Схід Діамант 150грн (1).jpg", "/images/Фікерти №1200 Схід Діамант 150грн (2).jpg", "/images/Фікерти №1200 Схід Діамант 150грн (3).jpg", "/images/Фікерти №1200 Схід Діамант 150грн (4).jpg"],
    price: 150
},
{
    id: "354",
    name: "Фікерти №800 Схід Діамант",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти №800 Схід Діамант 150грн (1).jpg", "/images/Фікерти №800 Схід Діамант 150грн (2).jpg", "/images/Фікерти №800 Схід Діамант 150грн (3).jpg", "/images/Фікерти №800 Схід Діамант 150грн (4).jpg"],
    price: 150
},
{
    id: "355",
    name: "Фікерти №220 Схід Діамант",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти №220 Схід Діамант 110грн (1).jpg", "/images/Фікерти №220 Схід Діамант 110грн (2).jpg", "/images/Фікерти №220 Схід Діамант 110грн (3).jpg", "/images/Фікерти №220 Схід Діамант 110грн (4).jpg"],
    price: 110
},
{
    id: "356",
    name: "Фікерти №60 Схід Діамант",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти №60 Схід Діамант 130грн (1).jpg", "/images/Фікерти №60 Схід Діамант 130грн (2).jpg", "/images/Фікерти №60 Схід Діамант 130грн (3).jpg", "/images/Фікерти №60 Схід Діамант 130грн (4).jpg"],
    price: 130
},
{
    id: "357",
    name: "Фікерти №36 Пуст",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти №36 Пуст 145грн (1).jpg", "/images/Фікерти №36 Пуст 145грн (2).jpg", "/images/Фікерти №36 Пуст 145грн (3).jpg", "/images/Фікерти №36 Пуст 145грн (4).jpg"],
    price: 145
},
{
    id: "358",
    name: "Фікерти Lux сірий",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти Lux сірий 220грн (4).jpg", "/images/Фікерти Lux сірий 220грн (3).jpg", "/images/Фікерти Lux сірий 220грн (2).jpg", "/images/Фікерти Lux сірий 220грн (1).jpg"],
    price: 220
},
{
    id: "359",
    name: "Фікерти Abressa чорний",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти Abressa чорний 650грн (1).jpg", "/images/Фікерти Abressa чорний 650грн (2).jpg", "/images/Фікерти Abressa чорний 650грн (3).jpg", "/images/Фікерти Abressa чорний 650грн (4).jpg"],
    price: 650
},
{
    id: "360",
    name: "Фікерти малі",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Fickerts,
    urlImg: ["/images/Фікерти малі 50грн (1).jpg", "/images/Фікерти малі 50грн (2).jpg", "/images/Фікерти малі 50грн (3).jpg"],
    price: 50
},
{
    id: "361",
    name: "Пластмасa Дельфін №1-4",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пластмаса 250 Дельфін №1-4 620грн.jpg",
    price: 620
},
{
    id: "362",
    name: "Пласмаса FY №4",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса FY №4 600грн.jpg",
    price: 600
},
{
    id: "363",
    name: "Пласмаса №100 Б.П iC",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №100 Б.П iC 780грн.jpg",
    price: 780
},
{
    id: "364",
    name: "Пласмаса №4SE Автолюкс",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №4SE Автолюкс 720грн.jpg",
    price: 720
},
{
    id: "365",
    name: "Пласмаса №4е однорядне П",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №4е однорядне П 690грн.jpg",
    price: 690
},
{
    id: "366",
    name: "Пласмаса №4е Б.П посилена",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №4е Б.П посилена 1100грн.jpg",
    price: 1100
},
{
    id: "367",
    name: "Пласмаса №4 №4е двухрядка РОМ",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №4 №4е двухрядка РОМ 850грн.jpg",
    price: 850
},
{
    id: "368",
    name: "Пласмаса №4 П",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №4 П 700грн.jpg",
    price: 700
},
{
    id: "369",
    name: "Пласмаса №0-№4 ус.РОМ",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 №0-№4 ус.РОМ 960грн.jpg",
    price: 960
},
{
    id: "370",
    name: "Пласмаса N4e ус шахматка П",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 N4e ус шахматка П 920грн.jpg",
    price: 920
},
{
    id: "371",
    name: "Пласмаса ELIT",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Plastic,
    urlImg: "/images/Пласмаса D250 ELIT 700грн.jpg",
    price: 700
},
{
    id: "372",
    name: "Пласмаса FY №1-4 сегментована",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса FY №1-4 сегментована 850грн.jpg",
    price: 850
},
{
    id: "373",
    name: "Пласмаса №0",
    sizes: "D320",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D320 №0 650грн.jpg",
    price: 650
},
{
    id: "374",
    name: "Пласмаса №0е Автолюкс",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 №0е Автолюкс 720грн.jpg",
    price: 720
},
{
    id: "375",
    name: "Пласмаса №0 Elite",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 №0 Elite 700грн.jpg",
    price: 700
},
{
    id: "376",
    name: "Пласмаса №00 ELIT",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 №00 ELIT 700грн.jpg",
    price: 700
},
{
    id: "377",
    name: "Пласмаса №0 шахм П",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 №0 шахм П 920грн.jpg",
    price: 920
},
{
    id: "378",
    name: "Пласмаса №0 низький на 7сегм.РОМ",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 №0 низький на 7сегм.РОМ 420грн.jpg",
    price: 420
},
{
    id: "379",
    name: "Пласмаса №0 високий РОМ",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 №0 високий РОМ 600грн.jpg",
    price: 600
},
{
    id: "380",
    name: "Пласмаса D250 0e Хмілик",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Scraps,
    urlImg: "/images/Пласмаса D250 0e Хмілик 920грн.jpg",
    price: 920
},
{
    id: "381",
    name: "Матриця Sankyo",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриця Sankyo D100 90грн (1).jpg", "/images/Матриця Sankyo D100 90грн (2).jpg"],
    price: 90
},
{
    id: "382",
    name: "Матриця- Black Star",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриця D125 Black Star 65грн (1).jpg", "/images/Матриця D125 Black Star 65грн (2).jpg"],
    price: 65
},
{
    id: "383",
    name: "Матриця NovoTools",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриця D100 NovoTools 85грн (1).jpg", "/images/Матриця D100 NovoTools 85грн (2).jpg"],
    price: 85
},
{
    id: "384",
    name: "Матриця металева",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриця D100 металева 350грн (1).jpg", "/images/Матриця D100 металева 350грн (2).jpg"],
    price: 350
},
{
    id: "385",
    name: "Матриця",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриця D100 110грн (1).jpg", "/images/Матриця D100 110грн (2).jpg"],
    price: 110
},
{
    id: "386",
    name: "Матриця резинова",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриця резиона 50грн (1).jpg", "/images/Матриця резиона 50грн (2).jpg"],
    price: 50
},
{
    id: "387",
    name: "Матриці червона",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D250 червона 195грн (1).jpg", "/images/Матриці D250 червона 195грн (2).jpg"],
    price: 195
},
{
    id: "388",
    name: "Матриці NovoTools",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D125 NovoTools 130грн (1).jpg", "/images/Матриці D125 NovoTools 130грн (2).jpg"],
    price: 130
},
{
    id: "389",
    name: "Матриці Дельфін",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D125 Дельфін 125грн (1).jpg", "/images/Матриці D125 Дельфін 125грн (2).jpg"],
    price: 125
},
{
    id: "390",
    name: "Матриці пласмаса червона",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D100 пласмаса червона 60грн (1).jpg", "/images/Матриці D100 пласмаса червона 60грн (2).jpg"],
    price: 60
},
{
    id: "391",
    name: "Матриці П",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D100 П 100грн (1).jpg", "/images/Матриці D100 П 100грн (2).jpg"],
    price: 110
},
{
    id: "392",
    name: "Матриці Китай",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D100 Китай 40грн (1).jpg", "/images/Матриці D100 Китай 40грн (2).jpg"],
    price: 40
},
{
    id: "393",
    name: "Матриці Дельфін резина",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D100 Дельфін резина 85грн (1).jpg", "/images/Матриці D100 Дельфін резина 85грн (2).jpg"],
    price: 85
},
{
    id: "394",
    name: "Матриці Дельфін",
    sizes: "D80",
    category: PolishingProducts,
    subCategory: Matrices,
    urlImg: ["/images/Матриці D80 Дельфін 85грн (1).jpg", "/images/Матриці D80 Дельфін 85грн (2).jpg"],
    price: 85
},
{
    id: "395",
    name: "Войлок Турція, DiaTools",
    sizes: "D320",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D320 Турція, DiaTools 2250грн.jpg",
    price: 2250
},
{
    id: "396",
    name: "Войлок м'який",
    sizes: "D300",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D300 м_який 1500грн.jpg",
    price: 1500
},
{
    id: "397",
    name: "Войлок S ELITE",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D250 S ELITE 1250грн.jpg",
    price: 1250
},
{
    id: "398",
    name: "Войлок DiaTools",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D250 DiaTools 1250грн.jpg",
    price: 1250
},
{
    id: "399",
    name: "Войлок Турція середній",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D250 Турція середній 1400грн.jpg",
    price: 1400
},
{
    id: "400",
    name: "Войлок клеймо Турція",
    sizes: "D250",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D250 клеймо Турція 1950грн.jpg",
    price: 1950
},
{
    id: "401",
    name: "Войлок середній Турція",
    sizes: "D200",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D200 середній Турція 800грн.jpg",
    price: 800
},
{
    id: "402",
    name: "Войлок клеймо Турція",
    sizes: "D200",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D200 середній Турція 800грн.jpg", "/images/Войлок D200 клеймо Турція 1100грн (2).jpg"],
    price: 800
},
{
    id: "403",
    name: "Войлок Турція",
    sizes: "D160x40",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D160x40 Турція 630грн.jpg",
    price: 630
},
{
    id: "404",
    name: "Войлок н.матр SHARK",
    sizes: "D125x25",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D125x25 н.матр SHARK 260грн (1).jpg", "/images/Войлок D125x25 н.матр SHARK 260грн (2).jpg"],
    price: 260
},
{
    id: "405",
    name: "Войлок на матр M14 DiaTools",
    sizes: "D125x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D125x20 на матр M14 DiaTools 250грн (1).jpg", "/images/Войлок D125x20 на матр M14 DiaTools 250грн (2).jpg"],
    price: 250
},
{
    id: "406",
    name: "Войлок н.матр твердий Elit",
    sizes: "D125x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D125x20 н.матр твердий Elit 250грн (1).jpg", "/images/Войлок D125x20 н.матр твердий Elit 250грн (2).jpg"],
    price: 250
},
{
    id: "407",
    name: "Войлок н.л DiaTools",
    sizes: "D125x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D125x20 н.л DiaTools 230грн. (1).jpg", "/images/Войлок D125x20 н.л DiaTools 230грн. (2).jpg"],
    price: 230
},
{
    id: "408",
    name: "Войлок б.л DiaTools",
    sizes: "D125x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D125x20 б.л DiaTools 190грн..jpg",
    price: 190
},
{
    id: "409",
    name: "Войлок б.л",
    sizes: "D125x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D125x20 б.л  130грн..jpg",
    price: 130
},
{
    id: "410",
    name: "Войлок н.л DiaTools",
    sizes: "D125x10",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D125x10 н.л DiaTools 80грн. (1).jpg", "/images/Войлок D125x10 н.л DiaTools 80грн. (2).jpg"],
    price: 80
},
{
    id: "411",
    name: "Войлок б.л DiaTools",
    sizes: "D125x10",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D125x10 б.л DiaTools 70грн (1).jpg", "/images/Войлок D125x10 б.л DiaTools 70грн (2).jpg"],
    price: 70
},
{
    id: "412",
    name: "Войлок н.м M14 DiaTools",
    sizes: "D100x40",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x40 н.м M14 DiaTools 280грн (1).jpg", "/images/Войлок D100x40 н.м M14 DiaTools 280грн (2).jpg"],
    price: 280
},
{
    id: "413",
    name: "Войлок н.м рез M14 DiaTools",
    sizes: "D100x40",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x40 н.м рез M14 DiaTools 340грн (1).jpg", "/images/Войлок D100x40 н.м рез M14 DiaTools 340грн (2).jpg"],
    price: 340
},
{
    id: "414",
    name: "Войлок б.л сірий",
    sizes: "D100x40",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: "/images/Войлок D100x40 б.л сірий 200грн.jpg",
    price: 200
},
{
    id: "415",
    name: "Войлок на матр.рез M14 DiaTools",
    sizes: "D100x30",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x30 на матр.рез M14 DiaTools 230грн (1).jpg", "/images/Войлок D100x30 на матр.рез M14 DiaTools 230грн (2).jpg"],
    price: 230
},
{
    id: "416",
    name: "Войлок D100x30 н.л ELIT",
    sizes: "D100x30",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x30 н.л ELIT 250грн (1).jpg", "/images/Войлок D100x30 н.л ELIT 250грн (2).jpg"],
    price: 250
},
{
    id: "417",
    name: "Войлок н.л Diatools",
    sizes: "D100x30",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x30 н.л Diatools 200грн (1).jpg", "/images/Войлок D100x30 н.л Diatools 200грн (2).jpg"],
    price: 200
},
{
    id: "418",
    name: "Войлок н.матр твердий Elit",
    sizes: "D100x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x20 н.матр твердий Elit 275грн (1).jpg", "/images/Войлок D100x20 н.матр твердий Elit 275грн (2).jpg"],
    price: 275
},
{
    id: "419",
    name: "Войлок н.л ELIT",
    sizes: "D100x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x20 н.л ELIT 175грн. (1).jpg", "/images/Войлок D100x20 н.л ELIT 175грн. (2).jpg"],
    price: 175
},
{
    id: "420",
    name: "Войлок н.л",
    sizes: "D100x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x20 н.л 150грн (1).jpg", "/images/Войлок D100x20 н.л 150грн (2).jpg"],
    price: 150
},
{
    id: "421",
    name: "Войлок н.л ELIT",
    sizes: "D100x15",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x15н.л ELIT 150грн (1).jpg", "/images/Войлок D100x15н.л ELIT 150грн (2).jpg"],
    price: 150
},
{
    id: "422",
    name: "Войлок н.л DiaTools",
    sizes: "D100x10",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100x10 н.л DiaTools 70грн. (1).jpg", "/images/Войлок D100x10 н.л DiaTools 70грн. (2).jpg"],
    price: 70
},
{
    id: "423",
    name: "Войлок н.л DiaTools",
    sizes: "D100x40",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D100х40 н.л DiaTools 255грн. (1).jpg", "/images/Войлок D100х40 н.л DiaTools 255грн. (2).jpg"],
    price: 255
},
{
    id: "424",
    name: "Войлок н.матр DiaTools",
    sizes: "D80x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D80x20 н.матр DiaTools 145грн (1).jpg", "/images/Войлок D80x20 н.матр DiaTools 145грн (2).jpg"],
    price: 145
},
{
    id: "425",
    name: "Войлок н.м DiaTools",
    sizes: "D70x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D70x20 н.м DiaTools 90грн (1).jpg", "/images/Войлок D70x20 н.м DiaTools 90грн (2).jpg"],
    price: 90
},
{
    id: "426",
    name: "Войлок сірий на матриці",
    sizes: "D50",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок D50 сірий на матриці 45грн (1).jpg", "/images/Войлок D50 сірий на матриці 45грн (2).jpg"],
    price: 45
},
{
    id: "427",
    name: "Войлок б.л DiaTools",
    sizes: "100x20",
    category: PolishingProducts,
    subCategory: Felts,
    urlImg: ["/images/Войлок 100x20 б.л DiaTools 155грн (1).jpg", "/images/Войлок 100x20 б.л DiaTools 155грн (2).jpg"],
    price: 155
},
{
    id: "428",
    name: "Полірувальний круг BUFF Сх.Діам(чорний,червоний)",
    sizes: "D200",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Полірувальний круг D200 BUFF Сх.Діам(чорний,червоний) 1300грн (1).jpg", "/images/Полірувальний круг D200 BUFF Сх.Діам(чорний,червоний) 1300грн (2).jpg"],
    price: 1400
},
{
    id: "429",
    name: "Липучки Sen-D BUFF",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучки Sen-D D100 BUFF 400грн (1).jpg", "/images/Липучки Sen-D D100 BUFF 400грн (2).jpg"],
    price: 400
},
{
    id: "430",
    name: "Липучки ELITE BUFF",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучки ELITE D125 BUFF 580грн (1).jpg", "/images/Липучки ELITE D125 BUFF 580грн (2).jpg"],
    price: 580
},
{
    id: "431",
    name: "Липучки BUFF ELITE",
    sizes: "D150",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучки BUFF D150 ELITE 790грн (1).jpg", "/images/Липучки BUFF D150 ELITE 790грн (2).jpg"],
    price: 790
},
{
    id: "432",
    name: "Липучка StoneCraft BUFF",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучка StoneCraft D125 BUFF 370грн (1).jpg", "/images/Липучка StoneCraft D125 BUFF 370грн (2).jpg"],
    price: 370
},
{
    id: "433",
    name: "Липучка Buff FY",
    sizes: "D125",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучка D125 Buff FY 270грн (1).jpg", "/images/Липучка D125 Buff FY 270грн (2).jpg"],
    price: 270
},
{
    id: "434",
    name: "Липучка BUFF ВП",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучка BUFF ВП 400грн (1).jpg", "/images/Липучка BUFF ВП 400грн (2).jpg"],
    price: 400
},
{
    id: "435",
    name: "Липучка трапеція SEN-D",
    sizes: " ",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучка трапеція SEN-D 495грн (1).jpg", "/images/Липучка трапеція SEN-D 495грн (2).jpg"],
    price: 500
},
{
    id: "436",
    name: "Липучка 100 BUFF huangchang",
    sizes: "D100",
    category: PolishingProducts,
    subCategory: Buffs,
    urlImg: ["/images/Липучка 100 BUFF huangchang 220грн (1).jpg", "/images/Липучка 100 BUFF huangchang 220грн (2).jpg"],
    price: 220
},
{
    id: "437",
    name: "Голка цільний фінський победит",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Points,
    urlImg: "/images/Голка цільний фінський победит.jpg",
    price: "Ціну уточнюйте"
},

{
    id: "438",
    name: "Голка паяна D3,0 7,19шт Хм конусні",
    sizes: "D0.8-3",
    category: ForArtisticWorks,
    subCategory: Points,
    urlImg: ["/images/голка-паяна.jpg","/images/голка-паяна2.jpg","/images/голка-паяна3.jpg","/images/голка-паяна4.jpg",],
    price: "Ціну уточнюйте"
},
{
    id: "439",
    name: "Голка паяна 19,37,61,91шт  Коростишів",
    sizes: "D0.5-3",
    category: ForArtisticWorks,
    subCategory: Points,
    urlImg: ["/images/Голка паяна 37,61,91шт Коростишів (1).jpg","/images/Голка паяна 37,61,91шт Коростишів (2).jpg","/images/Голка паяна 37,61,91шт Коростишів (3).jpg","/images/Голка паяна 37,61,91шт Коростишів (4).jpg","/images/Голка паяна 37,61,91шт Коростишів (5).jpg","/images/Голка паяна 37,61,91шт Коростишів (6).jpg",],
    price: "Ціну уточнюйте"
},
{
    id: "440",
    name: "Голка паяна Хм прямі",
    sizes: "D 1,2",
    category: ForArtisticWorks,
    subCategory: Points,
    urlImg: "/images/Голка паяна D 1,2 Хм прямі.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "442",
    name: "Набір скарпелів Хм L-3-35",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Chisels,
    urlImg: ["/images/Набір скарпелів Хм 850грн (2).jpg"],
    price: "Ціну уточнюйте"
},
{
    id: "443",
    name: "Бучарда СДС Max16 зуб",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Butchers,
    urlImg: ["/images/Бучарда СДС Max16 зуб 950грн (1).jpg", "/images/Бучарда СДС Max16 зуб 950грн (2).jpg"],
    price: 950
},
{
    id: "444",
    name: "Бучарда ручна 9зуб",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Butchers,
    urlImg: ["/images/Бучарда ручна 9зуб 420грн (1).jpg", "/images/Бучарда ручна 9зуб 420грн (2).jpg"],
    price: 480
},
{
    id: "445",
    name: "Бучарда ручна 5 зуб",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Butchers,
    urlImg: ["/images/Бучарда ручна 5 зуб 320грн (1).jpg", "/images/Бучарда ручна 5 зуб 320грн (2).jpg"],
    price: 360
},
{
    id: "446",
    name: "Бучарда ручна 3 зуб",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Butchers,
    urlImg: ["/images/Бучарда ручна 3 зуб 350грн (1).jpg", "/images/Бучарда ручна 3 зуб 350грн (2).jpg"],
    price: 350
},
{
    id: "447",
    name: "Машинка Drim-3 Полтава",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: EngravingMachines,
    urlImg: "/images/Машинка Drim-3 Полтава 2150грн.jpg",
    price: 2150
},
{
    id: "448",
    name: "Машинка Drim 2 Полтава",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: EngravingMachines,
    urlImg: ["/images/Машинка Drim 2 Полтава 1700грн (1).jpg", "/images/Машинка Drim 2 Полтава 1700грн (2).jpg"],
    price: 1700
},
{
    id: "449",
    name: "Машинка Drim 1 Полтава",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: EngravingMachines,
    urlImg: ["/images/Машинка Drim 1 Полтава 1600грн (1).jpg", "/images/Машинка Drim 1 Полтава 1600грн (2).jpg"],
    price: 1600
},
{
    id: "450",
    name: "Машинка на 2 швидкості Коростишів",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: EngravingMachines,
    urlImg: "/images/Машинка на 2 швидкості Коростишів 1600грн.jpg",
    price: 2000
},
{
    id: "451",
    name: "Гравірувальна машинка ROYCE 350",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: EngravingMachines,
    urlImg: ["/images/Гравірувальна машинка ROYCE 350 1700Грн (1).jpg", "/images/Гравірувальна машинка ROYCE 350 1700Грн (2).jpg"],
    price: 1700
}, {
    id: "452",
    name: "Набір буриків шліфувальних",
    sizes: "D6",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Набір буриків шліфувальних D6 5шт 65грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "453",
    name: "Набір буриків алмазних SANKYO",
    sizes: "D6",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Набір буриків алмазних SANKYO D6 1950грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "454",
    name: "Набір буриків алмазних INTERTOOL",
    sizes: "D3",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Набір буриків алмазних INTERTOOL D3 10Шт 85грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "455",
    name: "Голка алмазна №3",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна №3 130грн.jpg",
    price: 130
},
{
    id: "456",
    name: "Голка алмазна №2",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна №2 115грн.jpg",
    price: 115
},
{
    id: "457",
    name: "Голка алмазна №1 біла",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна №1 біла 125грн.jpg",
    price: 125
},
{
    id: "458",
    name: "Голка алмазна APT шестигран 0,03,0,04",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна APT шестигран 0,03,0,04 55грн.jpg",
    price: 55
},
{
    id: "459",
    name: "Голка алмазна APT з великою різьбою 0,06",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна APT з великою різьбою 0,06 110грн.jpg",
    price: 110
},
{
    id: "460",
    name: "Голка алмазна APT з великою різьбою 0,03",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна APT з великою різьбою 0,03 80грн.jpg",
    price: 80
},
{
    id: "461",
    name: "Голка алмазна APT Без хвост 0,11",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна APT Без хвост 0,11 125грн.jpg",
    price: 125
},
{
    id: "462",
    name: "Голка алмазна Якутія",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна Якутія 160грн.jpg",
    price: 160
},
{
    id: "463",
    name: "Голка алмазна природа",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна природа 140грн.jpg",
    price: 140
},
{
    id: "464",
    name: "Голка алмазна подовжена з кантиком",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна подовжена з кантиком 90грн.jpg",
    price: 90
},
{
    id: "465",
    name: "Голка алмазна на базальт 0,08 Леонід",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна на базальт 0,08 Леонід 140грн.jpg",
    price: 140
},
{
    id: "466",
    name: "Голка алмазна Монокристал",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна Монокристал 105грн.jpg",
    price: 105
},
{
    id: "467",
    name: "Голка алмазна мала різьба",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна мала різьба 140грн.jpg",
    price: 140
},
{
    id: "468",
    name: "Голка алмазна з різьбою вел Вінниця",
    sizes: "0,4",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна з різьбою вел 0,4 Вінниця 150грн.jpg",
    price: 150
},
{
    id: "469",
    name: "Голка алмазна жовта",
    sizes: "0,08-0,12",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна жовта 0,08-0,12 150грн.jpg",
    price: 170
},
{
    id: "470",
    name: "Голка алмазна до укр.станку Вінниця",
    sizes: "0,06-0,08",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна до укр.станку 0,06-0,08 Вінниця 150грн.jpg",
    price: 150
},
{
    id: "471",
    name: "Голка алмазна до укр.станка(Україночка граніт)",
    sizes: "0,08",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна до укр.станка(Україночка граніт) 0,08 110грн.jpg",
    price: 110
},
{
    id: "472",
    name: "Голка алмазна до укр.станка (Україночка граніт)",
    sizes: "0,06",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна до укр.станка (Україночка граніт) 0,06 85грн.jpg",
    price: 85
},
{
    id: "473",
    name: "Голка алмазна до укр.станка (Україночка граніт)",
    sizes: "0,03",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна до укр.станка (Україночка граніт) 0,03 70грн.jpg",
    price: 70
},
{
    id: "474",
    name: "Голка алмазна для українського станка",
    sizes: "0,08-0,1",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна для українського станка 0,08-0,1 120грн.jpg",
    price: 120
},
{
    id: "475",
    name: "Голка алмазна для правки абр.круга",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна для правки абр.круга 80грн.jpg",
    price: 80
},
{
    id: "476",
    name: "Голка алмазна Графік 3К  чорний наконечник",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна Графік 3К  чорний наконечник 120грн.jpg",
    price: 120
},
{
    id: "477",
    name: "Голка алмазна Вінниця 0,11 каратність",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна Вінниця 0,11 каратність 130грн.jpg",
    price: 130
},
{
    id: "478",
    name: "Голка алмазна в оправі",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна в оправі 80грн.jpg",
    price: 80
},
{
    id: "479",
    name: "Голка алмазна в бор машинку",
    sizes: "0,06-0,09",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна в бор машинку 0,06-0,09 110грн.jpg",
    price: 110
},
{
    id: "480",
    name: "Голка алмазна в бор машинку",
    sizes: "0,02-0,05",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна в бор машинку 0,02-0,05 60грн.jpg",
    price: 60
},
{
    id: "481",
    name: "Голка алмазна біла мала",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна біла мала 90грн.jpg",
    price: 90
},
{
    id: "482",
    name: "Голка алмазна без хвостовика",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна без хвостовика 110грн.jpg",
    price: 110
},
{
    id: "484",
    name: "Голка алмазна АРТ без хвост",
    sizes: "0,08",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна АРТ без хвост 0,08 95грн.jpg",
    price: 95
},
{
    id: "485",
    name: "Голка алмазна Австралія",
    sizes: "0,07-0,09",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна Австралія 0,07-0,09 160грн.jpg",
    price: 160
},
{
    id: "486",
    name: "Голка алмазна",
    sizes: "0,07-0,09",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,025-0,03 110грн.jpg",
    price: 110
},
{
    id: "487",
    name: "Голка алмазна САУНО АРТ",
    sizes: "0,18-0,21",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,18-0,21 САУНО АРТ 230грн.jpg",
    price: 230
},
{
    id: "489",
    name: "Голка алмазна САУНО АРТ",
    sizes: "0,13-0,17",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,13-0,17 САУНО АРТ 200грн.jpg",
    price: 200
},
{
    id: "490",
    name: "Голка алмазна Хм",
    sizes: "0,12",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,12 Білорусь Хм 115грн.jpg",
    price: 115
},
{
    id: "491",
    name: "Голка алмазна НОВІ",
    sizes: "0,11-0,15",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,11-0,15 НОВІ 120грн.jpg",
    price: 120
},
{
    id: "492",
    name: "Голка алмазна",
    sizes: "0,10",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,10 150грн.jpg",
    price: 150
},
{
    id: "493",
    name: "Голка алмазна жовта",
    sizes: "0,09",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,09 жовта 95грн.jpg",
    price: 95
},
{
    id: "494",
    name: "Голка алмазна Леонід природа",
    sizes: "0,08-0,10",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,08-0,10 Леонід природа 160грн.jpg",
    price: 160
},
{
    id: "495",
    name: "Голка алмазна Хм",
    sizes: "0,08",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,08 Білорусь Хм 100грн.jpg",
    price: 100
},
{
    id: "496",
    name: "Голка алмазна САУНА АРТ природа",
    sizes: "0,06",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,06 САУНА АРТ природа 120грн.jpg",
    price: 120
},
{
    id: "497",
    name: "Голка алмазна НОВІ",
    sizes: "0,06",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,06 НОВІ 90грн.jpg",
    price: 90
},
{
    id: "498",
    name: "Голка алмазна футляр чорний",
    sizes: "0,5-0,7",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,5-0,7 футляр чорний 135грн.jpg",
    price: 135
},
{
    id: "499",
    name: "Голка алмазна",
    sizes: "0,03-0,05",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,03-0,05 115грн.jpg",
    price: 115
},
{
    id: "500",
    name: "Голка алмазна природа",
    sizes: "0,03",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Голка алмазна 0,03 природа 120грн.jpg",
    price: 120
},
{
    id: "502",
    name: "Бурики алмазні",
    sizes: "D3",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Бурики алмазні D3,20, 28грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "503",
    name: "Бурики алмазні",
    sizes: "D3",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Бурики алмазні D3,20,  28грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "504",
    name: "Бурики алмазні",
    sizes: "D3",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Бурики алмазні D3,20,  28грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "505",
    name: "Бурики алмзні",
    sizes: "D3",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Бурики алмазні D3,20 28грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "506",
    name: "Бурик FY цанга 6",
    sizes: " ",
    category: ForArtisticWorks,
    subCategory: Beets,
    urlImg: "/images/Бурик FY цанга 6 330грн.jpg",
    price: "Ціну уточнюйте"
},
{
    id: "507",
    name: "Сверло алмазне Distar в дрель",
    sizes: "D35",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне Distar D35 в дрель 1225грн.jpg",
    price: 1225
},
{
    id: "508",
    name: "Сверло алмазне Distar в дрель",
    sizes: "D30",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне Distar D30 в дрель 1135грн.jpg",
    price: 1135
},
{
    id: "509",
    name: "Сверло алмазне Distar в дрель",
    sizes: "D20",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне Distar D30 в дрель 1135грн.jpg",
    price: 980
},
{
    id: "510",
    name: "Сверло алмазне Distar в дрель",
    sizes: "D18",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне Distar D18 в дрель 955грн.jpg",
    price: 985
},
{
    id: "511",
    name: "Сверло алмазне Distar в дрель",
    sizes: "D6",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне Distar D6 в дрель 820грн.jpg",
    price: 820
},
{
    id: "512",
    name: "Сверло алмазне CAK",
    sizes: "D48",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D48 CAK 1690грн.jpg",
    price: 1690
},
{
    id: "513",
    name: "Сверло алмазне CAK",
    sizes: "D46",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D46 CAK 1690грн.jpg",
    price: 1690
},
{
    id: "514",
    name: "Сверло алмазне Дельфін на болгарку",
    sizes: "D45",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D45 Дельфін на болгарку 1400грн.jpg",
    price: 1400
},
{
    id: "515",
    name: "Сверло алмазне CAK",
    sizes: "D42",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D42 CAK 1350грн.jpg",
    price: 1350
},
{
    id: "516",
    name: "Сверло алмазне Дельфін на болгарку",
    sizes: "D42",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D42 Дельфін на болгарку 1350грн.jpg",
    price: 1500
},
{
    id: "517",
    name: "Сверло алмазне CAK",
    sizes: "D38",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D38 CAK 1050грн.jpg",
    price: 1050
},
{
    id: "518",
    name: "Сверло алмазне CAK",
    sizes: "D32",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D32 CAK 850Грн.jpg",
    price: 850
},
{
    id: "519",
    name: "Сверло алмазне CAK",
    sizes: "D30",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D30 CAK 785Грн.jpg",
    price: 785
},
{
    id: "520",
    name: "Сверло алмазне D30 Самурай",
    sizes: "D30",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D30 Самурай 635грн.jpg",
    price: 635
},
{
    id: "521",
    name: "Сверло алмазне CAK",
    sizes: "D25",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D25 CAK 620Грн.jpg",
    price: 620
},
{
    id: "522",
    name: "Сверло алмазне Дельфін на болгарку",
    sizes: "D25",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D25 Дельфін на болгарку 830грн.jpg",
    price: 830
},
{
    id: "523",
    name: "Сверло алмазне CAK",
    sizes: "D24",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D24 CAK 590Грн.jpg",
    price: 590
},
{
    id: "524",
    name: "Сверло алмазне CAK",
    sizes: "D22",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D22 CAK 550грн.jpg",
    price: 550
},
{
    id: "525",
    name: "Сверло алмазне Дельфін на болгарку",
    sizes: "D22",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D22 Дельфін на болгарку 740грн.jpg",
    price: 900
},
{
    id: "526",
    name: "Сверло алмазне FY",
    sizes: "D20",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D20 FY 520грн.jpg",
    price: 520
},
{
    id: "527",
    name: "Сверло алмазне CAK",
    sizes: "D20",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D20 CAK 490грн.jpg",
    price: 490
},
{
    id: "528",
    name: "Сверло алмазне CAK",
    sizes: "D18",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D18 CAK 460грн.jpg",
    price: 460
},
{
    id: "529",
    name: "Сверло алмазне CAK (Граніт-мрамор)",
    sizes: "D18",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D18 CAK (Граніт-мрамор) 460грн.jpg",
    price: 460
},
{
    id: "530",
    name: "Сверло алмазне Самурай",
    sizes: "D18",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D18 Самурай 460грн.jpg",
    price: 460
},
{
    id: "531",
    name: "Сверло алмазне Distar на болгарку",
    sizes: "D16",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D16 Distar на болгарку 930грн.jpg",
    price: 930
},
{
    id: "532",
    name: "Сверло алмазне CAK",
    sizes: "D16",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D16 CAK 400Грн.jpg",
    price: 400
},
{
    id: "533",
    name: "Сверло алмазне CAK",
    sizes: "D14",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D14 CAK 380грн.jpg",
    price: 380
},
{
    id: "534",
    name: "Сверло алмазне Дельфін на болгарку",
    sizes: "D14",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D14 Дельфін на болгарку 490грн.jpg",
    price: 600
},
{
    id: "535",
    name: "Сверло алмазне FY на болгарку",
    sizes: "D12",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D12 FY на болгарку 480грн.jpg",
    price: 480
},
{
    id: "536",
    name: "Сверло алмазне CAK",
    sizes: "D12",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D12 CAK 400Грн.jpg",
    price: 400
},
{
    id: "537",
    name: "Сверло алмазне Самурай",
    sizes: "D12",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D12 Самурай 370грн.jpg",
    price: 370
},
{
    id: "538",
    name: "Сверло алмазне CAK",
    sizes: "D10",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D10 CAK 390грн.jpg",
    price: 390
},
{
    id: "539",
    name: "Сверло алмазне FY на болгарку",
    sizes: "D8",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D8 FY на болгарку 420грн.jpg",
    price: 420
},
{
    id: "540",
    name: "Сверло алмазне CAK",
    sizes: "D8",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D8 CAK 380Грн.jpg",
    price: 380
},
{
    id: "541",
    name: "Сверло алмазне FY",
    sizes: "D6",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D6 FY 400грн.jpg",
    price: 400
},
{
    id: "542",
    name: "Сверло алмазне CAК",
    sizes: "D6",
    category: ForArtisticWorks,
    subCategory: DiamondDrills,
    urlImg: "/images/Сверло алмазне D6 CAK 370грн.jpg",
    price: 370
},
{
    id: "543",
    name: "Алмазні круги для заточки",
    sizes: "D150х10",
    category: ForArtisticWorks,
    subCategory: DiamondSharpeningWheels,
    urlImg: "/images/Алмазні круги для заточки D150x10 125,100т 550грн.jpg",
    price: 550
},
{
    id: "544",
    name: "Алмазні круги для заточки",
    sizes: "D150х10",
    category: ForArtisticWorks,
    subCategory: DiamondSharpeningWheels,
    urlImg: "/images/Алмазні круги для заточки D150x10 80.63т 550грн.jpg",
    price: 550
},
{
    id: "545",
    name: "Алмазні круги для заточки",
    sizes: "D125",
    category: ForArtisticWorks,
    subCategory: DiamondSharpeningWheels,
    urlImg: "/images/Алмазні круги для заточки D125 160.125т 350грн.jpg",
    price: 350
},
{
    id: "546",
    name: "Алмазні круги для заточки",
    sizes: "D125",
    category: ForArtisticWorks,
    subCategory: DiamondSharpeningWheels,
    urlImg: "/images/Алмазні круги для заточки D125 160.125 450грн.jpg",
    price: 450
},
{ 
    id: "547", 
    name: "Липучка ромашка 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка ромашка D100 50 175грн (1).jpg ","/images/Липучка ромашка D100 50 175грн (2).jpg "], 
    price: 175
},
{ 
    id: "548", 
    name: "Липучка ромашка 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка ромашка D100 400 175грн (1).jpg ","/images/Липучка ромашка D100 400 175грн (2).jpg "], 
    price: 175 
},
{ 
    id: "549", 
    name: "Липучка ромашка  800", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка ромашка D100 800 175грн (1).jpg ","/images/Липучка ромашка D100 800 175грн (2).jpg "], 
    price: 175 
},
{ 
    id: "550", 
    name: "Липучка ромашка 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка ромашка D100 1500 175грн (1).jpg ","/images/Липучка ромашка D100 1500 175грн (2).jpg "], 
    price: 175 
},
{ 
    id: "551", 
    name: "Липучка ромашка 3000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка ромашка D100 3000 175грн (1).jpg ","/images/Липучка ромашка D100 3000 175грн (2).jpg "], 
    price: 175 
},
{ 
    id: "552", 
    name: "Липучка 4 кроки ромашка 1 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка D100 4 кроки ромашка 1  250грн  (1).jpg ","/images/Липучка D100 4 кроки ромашка 1  250грн  (2).jpg "], 
    price: 250 
},
{ 
    id: "553", 
    name: "Липучка 4 кроки ромашка 2 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка D100 4 кроки ромашка 2  250грн  (1).jpg ","/images/Липучка D100 4 кроки ромашка 2  250грн  (2).jpg "], 
    price: 250 
},
{ 
    id: "554", 
    name: "Липучка 4 кроки ромашка 3", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка D100 4 кроки ромашка 3  250грн  (1).jpg ","/images/Липучка D100 4 кроки ромашка 3  250грн  (2).jpg "], 
    price: 250 
},
{ 
    id: "555", 
    name: "Липучка 4 кроки ромашка 4", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка D100 4 кроки ромашка 4  250грн (1).jpg ","/images/Липучка D100 4 кроки ромашка 4  250грн (2).jpg "], 
    price: 250 
},
{ 
    id: "557", 
    name: "Липучка S обдірочна 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучка S обдірочна D100 400 240грн (1).jpg ","/images/Липучка S обдірочна D100 400 240грн (2).jpg "], 
    price: 240 
},
{ 
    id: "558", 
    name: "Липучки 3х кольорова 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 50 140грн  (1).jpg ","/images/Липучки 3х кольорова D100 50 140грн  (2).jpg "], 
    price: 160 
},
{ 
    id: "559", 
    name: "Липучки 3х кольорова 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 200 140грн (1).jpg ","/images/Липучки 3х кольорова D100 200 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "560", 
    name: "Липучки 3х кольорова 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 300 140грн (1).jpg ","/images/Липучки 3х кольорова D100 300 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "561", 
    name: "Липучки 3х кольорова 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 400 140грн (1).jpg ","/images/Липучки 3х кольорова D100 400 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "562", 
    name: "Липучки 3х кольорова 600", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 600 140грн (1).jpg ","/images/Липучки 3х кольорова D100 600 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "563", 
    name: "Липучки 3х кольорова 800", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 800 140грн (1).jpg ","/images/Липучки 3х кольорова D100 800 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "564", 
    name: "Липучки 3х кольорова 1000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 1000 140грн (1).jpg ","/images/Липучки 3х кольорова D100 1000 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "565", 
    name: "Липучки 3х кольорова 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 1500 140грн (1).jpg ","/images/Липучки 3х кольорова D100 1500 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "566", 
    name: "Липучки 3х кольорова 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 2000 140грн (1).jpg ","/images/Липучки 3х кольорова D100 2000 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "567", 
    name: "Липучки 3х кольорова 3000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D100 3000 140грн (1).jpg ","/images/Липучки 3х кольорова D100 3000 140грн (2).jpg "], 
    price: 160 
},
{ 
    id: "568", 
    name: "Липучки 3х кольорова 400", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D125 400 225грн (1).jpg ","/images/Липучки 3х кольорова D125 400 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "569", 
    name: "Липучки 3х кольорова 800", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D125 800 225грн  (1).jpg ","/images/Липучки 3х кольорова D125 800 225грн  (2).jpg "], 
    price: 225 
},
{ 
    id: "570", 
    name: "Липучки 3х кольорова 1500 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 3х кольорова D125 1500 225грн  (1).jpg ","/images/Липучки 3х кольорова D125 1500 225грн  (2).jpg "], 
    price: 225 
},
{ 
    id: "571", 
    name: "Липучки 4 кроки Хуанчанг білі 100", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки 4 кроки D125 Хуанчанг білі 100 280грн (1).jpg ","/images/Липучки 4 кроки D125 Хуанчанг білі 100 280грн (2).jpg "], 
    price: 280 
},
{ 
    id: "572", 
    name: "Липучки Дельфін універсальна  100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін  D100 універсальна  100 160грн(1).jpg ","/images/Липучки Дельфін  D100 універсальна  100 160грн(2).jpg "], 
    price: 160 
},
{ 
    id: "573", 
    name: "Липучки Дельфін пласмаса 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін D100 пласмаса 50 165грн (1).jpg ","/images/Липучки Дельфін D100 пласмаса 50 165грн (2).jpg "], 
    price: 165 
},
{ 
    id: "574", 
    name: "Липучки Дельфін пласмаса 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін D100 пласмаса 200 165 грн (1).jpg ","/images/Липучки Дельфін D100 пласмаса 200 165 грн (2).jpg "], 
    price: 165 
},
{ 
    id: "575", 
    name: "Липучки дельфін резина 30", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки дельфін D100 резина 30 290грн (1).jpg ","/images/Липучки дельфін D100 резина 30 290грн (2).jpg "], 
    price: 290 
},
{ 
    id: "576", 
    name: "Липучки Дельфін резина 100", 
    sizes: "D100 ",
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін D100 резина 100 290грн (1).jpg ","/images/Липучки Дельфін D100 резина 100 290грн (2).jpg "], 
    price: 290 
},
{ 
    id: "577", 
    name: "Липучки Дельфін резина 100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін D100 резина 100 290грн (1).jpg ","/images/Липучки Дельфін D100 резина 100 290грн (2).jpg "], 
    price: 290 
},
{ 
    id: "578", 
    name: "Липучки Дельфін універсальна 600", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін D100 універсальна 600 160грн (1).jpg ","/images/Липучки Дельфін D100 універсальна 600 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "579", 
    name: "Липучки Дельфін універсальна 3500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Дельфін D100 універсальна 3500 160грн (1).jpg ","/images/Липучки Дельфін D100 універсальна 3500 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "580", 
    name: "Липучки павутинка чорна 1", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки павутинка чорна D100 1 190грн (1).jpg ","/images/Липучки павутинка чорна D100 1 190грн (2).jpg "], 
    price: 220 
},
{ 
    id: "581", 
    name: "Липучки павутинка чорна 2", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки павутинка чорна D100 2 190грн (1).jpg ","/images/Липучки павутинка чорна D100 2 190грн (2).jpg "], 
    price: 220 
},
{ 
    id: "582", 
    name: "Липучки павутинка чорна 3", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки павутинка чорна D100 3 190грн (1).jpg ","/images/Липучки павутинка чорна D100 3 190грн (2).jpg "], 
    price: 220 
},
{ 
    id: "583", 
    name: "Липучки павутинка чорна 4", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки павутинка чорна D100 4 190грн (1).jpg ","/images/Липучки павутинка чорна D100 4 190грн (2).jpg "], 
    price: 220 
},
{ 
    id: "584", 
    name: "Липучки павутинка чорна 5", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки павутинка чорна D100 5 190грн (1).jpg ","/images/Липучки павутинка чорна D100 5 190грн (2).jpg "], 
    price: 220 
},
{ 
    id: "585", 
    name: "Липучки BGX 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 300 200грн (1).jpg ","/images/Липучки BGX D100 300 200грн (2).jpg "], 
    price: 200 
},
{ 
    id: "586", 
    name: "Липучки BGX 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 400 200грн (1).jpg ","/images/Липучки BGX D100 400 200грн (2).jpg "], 
    price: 200 
},
{ 
    id: "587", 
    name: "Липучки BGX 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 1500 200грн (1).jpg ","/images/Липучки BGX D100 1500 200грн (2).jpg "], 
    price: 200 
},
{ 
    id: "588", 
    name: "Липучки BGX 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 2000 200грн (1).jpg ","/images/Липучки BGX D100 2000 200грн (2).jpg "], 
    price: 200 
},
{ 
    id: "589", 
    name: "Липучки BGX Р", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 Р 200грн (1).jpg ","/images/Липучки BGX D100 Р 200грн (2).jpg "], 
    price: 200 
},
{ 
    id: "590", 
    name: "Липучки BGX суха 1", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха 1 180грн (1).jpg ","/images/Липучки BGX D100 суха 1 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "591", 
    name: "Липучки BGX суха 3", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха 3 180грн (1).jpg ","/images/Липучки BGX D100 суха 3 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "592", 
    name: "Липучки BGX суха 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха 300 180грн (1).jpg ","/images/Липучки BGX D100 суха 300 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "593", 
    name: "Липучки BGX суха 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха 1500 180грн (1).jpg ","/images/Липучки BGX D100 суха 1500 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "594", 
    name: "Липучки BGX суха 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха 2000 180грн (1).jpg ","/images/Липучки BGX D100 суха 2000 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "595", 
    name: "Липучки BGX суха 3000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха 3000 180грн (1).jpg ","/images/Липучки BGX D100 суха 3000 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "596", 
    name: "Липучки BGX суха Р", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D100 суха Р 180грн (1).jpg ","/images/Липучки BGX D100 суха Р 180грн (2).jpg "], 
    price: 180 
},
{ 
    id: "597", 
    name: "Липучки BGX 100", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 100 230грн (1).jpg ","/images/Липучки BGX D125 100 230грн (2).jpg "], 
    price: 230 
},
{ 
    id: "598", 
    name: "Липучки BGX 200", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 200 230грн. (1).jpg ","/images/Липучки BGX D125 200 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "599", 
    name: "Липучки BGX 300", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 300 230грн. (1).jpg ","/images/Липучки BGX D125 300 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "600", 
    name: "Липучки BGX 400", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 400 230грн. (1).jpg ","/images/Липучки BGX D125 400 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "601", 
    name: "Липучки BGX 600", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 600 230грн. (1).jpg ","/images/Липучки BGX D125 600 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "602", 
    name: "Липучки BGX 800", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 800 230грн. (1).jpg ","/images/Липучки BGX D125 800 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "603", 
    name: "Липучки BGX 1200", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 1200 230грн. (1).jpg ","/images/Липучки BGX D125 1200 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "604", 
    name: "Липучки BGX 1500", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 1500 230грн. (1).jpg ","/images/Липучки BGX D125 1500 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "605", 
    name: "Липучки BGX 2000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 2000 230грн. (1).jpg ","/images/Липучки BGX D125 2000 230грн. (2).jpg "], 
    price: 230 
},
{
    id: "606", 
    name: "Липучки BGX 3000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 3000 230грн. (1).jpg ","/images/Липучки BGX D125 3000 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "607", 
    name: "Липучки BGX Р", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки BGX D125 Р 230грн. (1).jpg ","/images/Липучки BGX D125 Р 230грн. (2).jpg "], 
    price: 230 
},
{ 
    id: "608", 
    name: "Липучки 5кроків мрамор S 1", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки D100 5кроків мрамор S 1 190 грн. (1).jpg ","/images/Липучки D100 5кроків мрамор S 1 190 грн. (2).jpg "], 
    price: 220 
},
{ 
    id: "609", 
    name: "Липучки 5кроків мрамор S 2", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки D100 5кроків мрамор S 2 190 грн. (1).jpg ","/images/Липучки D100 5кроків мрамор S 2 190 грн. (2).jpg "], 
    price: 220 
},
{ 
    id: "610", 
    name: "Липучки 5кроків мрамор S 3", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки D100 5кроків мрамор S 3 190 грн. (1).jpg ","/images/Липучки D100 5кроків мрамор S 3 190 грн. (2).jpg "], 
    price: 220 
},
{ 
    id: "611", 
    name: "Липучки 5кроків мрамор S 4", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки D100 5кроків мрамор S 4 190 грн. (1).jpg ","/images/Липучки D100 5кроків мрамор S 4 190 грн. (2).jpg "], 
    price: 220 
},
{ 
    id: "612", 
    name: "Липучки 5кроків мрамор S 5", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки D100 5кроків мрамор S 5 190 грн (1).jpg ","/images/Липучки D100 5кроків мрамор S 5 190 грн (2).jpg "], 
    price: 220 
},
{ 
    id: "613", 
    name: "Липучки обдірочна сота 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки D100 обдірочна сота 200 200грн (1).jpg ","/images/Липучки D100 обдірочна сота 200 200грн (2).jpg "], 
    price: 200 
},
{ 
    id: "614", 
    name: "Липучки Dia Tools 30", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 30 140грн  (1).jpg ","/images/Липучки Dia Tools D100 30 140грн  (2).jpg "], 
    price: 140 
},
{ 
    id: "615", 
    name: "Липучки Dia Tools 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 50 140грн  (1).jpg ","/images/Липучки Dia Tools D100 50 140грн  (2).jpg "], 
    price: 140 
},
{ 
    id: "616", 
    name: "Липучки Dia Tools 100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 100 140грн (1).jpg ","/images/Липучки Dia Tools D100 100 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "617", 
    name: "Липучки Dia Tools 150", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 150 140грн (1).jpg ","/images/Липучки Dia Tools D100 150 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "618", 
    name: "Липучки Dia Tools 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 200 140грн (1).jpg ","/images/Липучки Dia Tools D100 200 140грн (2).jpg "], 
    price: 140
},
{ 
    id: "619", 
    name: "Липучки Dia Tools 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 300 140грн (1).jpg ","/images/Липучки Dia Tools D100 300 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "620", 
    name: "Липучки Dia Tools 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 400 140грн (1).jpg ","/images/Липучки Dia Tools D100 400 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "621", 
    name: "Липучки Dia Tools 500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 500 140грн (1).jpg ","/images/Липучки Dia Tools D100 500 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "622", 
    name: "Липучки Dia Tools 600", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 600 140грн (1).jpg ","/images/Липучки Dia Tools D100 600 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "623", 
    name: "Липучки Dia Tools 1000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 1000 140грн (1).jpg ","/images/Липучки Dia Tools D100 1000 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "624", 
    name: "Липучки Dia Tools 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 1500 140грн (1).jpg ","/images/Липучки Dia Tools D100 1500 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "625", 
    name: "Липучки Dia Tools 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 2000 140грн (1).jpg ","/images/Липучки Dia Tools D100 2000 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "626", 
    name: "Липучки Dia Tools 3000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D100 3000 140грн (1).jpg ","/images/Липучки Dia Tools D100 3000 140грн (2).jpg "], 
    price: 140 
},
{ 
    id: "627", 
    name: "Липучки Dia Tools 50 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 50 260грн (1).jpg ","/images/Липучки Dia Tools D125 50 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "628", 
    name: "Липучки Dia Tools 100 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 100 260грн (1).jpg ","/images/Липучки Dia Tools D125 100 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "629", 
    name: "Липучки Dia Tools 150 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 150 260грн (1).jpg ","/images/Липучки Dia Tools D125 150 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "630", 
    name: "Липучки Dia Tools 200 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 200 260грн (1).jpg ","/images/Липучки Dia Tools D125 200 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "631", 
    name: "Липучки Dia Tools 300 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 300 260грн (1).jpg ","/images/Липучки Dia Tools D125 300 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "632", 
    name: "Липучки Dia Tools 400 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 400 260грн (1).jpg ","/images/Липучки Dia Tools D125 400 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "633", 
    name: "Липучки Dia Tools 500 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 500 260грн (1).jpg ","/images/Липучки Dia Tools D125 500 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "634", 
    name: "Липучки Dia Tools 600", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 600 260грн (1).jpg ","/images/Липучки Dia Tools D125 600 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "635",
    name: "Липучки Dia Tools 800", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 800 260грн (1).jpg ","/images/Липучки Dia Tools D125 800 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "636", 
    name: "Липучки Dia Tools 1000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 1000 260грн (1).jpg ","/images/Липучки Dia Tools D125 1000 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "637", 
    name: "Липучки Dia Tools 1500", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 1500 260грн (1).jpg ","/images/Липучки Dia Tools D125 1500 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "638", 
    name: "Липучки Dia Tools 2000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки Dia Tools D125 2000 260грн (1).jpg ","/images/Липучки Dia Tools D125 2000 260грн (2).jpg "], 
    price: 260 
},
{ 
    id: "639", 
    name: "Липучки ELITE квітка 4 кроки", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки ELITE квітка D100 4 кроки 350 грн(1).jpg ","/images/Липучки ELITE квітка D100 4 кроки 350 грн(2).jpg "], 
    price: 350 
},
{ 
    id: "640", 
    name: "Липучки ELITE квітка 4 кроки", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE квітка D100 4 кроки 350 грн(1).jpg ", 
    price: 350 
},
{ 
    id: "641", 
    name: "Липучки ELITE квітка 4 кроки", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE квітка D100 4 кроки 350 грн(2).jpg ", 
    price: 350 
},
{ 
    id: "642", 
    name: "Липучки ELITE квітка 4 кроки", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE квітка D100 4 кроки 350 грн(2+).jpg ", 
    price: 350 
},
{ 
    id: "643", 
    name: "Липучки ELITE квітка 4 кроки", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE квітка D100 4 кроки 350грн(3).jpg ", 
    price: 350 
},
{ 
    id: "644", 
    name: "Липучки ELITE 4 кроки S  1", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки ELITE D100 4 кроки S  1 220грн  (1).jpg ","/images/Липучки ELITE D100 4 кроки S  1 220грн  (2).jpg "], 
    price: 220 
},
{ 
    id: "645", 
    name: "Липучки ELITE 4 кроки S  2", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки ELITE D100 4 кроки S  2 220грн  (1).jpg ","/images/Липучки ELITE D100 4 кроки S  2 220грн  (2).jpg "], 
    price: 220 
},
{ 
    id: "646", 
    name: "Липучки ELITE 4 кроки S  3 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки ELITE D100 4 кроки S  3 220грн  (1).jpg ","/images/Липучки ELITE D100 4 кроки S  3 220грн  (2).jpg "], 
    price: 220 
},
{ 
    id: "647", 
    name: "Липучки ELITE 4 кроки S  4 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки ELITE D100 4 кроки S  4 220грн (1).jpg ","/images/Липучки ELITE D100 4 кроки S  4 220грн (2).jpg "], 
    price: 220 
},
{ 
    id: "648", 
    name: "Липучки ELITE ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185 грн (3000).jpg ", 
    price: 185 
},
{ 
    id: "649", 
    name: "Липучки ELITE ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185 грн(300).jpg ", 
    price: 185 
},
{ 
    id: "650", 
    name: "Липучки ELITE ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185 грн(600).jpg ", 
    price: 185 
},
{ 
    id: "651", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185 грн(1500).jpg ", 
    price: 185 
},
{ 
    id: "652", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(100).jpg ", 
    price: 185 
},
{ 
    id: "653", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(200).jpg ", 
    price: 185 
},
{ 
    id: "654", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(400).jpg ", 
    price: 185 
},
{ 
    id: "655", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(500).jpg ", 
    price: 185 
},
{ 
    id: "656", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(800).jpg ", 
    price: 185 
},
{ 
    id: "657", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(1000).jpg ", 
    price: 185 
},
{ 
    id: "658", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(1200).jpg ", 
    price: 185 
},
{ 
    id: "659", 
    name: "Липучки ELITE", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: "/images/Липучки ELITE D100 185грн(2000).jpg ", 
    price: 185 
},
{ 
    id: "660", 
    name: "Липучки PELE  6 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100  6 160грн (1).jpg ","/images/Липучки PELE D100  6 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "661", 
    name: "Липучки PELE 0 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 0 160грн (1).jpg ","/images/Липучки PELE D100 0 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "662", 
    name: "Липучки PELE 00 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 00 160грн (1).jpg ","/images/Липучки PELE D100 00 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "663", 
    name: "Липучки PELE 1  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 1 160грн (1).jpg ","/images/Липучки PELE D100 1 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "664", 
    name: "Липучки PELE 2 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 2 160грн (1).jpg ","/images/Липучки PELE D100 2 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "665", 
    name: "Липучки PELE 3 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 3 160грн (1).jpg ","/images/Липучки PELE D100 3 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "666", 
    name: "Липучки PELE 4  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 4 160грн (1).jpg ","/images/Липучки PELE D100 4 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "667", 
    name: "Липучки PELE 5 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 5 160грн (1).jpg ","/images/Липучки PELE D100 5 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "668", 
    name: "Липучки PELE 7  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 7 160грн (1).jpg ","/images/Липучки PELE D100 7 160грн (2).jpg "], 
    price: 160
},
{ 
    id: "669", 
    name: "Липучки PELE 8 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 8 160грн (1).jpg ","/images/Липучки PELE D100 8 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "670", 
    name: "Липучки PELE 9 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 9 160грн (1).jpg ","/images/Липучки PELE D100 9 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "671", 
    name: "Липучки PELE 00 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 00 225грн (1).jpg ","/images/Липучки PELE D125 00 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "672", 
    name: "Липучки PELE 0 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 0 225грн (1).jpg ","/images/Липучки PELE D125 0 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "673", 
    name: "Липучки PELE 1 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 1 225грн (1).jpg ","/images/Липучки PELE D125 1 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "674", 
    name: "Липучки PELE 2  ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 2 225грн (1).jpg ","/images/Липучки PELE D125 2 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "675", 
    name: "Липучки PELE 3 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 3 225грн (1).jpg ","/images/Липучки PELE D125 3 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "676", 
    name: "Липучки PELE 4 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 4 225грн (1).jpg ","/images/Липучки PELE D125 4 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "677", 
    name: "Липучки PELE 5", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 5 225грн (1).jpg ","/images/Липучки PELE D125 5 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "678", 
    name: "Липучки PELE 7", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 7 160грн (1).jpg ","/images/Липучки PELE D100 7 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "679", 
    name: "Липучки PELE 8 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 8 160грн (1).jpg ","/images/Липучки PELE D100 8 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "680", 
    name: "Липучки PELE 9", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D100 9 160грн (1).jpg ","/images/Липучки PELE D100 9 160грн (2).jpg "], 
    price: 160 
},
{ 
    id: "681", 
    name: "Липучки PELE 00", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 00 225грн (1).jpg ","/images/Липучки PELE D125 00 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "682", 
    name: "Липучки PELE 0 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 0 225грн (1).jpg ","/images/Липучки PELE D125 0 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "683", 
    name: "Липучки PELE 1 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 1 225грн (1).jpg ","/images/Липучки PELE D125 1 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "684", 
    name: "Липучки PELE 2 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 2 225грн (1).jpg ","/images/Липучки PELE D125 2 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "685", 
    name: "Липучки PELE 3 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 3 225грн (1).jpg ","/images/Липучки PELE D125 3 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "686", 
    name: "Липучки PELE 4 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 4 225грн (1).jpg ","/images/Липучки PELE D125 4 225грн (2).jpg "], 
    price: 225 
},
{ 
    id: "687", 
    name: "Липучки PELE 5 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 5 225грн (1).jpg ","/images/Липучки PELE D125 5 225грн (2).jpg "], 
    price: 225 
},
{
    id: "688", 
    name: "Липучки PELE 6 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 6 225грн (1).jpg ","/images/Липучки PELE D125 6 225грн (2).jpg "], 
    price: 225 
},
{
    id: "689", 
    name: "Липучки PELE 7 ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 7 225грн (1).jpg ","/images/Липучки PELE D125 7 225грн (2).jpg "], 
    price: 225 
},
{
    id: "690", 
    name: "Липучки PELE 8  ", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 8 225грн (1).jpg ","/images/Липучки PELE D125 8 225грн (2).jpg "], 
    price: 225 
},
{
    id: "691", 
    name: "Липучки PELE 9", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки PELE D125 9 225грн (1).jpg ","/images/Липучки PELE D125 9 225грн (2).jpg "], 
    price: 225 
},
{
    id: "692", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195 грн(200).jpg ","/images/Липучки SEN-D D100 195 грн(201).jpg "], 
    price: 195
},
{
    id: "693", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195 грн(1501).jpg ","/images/Липучки SEN-D D100 195 грн(1500).jpg "], 
    price: 195 
},
{
    id: "694", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195 грн(3000).jpg ","/images/Липучки SEN-D D100 195 грн(3001).jpg "], 
    price: 195 
},
{
    id: "695", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн (500).jpg ","/images/Липучки SEN-D D100 195грн (501).jpg "], 
    price: 195 
},
{
    id: "696", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн (1200).jpg ","/images/Липучки SEN-D D100 195грн (1201).jpg "], 
    price: 195 
},
{
    id: "697", 
    name: "Липучки SEN-D №1", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн №1 (1).jpg ","/images/Липучки SEN-D D100 195грн №1 (2).jpg "], 
    price: 380
},
{
    id: "698", 
    name: "Липучки SEN-D №2+", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн №2+ (1).jpg ","/images/Липучки SEN-D D100 195грн №2+ (2).jpg "], 
    price: 380
},
{
    id: "699", 
    name: "Липучки SEN-D №3", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн №3 (1).jpg ","/images/Липучки SEN-D D100 195грн №3 (2).jpg "], 
    price: 380 
},
{
    id: "700", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(30).jpg ","/images/Липучки SEN-D D100 195грн(31).jpg "], 
    price: 195 
},
{
    id: "701", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(50).jpg ","/images/Липучки SEN-D D100 195грн(51).jpg "], 
    price: 195 
},
{
    id: "702", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(100).jpg ","/images/Липучки SEN-D D100 195грн(101).jpg "], 
    price: 195 
},
{
    id: "703", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(300).jpg ","/images/Липучки SEN-D D100 195грн(301).jpg "], 
    price: 195 
},
{
    id: "704", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(400).jpg ","/images/Липучки SEN-D D100 195грн(401).jpg "], 
    price: 195 
},
{
    id: "705", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(600).jpg ","/images/Липучки SEN-D D100 195грн(601).jpg "], 
    price: 195 
},
{
    id: "706", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(800).jpg ","/images/Липучки SEN-D D100 195грн(801).jpg "], 
    price: 195 
},
{
    id: "707", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(1000).jpg ","/images/Липучки SEN-D D100 195грн(1001).jpg "], 
    price: 195 
},
{
    id: "708", 
    name: "Липучки SEN-D", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн(2000).jpg ","/images/Липучки SEN-D D100 195грн(2001).jpg "], 
    price: 195 
},
{
    id: "709", 
    name: "Липучки SEN-D №2", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки SEN-D D100 195грн№2 (1).jpg ","/images/Липучки SEN-D D100 195грн№2 (2).jpg "], 
    price: 380
},
{
    id: "710", 
    name: "Липучки StoneCraft сота на суху  50 ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft сота на суху D100  50 250грн (1).jpg ","/images/Липучки StoneCraft сота на суху D100  50 250грн (2).jpg "], 
    price: 250 
},
{
    id: "711", 
    name: "Липучки StoneCraft 30  ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 30 130грн (1).jpg ","/images/Липучки StoneCraft D80 30 130грн (2).jpg "], 
    price: 160
},
{
    id: "712", 
    name: "Липучки StoneCraft 100  ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 100 130грн (1).jpg ","/images/Липучки StoneCraft D80 100 130грн (2).jpg "], 
    price: 160 
},
{
    id: "713", 
    name: "Липучки StoneCraft 200  ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 200 130грн (1).jpg ","/images/Липучки StoneCraft D80 200 130грн (2).jpg "], 
    price: 130 
},
{
    id: "714", 
    name: "Липучки StoneCraft 400  ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 400 130грн (1).jpg ","/images/Липучки StoneCraft D80 400 130грн (2).jpg "], 
    price: 160 
},
{
    id: "715", 
    name: "Липучки StoneCraft 500  ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 500 130грн (1).jpg ","/images/Липучки StoneCraft D80 500 130грн (2).jpg "], 
    price: 160 
},
{
    id: "716", 
    name: "Липучки StoneCraft 600 ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 600 130грн (1).jpg ","/images/Липучки StoneCraft D80 600 130грн (2).jpg "], 
    price: 160 
},
{
    id: "717", 
    name: "Липучки StoneCraft 800 ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 800 130грн (1).jpg ","/images/Липучки StoneCraft D80 800 130грн (2).jpg "], 
    price: 160 
},
{
    id: "718", 
    name: "Липучки StoneCraft 1000 ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 1000 130грн (1).jpg ","/images/Липучки StoneCraft D80 1000 130грн (2).jpg "], 
    price: 160 
},
{
    id: "719", 
    name: "Липучки StoneCraft 1500 ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 1500 130грн (1).jpg ","/images/Липучки StoneCraft D80 1500 130грн (2).jpg "], 
    price: 160 
},
{
    id: "720", 
    name: "Липучки StoneCraft 2000 ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 2000 130грн (1).jpg ","/images/Липучки StoneCraft D80 2000 130грн (2).jpg "], 
    price: 160 
},
{
    id: "721", 
    name: "Липучки StoneCraft 3000  ", 
    sizes: "D80 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D80 3000 130грн (1).jpg ","/images/Липучки StoneCraft D80 3000 130грн (2).jpg "], 
    price: 160 
},
{
    id: "722", 
    name: "Липучки StoneCraft 150  мрамур  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 150  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 150  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "723", 
    name: "Липучки StoneCraft 300  мрамур ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 300  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 300  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "724", 
    name: "Липучки StoneCraft 500  мрамур ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 500  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 500  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "725", 
    name: "Липучки StoneCraft 1000  мрамур  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 1000  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 1000  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "726", 
    name: "Липучки StoneCraft 2000  мрамур  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 2000  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 2000  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "727", 
    name: "Липучки StoneCraft 3000  мрамур  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 3000  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 3000  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "728", 
    name: "Липучки StoneCraft 6000  мрамур  ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 6000  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 6000  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "729", 
    name: "Липучки StoneCraft 10000  мрамур ", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 10000  мрамур 85грн (1).jpg ","/images/Липучки StoneCraft D100 10000  мрамур 85грн (2).jpg "], 
    price: 85 
},
{
    id: "730", 
    name: "Липучки StoneCraft A 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 50 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 50 155грн (2).jpg "], 
    price: 155 
},
{
    id: "731", 
    name: "Липучки StoneCraft A 100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 100.jpg"],
    price: 160
},
{
    id: "732", 
    name: "Липучки StoneCraft A 150", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 150 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 150 155грн (2).jpg "], 
    price: 160
},
{
    id: "733", 
    name: "Липучки StoneCraft A 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 200 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 200 155грн (2).jpg "], 
    price: 160
},
{
    id: "734", 
    name: "Липучки StoneCraft A 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 300 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 300 155грн (2).jpg "], 
    price: 160 
},
{
    id: "735", 
    name: "Липучки StoneCraft A 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 400 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 400 155грн (2).jpg "], 
    price: 160 
},
{
    id: "736", 
    name: "Липучки StoneCraft A 500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 500 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 500 155грн (2).jpg "], 
    price: 160 
},
{
    id: "737", 
    name: "Липучки StoneCraft A 800", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 800 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 800 155грн (2).jpg "], 
    price: 160 
},
{
    id: "738", 
    name: "Липучки StoneCraft A 800", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 800 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 800 155грн (2).jpg "], 
    price: 160 
},
{
    id: "739", 
    name: "Липучки StoneCraft A 1000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 1000 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 1000 155грн (2).jpg "], 
    price: 160 
},
{
    id: "740", 
    name: "Липучки StoneCraft A 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 1500 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 1500 155грн (2).jpg "], 
    price: 160 
},
{
    id: "741", 
    name: "Липучки StoneCraft A 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 2000 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 2000 155грн (2).jpg "], 
    price: 160 
},
{
    id: "742", 
    name: "Липучки StoneCraft A 3000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 A 3000 155грн (1).jpg ","/images/Липучки StoneCraft D100 A 3000 155грн (2).jpg "], 
    price: 160 
},
{
    id: "743", 
    name: "Липучки StoneCraft AA 5кроків №1", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 5кроків №1 265грн (1).jpg ","/images/Липучки StoneCraft D100 AA 5кроків №1 265грн (2).jpg "], 
    price: 265 
},
{
    id: "744", 
    name: "Липучки StoneCraft AA 5кроків №2", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 5кроків №2 265грн (1).jpg ","/images/Липучки StoneCraft D100 AA 5кроків №2 265грн (2).jpg "], 
    price: 265 
},
{
    id: "745", 
    name: "Липучки StoneCraft AA 5кроків №4", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 5кроків №4 265грн (1).jpg ","/images/Липучки StoneCraft D100 AA 5кроків №4 265грн (2).jpg "],
    price: 265 
},
{
    id: "746", 
    name: "Липучки StoneCraft AA 5кроків №5", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 5кроків №5 265грн  (1).jpg ","/images/Липучки StoneCraft D100 AA 5кроків №5 265грн  (2).jpg "], 
    price: 265 
},
{
    id: "747", 
    name: "Липучки StoneCraft AA 30", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 30 225грн (1).jpg ","/images/Липучки StoneCraft D100 AA 30 225грн (2).jpg "], 
    price: 225 
},
{
    id: "748", 
    name: "Липучки StoneCraft AA 100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 100 225грн (1).jpg ","/images/Липучки StoneCraft D100 AA 100 225грн (2).jpg "], 
    price: 225 
},
{
    id: "749", 
    name: "Липучки StoneCraft AA 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 200 225грн (1).jpg ","/images/Липучки StoneCraft D100 AA 200 225грн (2).jpg "], 
    price: 225 
},
{
    id: "750", 
    name: "Липучки StoneCraft AA 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 300 225грн (1).jpg ","/images/Липучки StoneCraft D100 AA 300 225грн (2).jpg "], 
    price: 225 
},
{
    id: "751", 
    name: "Липучки StoneCraft AA 400", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 400 225грн (1).jpg ","/images/Липучки StoneCraft D100 AA 400 225грн (2).jpg "], 
    price: 225 
},
{
    id: "752", 
    name: "Липучки StoneCraft AA 800", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 800 225грн  (1).jpg ","/images/Липучки StoneCraft D100 AA 800 225грн  (2).jpg "], 
    price: 225 
},
{
    id: "753", 
    name: "Липучки StoneCraft AA 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 1500 225грн  (1).jpg ","/images/Липучки StoneCraft D100 AA 1500 225грн  (2).jpg "], 
    price: 225 
},
{
    id: "754", 
    name: "Липучки StoneCraft AA 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 AA 2000 225грн  (1).jpg ","/images/Липучки StoneCraft D100 AA 2000 225грн  (2).jpg "], 
    price: 225 
},
{
    id: "755", 
    name: "Липучки StoneCraft B 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 50 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 50 130грн  (2).jpg "], 
    price: 140
},
{
    id: "756", 
    name: "Липучки StoneCraft B 100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 100 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 100 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "757", 
    name: "Липучки StoneCraft B 150", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 150 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 150 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "758", 
    name: "Липучки StoneCraft B 200", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 200 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 200 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "759", 
    name: "Липучки StoneCraft B 300", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 300 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 300 130грн  (2).jpg "],
    price: 140 
},
{
    id: "760", 
    name: "Липучки StoneCraft B 600", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 600 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 600 130грн  (2).jpg "], 
    price: 140
},
{
    id: "761", 
    name: "Липучки StoneCraft B 800", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 800 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 800 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "762", 
    name: "Липучки StoneCraft B 1000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 1000 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 1000 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "763", 
    name: "Липучки StoneCraft B 1500", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 1500 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 1500 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "764", 
    name: "Липучки StoneCraft B 2000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 2000 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 2000 130грн  (2).jpg "], 
    price: 130 
},
{
    id: "765", 
    name: "Липучки StoneCraft B 3000", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 B 3000 130грн  (1).jpg ","/images/Липучки StoneCraft D100 B 3000 130грн  (2).jpg "], 
    price: 140 
},
{
    id: "766", 
    name: "Липучки StoneCraft V 50", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 V 50  230грн (1).jpg ","/images/Липучки StoneCraft D100 B 3000 130грн  (2).jpg "], 
    price: 230 
},
{
    id: "767", 
    name: "Липучки StoneCraft V 100", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 V 100  230грн (1).jpg ","/images/Липучки StoneCraft D100 V 100  230грн (2).jpg "], 
    price: 230 
},
{
    id: "768", 
    name: "Липучки StoneCraft V 100(біла)", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 V 100(біла) 230грн (1).jpg ","/images/Липучки StoneCraft D100 V 100(біла) 230грн (2).jpg "], 
    price: 230 
},
{
    id: "769", 
    name: "Липучки StoneCraft V 200 (біла)", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 V 200 (біла) 230грн (1).jpg ","/images/Липучки StoneCraft D100 V 200 (біла) 230грн (2).jpg "], 
    price: 230 
},
{
    id: "770", 
    name: "Липучки StoneCraft V 400(біла)", 
    sizes: "D100 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D100 V 400(біла) 230грн (1).jpg ","/images/Липучки StoneCraft D100 V 400(біла) 230грн (2).jpg "], 
    price: 230 
},
{
    id: "771", 
    name: "Липучки StoneCraft 100", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 100 280грн (1).jpg ","/images/Липучки StoneCraft D125 100 280грн (2).jpg "], 
    price: 280 
},
{
    id: "772", 
    name: "Липучки StoneCraft 200", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 200 280грн  (1).jpg ","/images/Липучки StoneCraft D125 200 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "773", 
    name: "Липучки StoneCraft 300   мрамур", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 300   мрамур 150грн. (1).jpg ","/images/Липучки StoneCraft D125 3Шурік Смаран, [16.10.2023 22:30]00   мрамур 150грн. (2).jpg "], 
    price: 150
},
{
    id: "774", 
    name: "Липучки StoneCraft 300", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 300 280грн  (1).jpg ","/images/Липучки StoneCraft D125 300 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "775", 
    name: "Липучки StoneCraft 400", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 400 280грн  (1).jpg ","/images/Липучки StoneCraft D125 400 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "776", 
    name: "Липучки StoneCraft 500   мрамур", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 500   мрамур 150грн (1).jpg ","/images/Липучки StoneCraft D125 500   мрамур 150грн (2).jpg "], 
    price: 150 
},
{
    id: "777", 
    name: "Липучки StoneCraft 500", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 500 280грн  (1).jpg ","/images/Липучки StoneCraft D125 500 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "778", 
    name: "Липучки StoneCraft 1000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 1000 280грн  (1).jpg ","/images/Липучки StoneCraft D125 1000 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "779", 
    name: "Липучки StoneCraft 1500", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 1500 280грн  (1).jpg ","/images/Липучки StoneCraft D125 1500 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "780", 
    name: "Липучки StoneCraft 2000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 2000 280грн  (1).jpg ","/images/Липучки StoneCraft D125 2000 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "781", 
    name: "Липучки StoneCraft 3000   мрамур", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 3000   мрамур 150грн. (1).jpg ","/images/Липучки StoneCraft D125 3000   мрамур 150грн. (2).jpg "], 
    price: 150 
},
{
    id: "782", 
    name: "Липучки StoneCraft 3000", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 3000 280грн  (1).jpg ","/images/Липучки StoneCraft D125 3000 280грн  (2).jpg "], 
    price: 280 
},
{
    id: "783", 
    name: "Липучки StoneCraft 6000   мрамур", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 6000   мрамур 150грн. (1).jpg ","/images/Липучки StoneCraft D125 6000   мрамур 150грн. (2).jpg "], 
    price: 150 
},
{
    id: "784", 
    name: "Липучки StoneCraft 10000   мрамур", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 10000   мрамур 150грн. (1).jpg ","/images/Липучки StoneCraft D125 10000   мрамур 150грн. (2).jpg "], 
    price: 150 
},
{
    id: "785", 
    name: "Липучки StoneCraft V  біла 50", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 V  біла 50 325грн (1).jpg ","/images/Липучки StoneCraft D125 V  біла 50 325грн (2).jpg "], 
    price: 325
},
{
    id: "786", 
    name: "Липучки StoneCraft V  біла 100", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 V  біла 100 325грн (1).jpg ","/images/Липучки StoneCraft D125 V  біла 100 325грн (2).jpg "], 
    price: 325 
},
{
    id: "787", 
    name: "Липучки StoneCraft V  біла 200", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 V  біла 200 325грн (1).jpg ","/images/Липучки StoneCraft D125 V  біла 200 325грн (2).jpg "], 
    price: 325 
},
{
    id: "788", 
    name: "Липучки StoneCraft V  біла 400", 
    sizes: "D125 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D125 V  біла 400 325грн (1).jpg ","/images/Липучки StoneCraft D125 V  біла 400 325грн (2).jpg "], 
    price: 325 
},
{
    id: "789", 
    name: "Липучки StoneCraft  50", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 50  450грн.  (1).jpg ","/images/Липучки StoneCraft D150 50  450грн.  (2).jpg "], 
    price: 450 
},
{
    id: "790", 
    name: "Липучки StoneCraft 100", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 100  450грн.м (1).jpg ","/images/Липучки StoneCraft D150 100  450грн.м (2).jpg "], 
    price: 450 
},
{
    id: "791", 
    name: "Липучки StoneCraft 200", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 200  450грн. (1).jpg ","/images/Липучки StoneCraft D150 200  450грн. (2).jpg "], 
    price: 450 
},
{
    id: "792", 
    name: "Липучки StoneCraft 400", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 400  450грн. (1).jpg ","/images/Липучки StoneCraft D150 400  450грн. (2).jpg "], 
    price: 450 
},
{
    id: "793", 
    name: "Липучки StoneCraft 600 ", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 600  450грн.  (1).jpg ","/images/Липучки StoneCraft D150 600  450грн.  (2).jpg "], 
    price: 450 
},
{
    id: "794", 
    name: "Липучки StoneCraft 800 ", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 800  450грн. (1).jpg ","/images/Липучки StoneCraft D150 800  450грн. (2).jpg "], 
    price: 450 
},
{
    id: "795", 
    name: "Липучки StoneCraft 3000", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 3000  450грн. (1).jpg ","/images/Липучки StoneCraft D150 3000  450грн. (2).jpg "], 
    price: 450 
},
{
    id: "796", 
    name: "Липучки StoneCraft 3000", 
    sizes: "D150 ", 
    category: PolishingProducts, 
    subCategory: Velcro, 
    urlImg: ["/images/Липучки StoneCraft D150 3000  450грн. (1).jpg ","/images/Липучки StoneCraft D150 3000  450грн. (2).jpg "], 
    price: 450 
},
{
    id: "797",
    name: "Стрейч-плівка чорна",
    sizes: " ",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: "/images/Стрейч-плівка чорна 270грн.jpg",
    price: 270
},
{
    id: "798",
    name: "Стрейч-плівка вторсировина",
    sizes: " ",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: "/images/Стрейч-плівка вторсировина 220грн.jpg",
    price: 220
},
{
    id: "800",
    name: "Стрейч-плівка",
    sizes: "3 кг",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: "/images/Стрейч-плівка 2,3 290грн.jpg",
    price: 300
},
{
    id: "801",
    name: "Скоба для пакування №19 (оцинкована) 100шт",
    sizes: " ",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: "/images/Скоба для пакування №19 100шт 120грн.jpg",
    price: 150
},
{
    id: "802",
    name: "Машинка для пакування синя",
    sizes: " ",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: ["/images/Машинка для пакування синя 1300грн (2).jpg","/images/Машинка для пакування синя 1300грн (1).jpg"],
    price: 1400
},
{
    id: "803",
    name: "Стрічка №19 чорна",
    sizes: "1000м",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: "/images/Лєнта №19 чорна 1000м 1100грн.jpg",
    price: 1100
},
{
    id: "804",
    name: "Стрічка пакувальна №19 біла",
    sizes: "1000м",
    category: Others,
    subCategory: PackagingMaterials,
    urlImg: "/images/Лєнта пакувальна №19 1000м біла 1100грн.jpg",
    price: 1100
},
{
    id: "805",
    name: "Рукавиці InterTool сині SP-0111",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці InterTool сині SP-0111 58Грн (1).jpg", "/images/Рукавиці InterTool сині SP-0111 58Грн (2).jpg"],
    price: 58
},
{
    id: "806",
    name: "Рукавиці INTERTOOL сині з вузьким манжетом",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці INTERTOOL сині з вузьким манжетом 70грн (1).jpg","/images/Рукавиці INTERTOOL сині з вузьким манжетом 70грн (2).jpg"],
    price: 70
},
{
    id: "807",
    name: "Рукавиці DOLONI універсальні хвиля",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI універсальні хвиля 22грн (1).jpg","/images/Рукавиці DOLONI універсальні хвиля 22грн (2).jpg"],
    price: 25
},
{
    id: "808",
    name: "Рукавиці DOLONI ЗІРКА чорна",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI ЗІРКА чорна (562 4080) 33грн (1).jpg","/images/Рукавиці DOLONI ЗІРКА чорна (562 4080) 33грн (2).jpg"],
    price: 33
},
{
    id: "809",
    name: "Рукавиці DOLONI ЗІРКА червона",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI ЗІРКА червона 4040 35грн (1).jpg","/images/Рукавиці DOLONI ЗІРКА червона 4040 35грн (2).jpg"],
    price: 35
},
{
    id: "810",
    name: "Рукавиці DOLONI",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI 4564 55грн (1).jpg","/images/Рукавиці DOLONI 4564 55грн (2).jpg"],
    price: 55
},
{
    id: "811",
    name: "Рукавиці DOLONI" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI 4502 53грн (1).jpg","/images/Рукавиці DOLONI 4502 53грн (2).jpg"],
    price: 53
},
{
    id: "812",
    name: "Рукавиці DOLONI" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI 4082 45грн (1).jpg","/images/Рукавиці DOLONI 4082 45грн (2).jpg"],
    price: 45
},
{
    id: "813",
    name: "Рукавиці DOLONI універсальні помаранчеві" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONI 526 універсальні помаранчеві 25грн (1).jpg","/images/Рукавиці DOLONI 526 універсальні помаранчеві 25грн (2).jpg"],
    price: 25
},
{
    id: "814",
    name: "Рукавиці DOLONI",
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці DOLONE 4187 43грн (1).jpg","/images/Рукавиці DOLONE 4187 43грн (2).jpg"],
    price: 43
},
{
    id: "815",
    name: "Рукавиці Стандарт помаранчеві" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці Стандарт помаранчеві 794 30грн (1).jpg","/images/Рукавиці Стандарт помаранчеві 794 30грн (1).jpg"],
    price: 30
},
{
    id: "816",
    name: "Рукавиці піна ЛЧ" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: "/images/Рукавиці піна 300 ЛЧ 55грн.jpg",
    price: 55
},
{
    id: "817",
    name: "Рукавиці Долоні Стандарт" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: ["/images/Рукавиці Долоні Стандарт Арт548 20грн (1).jpg","/images/Рукавиці Долоні Стандарт Арт548 20грн (2).jpg"],
    price: 20
},
{
    id: "818",
    name: "Рукавиці Долоні" ,
    sizes: " ",
    category: PersonalEquipment,
    subCategory: Gloves,
    urlImg: "/images/Рукавиці Долоні 4581 62грн.jpg",
    price: 62
},
{
    id: "819",
    name: "Струбцина VOREL",
    sizes: "D300x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Струбцина VOREL 300x120 520грн.jpg",
    price: 520
},
{
    id: "820",
    name: "Струбцина MEGA",
    sizes: "D800x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Струбцина MEGA 800x120 550грн.jpg",
    price: 550
},
{
    id: "821",
    name: "Струбцина VOREL",
    sizes: "D1000x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Струбцина 1000х120 VOREL 850грн.jpg",
    price: 850
},
{
    id: "822",
    name: "Струбцина SIGMA",
    sizes: "D500x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Струбцина 500х120 SIGMA 650грн.jpg",
    price: 650
},
{
    id: "823",
    name: "Струбцина Intertool",
    sizes: "D500x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Струбцина 500х120 Intertool 330грн.jpg",
    price: 330
},
{
    id: "824",
    name: "Струбцина SIGMA",
    sizes: "D300x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Струбцина 300х120 SIGMA 450грн.jpg",
    price: 450
},
{
    id: "825",
    name: "Срубцина VORTEL",
    sizes: "D500x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Срубцина VORTEL 500x120 650грн.jpg",
    price: 650
},
{
    id: "826",
    name: "Сверло YATO х-типу",
    sizes: "D6x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло YATO D6x110 х-типу 50грн.jpg",
    price: 50
},
{
    id: "827",
    name: "Сверло VECTRON",
    sizes: "12х210мм",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло VECTRON 12x210мм 60грн.jpg",
    price: 60
},
{
    id: "828",
    name: "Сверло VECTRON",
    sizes: "8х210мм",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло VECTRON 8x210мм 50грн.jpg",
    price: 50
},
{
    id: "829",
    name: "Сверло VECTRON",
    sizes: "6х210мм",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло VECTRON 6x210мм 45грн.jpg",
    price: 45
},
{
    id: "830",
    name: "Сверло VECTRON",
    sizes: "6х140мм",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло VECTRON 6x160мм 40грн.jpg",
    price: 40
},
{
    id: "831",
    name: "Сверло VECTRON",
    sizes: "6х160мм",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло VECTRON 5x110мм 30грн.jpg",
    price: 30
},
{
    id: "832",
    name: "Сверло USH TWISTER",
    sizes: "D18x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло USH TWISTER D18x210 400грн.jpg",
    price: 400
},
{
    id: "833",
    name: "Сверло USH TWISTER",
    sizes: "D4x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло USH TWISTER D4x110 80грн.jpg",
    price: 80
},
{
    id: "834",
    name: "Сверло TOMAX",
    sizes: "D20 150x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAX D20 150x210 180грн.jpg",
    price: 180
},
{
    id: "835",
    name: "Сверло TOMAX",
    sizes: "D17 150x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAX D17 150x210 140грн.jpg",
    price: 140
},
{
    id: "836",
    name: "Сверло TOMAX",
    sizes: "D12 150x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAX D12 150x210 80грн.jpg",
    price: 80
},
{
    id: "837",
    name: "Сверло TOMAX",
    sizes: "D8 100x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAX D8 100x160 65грн.jpg",
    price: 65
},
{
    id: "838",
    name: "Сверло TOMAX",
    sizes: "D8 50x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAX D8 50x110 60грн.jpg",
    price: 60
},
{
    id: "839",
    name: "Сверло TOMAX",
    sizes: "D5 100x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAX D5 100x160 30грн.jpg",
    price: 30
},
{
    id: "840",
    name: "Сверло TOMAAX",
    sizes: "D10 150x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло TOMAAX D10 150x210 75грн.jpg",
    price: 75
},
{
    id: "841",
    name: "Сверло Rapide",
    sizes: "D16x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Rapide D16x210 80грн.jpg",
    price: 80
},
{
    id: "842",
    name: "Сверло Rapide",
    sizes: "D14x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Rapide D14x160 60грн.jpg",
    price: 60
},
{
    id: "843",
    name: "Сверло Rapide",
    sizes: "D12x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Rapide D12x160 55грн.jpg",
    price: 55
},
{
    id: "844",
    name: "Сверло Rapide",
    sizes: "D10x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Rapide D10x210 50грн.jpg",
    price: 50
},
{
    id: "845",
    name: "Сверло Rapide",
    sizes: "D6x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Rapide D6x110 30грн.jpg",
    price: 30
},
{
    id: "846",
    name: "Сверло PROJAHN",
    sizes: "D14x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло PROJAHN D14x160 45грн.jpg",
    price: 45
},
{
    id: "847",
    name: "Сверло Profi Line",
    sizes: "D8x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Profi Line D8x110 20грн.jpg",
    price: 20
},
{
    id: "848",
    name: "Сверло MTX",
    sizes: "D14x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло MTX D14x160 55грн.jpg",
    price: 55
},
{
    id: "849",
    name: "Сверло MODECO",
    sizes: "D16 250x300",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло MODECO D16 250x300 50грн.jpg",
    price: 50
},
{
    id: "850",
    name: "Сверло METABO",
    sizes: "D10 100x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло METABO D10 100x160 50грн.jpg",
    price: 50
},
{
    id: "851",
    name: "Сверло METABO",
    sizes: "D8 100x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло METABO D8 100х160 45грн.jpg",
    price: 45
},
{
    id: "852",
    name: "Сверло METABO",
    sizes: "D6 50x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло METABO D6 50x110 35Грн.jpg",
    price: 35
},
{
    id: "853",
    name: "Сверло Matrix",
    sizes: "D12x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Matrix D12x160 50грн.jpg",
    price: 50
},
{
    id: "854",
    name: "Сверло Matrix в дрель",
    sizes: "D5x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Matrix D5x150 в дрель 15 грн.jpg",
    price: 15
},
{
    id: "855",
    name: "Сверло Matrix",
    sizes: "D5x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Matrix D5x110 20грн.jpg",
    price: 20
},
{
    id: "856",
    name: "Сверло Makita SDS-PLUS",
    sizes: "18x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita SDS-PLUS 18x210 230грн.jpg",
    price: 230
},
{
    id: "857",
    name: "Сверло Makita SDS-PLUS",
    sizes: "16x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita SDS-PLUS 16x210 175грн.jpg",
    price: 175
},
{
    id: "858",
    name: "Сверло Makita SDS-PLUS",
    sizes: "14x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita SDS-PLUS 14x160 115грн.jpg",
    price: 115
},
{
    id: "859",
    name: "Сверло Makita SDS-PLUS",
    sizes: "12x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita SDS-PLUS 12x160 90грн.jpg",
    price: 90
},
{
    id: "860",
    name: "Сверло Makita SDS-PLUS",
    sizes: "8x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita SDS-PLUS 8x160 70грн.jpg",
    price: 70
},
{
    id: "861",
    name: "Сверло Makita SDS-PLUS",
    sizes: "6x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita SDS-PLUS 6x110 70грн.jpg",
    price: 70
},
{
    id: "862",
    name: "Сверло Makita",
    sizes: "D44x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Makita D4x110 70грн.jpg",
    price: 70
},
{
    id: "863",
    name: "Сверло IRWIN",
    sizes: "D20 150x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло IRWIN D20 150x210 260грн.jpg",
    price: 260
},
{
    id: "864",
    name: "Сверло INTERTOOL",
    sizes: "D4x150",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло INTERTOOL D14x150 45грн.jpg",
    price: 45
},
{
    id: "865",
    name: "Сверло HITACHI",
    sizes: "D8 150x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло HITACHI D8 150x210 50грн.jpg",
    price: 50
},
{
    id: "866",
    name: "Сверло HAISSER",
    sizes: "D5 50x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло HAISSER D5 50x110 23грн.jpg",
    price: 23
},
{
    id: "867",
    name: "Сверло Falc",
    sizes: "D22x460",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D22x460 230грн.jpg",
    price: 230
},
{
    id: "868",
    name: "Сверло Falc",
    sizes: "D14x260",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D14x260 75грн.jpg",
    price: 75
},
{
    id: "869",
    name: "Сверло Falc в дрель",
    sizes: "D12x120",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D12x120 дрель 25грн.jpg",
    price: 25
},
{
    id: "870",
    name: "Сверло Falc",
    sizes: "D10x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D10x210 55грн.jpg",
    price: 55
},
{
    id: "871",
    name: "Сверло Falc",
    sizes: "D10x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D10x160 50грн.jpg",
    price: 50
},
{
    id: "872",
    name: "Сверло Falc",
    sizes: "D8x460",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D8x460 70грн.jpg",
    price: 70
},
{
    id: "873",
    name: "Сверло Falc",
    sizes: "D8x310",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D8x310 32грн.jpg",
    price: 32
},
{
    id: "874",
    name: "Сверло Falc",
    sizes: "D8x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D8x210 45Грн.jpg",
    price: 45
},
{
    id: "875",
    name: "Сверло Falc в чохлі",
    sizes: "D8x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D8x110 в чохлі 20грн.jpg",
    price: 20
},
{
    id: "876",
    name: "Сверло Falc",
    sizes: "D6x110",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D6x110 35грн.jpg",
    price: 35
},
{
    id: "877",
    name: "Сверло Falc в чохлі",
    sizes: "D5x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D5x160 в чохлі 20грн.jpg",
    price: 20
},
{
    id: "878",
    name: "Сверло Falс",
    sizes: "D5x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D5x160 20грн.jpg",
    price: 20
},
{
    id: "879",
    name: "Сверло Falc Дрель",
    sizes: "D5x85",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло Falc D5x85 Дрель 10грн.jpg",
    price: 10
},
{
    id: "880",
    name: "Сверло по бетону QUADRO",
    sizes: "16x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло по бетону QUADRO 16x210 110грн.jpg",
    price: 110
},
{
    id: "881",
    name: "Сверло по бетону QUADRO",
    sizes: "14x210",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло по бетону QUADRO 14x210 80грн.jpg",
    price: 80
},
{
    id: "882",
    name: "Сверло по бетону QUADRO",
    sizes: "14x160",
    category: Tools,
    subCategory: Drills,
    urlImg: "/images/Сверло по бетону QUADRO 14x160 75грн.jpg",
    price: 75
},
{
    id: "883",
    name: "Коронка КНШ чорна",
    sizes: "D43x25",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D43x25 КНШ чорна 450грн (1).jpg","/images/Коронка D43x25 КНШ чорна 450грн (2).jpg"],
    price: 450
},
{
    id: "884",
    name: "Коронка КНШ біла",
    sizes: "D43x25",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D43x25 КНШ біла 350грн (1).jpg","/images/Коронка D43x25 КНШ біла 350грн (2).jpg"],
    price: 350
},
{
    id: "885",
    name: "Коронка червона Китай",
    sizes: "D40x22",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D40x22 червона Китай 350грн (1).jpg","/images/Коронка D40x22 червона Китай 350грн (2).jpg"],
    price: 350
},
{
    id: "886",
    name: "Коронка крестообразна помаранчева",
    sizes: "D40x22",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D40x22 крестообразна помаранчева 200грн (1).jpg","/images/Коронка D40x22 крестообразна помаранчева 200грн (2).jpg"],
    price: 200
},
{
    id: "887",
    name: "Коронка КДП долотчата",
    sizes: "D40",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D40 КДП долотчата 300грн (1).jpg","/images/Коронка D40 КДП долотчата 300грн (2).jpg"],
    price: 300
},
{
    id: "888",
    name: "Коронка КНШ кольорова китай",
    sizes: "D36x22",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D36x22 КНШ кольорова китай 350грн (1).jpg","/images/Коронка D36x22 КНШ кольорова китай 350грн (2).jpg"],
    price: 350
},
{
    id: "889",
    name: "Коронка долотчата корона золота",
    sizes: "D36x22",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D36x22 долотчата корона золота 160грн (1).jpg","/images/Коронка D36x22 долотчата корона золота 160грн (2).jpg"],
    price: 160
},
{
    id: "890",
    name: "Коронка долотчата",
    sizes: "D36x22",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка D36x22 долотчата 160грн (1).jpg","/images/Коронка D36x22 долотчата 160грн (2).jpg"],
    price: 160
},
{
    id: "891",
    name: "Коронка черовна долодчата",
    sizes: "D40x22",
    category: Tools,
    subCategory: Drills,
    urlImg: ["/images/Коронка 40х22 черовна долодчата 250грн  (1).jpg","/images/Коронка 40х22 черовна долодчата 250грн  (2).jpg"],
    price: 250
},
{
    id: "892",
    name: "Фарба типографна",
    sizes: "500мл",
    category: Perm,
    subCategory: Paint,
    urlImg: "/images/Фарба типографна 0,5л 300грн.jpg",
    price: 300
},
{
    id: "893",
    name: "Емаль універсальна фарба-спрей(червона)",
    sizes: " ",
    category: Perm,
    subCategory: Paint,
    urlImg: "/images/Емаль універсальна фарба-спрей(червона) 120грн.jpg",
    price: 120
},
{
    id: "894",
    name: "Емаль універсальна фарба-спрей(синя)",
    sizes: " ",
    category: Perm,
    subCategory: Paint,
    urlImg: "/images/Емаль універсальна фарба-спрей(синя) 120грн.jpg",
    price: 120
},
{
    id: "895",
    name: "Емаль універсальна фарба-спрей(жовта)",
    sizes: " ",
    category: Perm,
    subCategory: Paint,
    urlImg: "/images/Емаль універсальна фарба-спрей(жовта) 120грн.jpg",
    price: 120
},
{
    id: "896",
    name: "Емаль універсальна фарба-спрей(біла)",
    sizes: " ",
    category: Perm,
    subCategory: Paint,
    urlImg: "/images/Емаль універсальна фарба-спрей(біла) 120грн.jpg",
    price: 120
},
{
    id: "897",
    name: "Емаль універсальна фарба-спрей (зелена)",
    sizes: " ",
    category: Perm,
    subCategory: Paint,
    urlImg: "/images/Емаль універсальна фарба-спрей (зелена) 120грн.jpg",
    price: 120
},
{
    id: "900",
    name: "Хрести-накладка (кольорові)",
    sizes: "40х20х3",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрести-накладка (кольорові) 40х20х3 360грн.jpg",
    price: 360
},
{
    id: "901",
    name: "Хрест ток",
    sizes: "25х18х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест ток 25х18х5 400грн.jpg",
    price: 400
},
{
    id: "902",
    name: "Хрест покостовка фаска",
    sizes: "30х20х2",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест покостовка 30х20х2 фаска 720грн.jpg",
    price: 720
},
{
    id: "903",
    name: "Хрест покостівка",
    sizes: "30х40х3",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест покостівка 30х40х3 450грн.jpg",
    price: 450
},
{
    id: "904",
    name: "Хрест покостівка",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест покостівка 30х20х5 560грн.jpg",
    price: 560
},
{
    id: "905",
    name: "Хрест покостівка",
    sizes: "30х20х3",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест покостівка 30х20х3 310грн.jpg",
    price: 310
},
{
    id: "906",
    name: "Хрест мрамур",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест мрамур 30х20х5 1200грн.jpg",
    price: 1200
},
{
    id: "907",
    name: "Хрест Межирічка",
    sizes: "35х20",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест Межирічка 35х20 780грн.jpg",
    price: 780
},
{
    id: "908",
    name: "Хрест маславка",
    sizes: "40х25х8",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест маславка 40х25х8 600грн.jpg",
    price: 600
},
{
    id: "910",
    name: "Хрест лізник",
    sizes: "40х25х2",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест лізник 40х25х2 850грн.jpg",
    price: 850
},
{
    id: "911",
    name: "Хрест лізник",
    sizes: "40х25х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест лізник 38х25х5 1550грн.jpg",
    price: 1550
},
{
    id: "912",
    name: "Хрест лізник",
    sizes: "30х20х2",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест лізник 30х20х2 720грн.jpg",
    price: 720
},
{
    id: "913",
    name: "Хрест капуста",
    sizes: "30х20",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест капуста 30х20 2720грн.jpg",
    price: 2720
},
{
    id: "914",
    name: "Хрест габро",
    sizes: "60х27х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 60х27х5 з перетинкой 3500грн.jpg",
    price: 3500
},
{
    id: "915",
    name: "Хрест габро",
    sizes: "60х30х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 60х30х5 2000грн.jpg",
    price: 2000
},
{
    id: "916",
    name: "Хрест габро з перетинкой",
    sizes: "60х27х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 60х27х5 з перетинкой 3500грн.jpg",
    price: 3500
},
{
    id: "917",
    name: "Хрест габро скол",
    sizes: "58х34х6",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 58х34х6 скол 3000грн.jpg",
    price: 3000
},
{
    id: "918",
    name: "Хрест габро",
    sizes: "50х30х8",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 50х30х8 1300грн.jpg",
    price: 1300
},
{
    id: "919",
    name: "Хрест габро з перетинкой",
    sizes: "50х27х6",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 50х27х6 з перетинкой 3000грн.jpg",
    price: 3000
},
{
    id: "920",
    name: "Хрест габро",
    sizes: "50х24х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 50х24х5 1800грн.jpg",
    price: 1800
},
{
    id: "921",
    name: "Хрест габро",
    sizes: "45х25х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 45х25х5 ш.ф 1800грн.jpg",
    price: 1800
},
{
    id: "922",
    name: "Хрест габро широка фаска",
    sizes: "40х25х7",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 40х25х7 шир фаск 1020грн.jpg",
    price: 1020
},
{
    id: "923",
    name: "Хрест габро",
    sizes: "40х25х6",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 40х25х5 600грн.jpg",
    price: 600
},
{
    id: "924",
    name: "Хрест габро",
    sizes: "40х25х4",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 40х25х4 1020грн.jpg",
    price: 1020
},
{
    id: "925",
    name: "Хрест габро",
    sizes: "35х25х3",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 35х25х3 650грн.jpg",
    price: 650
},
{
    id: "926",
    name: "Хрест габро широка фаска",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 30х20х5 широка фаска 960грн.jpg",
    price: 960
},
{
    id: "927",
    name: "Хрест габро пол.фаска",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 30х20х5 пол.фаска 960грн.jpg",
    price: 960
},
{
    id: "928",
    name: "Хрест габро",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро 30х20х5 600грн.jpg",
    price: 780
},
{
    id: "929",
    name: "Хрест василівка",
    sizes: "20х30х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест василівка 20х30х5 500грн.jpg",
    price: 500
},
{
    id: "930",
    name: "Хрест базальт",
    sizes: "30х20х5",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест базальт 30х20х5 300грн.jpg",
    price: 500
},
{
    id: "931",
    name: "Хрест анертезит",
    sizes: "30х20х4",
    category: DecorativeProductsGranite,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест анертезит 30х20х4 550грн.jpg",
    price: 550
},

{ 
    id: "933", 
    name: "Сухоріз Baumesser Stein PRO RF", 
    sizes: "230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз 230 Baumesser Stein PRO RF 750грн.jpg", 
    price: 750
},
{ 
    id: "934", 
    name: "Сухоріз Астра гайка ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Астра D125 гайка 350грн.jpg", 
    price: 350 
},
{ 
    id: "935", 
    name: "Сухоріз зачисний CRAFT червоний ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз зачисний CRAFT D125 червоний 750грн.jpg", 
    price: 750 
},
{ 
    id: "936", 
    name: "Сухоріз Інватех мрамур з флянцем ", 
    sizes: "D65", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D65 мрамур з флянцем 780грн.jpg", 
    price: 780 
},
{ 
    id: "937", 
    name: "Сухоріз Інватех Turbo з флянцем", 
    sizes: "D84", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D84 Turbo з флянцем 670грн.jpg", 
    price: 670 
},
{ 
    id: "938", 
    name: "Сухоріз Інватех МУЛЬТІ з флянцем", 
    sizes: "D85", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D85 МУЛЬТІ з флянцем 810грн.jpg", 
    price: 920 
},
{ 
    id: "939", 
    name: "Сухоріз Інватех Turbo гайка ", 
    sizes: "D96", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D96 Turbo гайка 526грн.jpg", 
    price: 525
},
{ 
    id: "940", 
    name: "Сухоріз Інватех  Turbo з флянцем ", 
    sizes: "D96", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D96 Turbo з флянцем 590грн.jpg", 
    price: 590 
},
{ 
    id: "941", 
    name: "Сухоріз Інватех МУЛЬТІ з флянцем ", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D105 МУЛЬТІ з флянцем 1040 грн.jpg", 
    price: 1040 
},
{ 
    id: "942", 
    name: "Сухоріз Інватех Turbo гайка ", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D115 Turbo гайка 740грн.jpg", 
    price: 740 
},
{ 
    id: "943", 
    name: "Сухоріз Інватех Segment гайка ", 
    sizes: "D119", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D119 Segment гайка 872грн.jpg", 
    price: 872 
},
{ 
    id: "945", 
    name: "Сухоріз Інватех Turbo гайка", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D125 Turbo гайка 810грн.jpg", 
    price: 810 
},
{ 
    id: "946", 
    name: "Сухоріз Інватех Turbo з флянцем ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D125 Turbo з флянцем 930грн.jpg", 
    price: 930 
},
{ 
    id: "947", 
    name: "Сухоріз Інватех Turbo з флянцем ", 
    sizes: "D180", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D180 Turbo з флянцем 1220грн.jpg", 
    price: 1380 
},
{ 
    id: "948", 
    name: "Сухоріз Інватех Turbo гайка ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D230 Turbo гайка 1300грн.jpg", 
    price: 1300 
},
{ 
    id: "949", 
    name: "Сухоріз Інватех Turbo з флянцем ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Інватех D230 Turbo з флянцем 1520грн.jpg", 
    price: 1590 
},
{ 
    id: "950", 
    name: "Сухоріз Китай  гайка ", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Китай D105 гайка 190грн.jpg", 
    price: 190 
},
{ 
    id: "951", 
    name: "Сухоріз Рекс ", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Рекс Д.115 200грн.jpg", 
    price: 200
},
{ 
    id: "952", 
    name: "Сухоріз Сх-Д холодного пресув.сегм ", 
    sizes: "D230-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Сх-Д холодного пресув.сегм D 230-PF 350грн.jpg", 
    price: 350 
},
{ 
    id: "953", 
    name: "Сухоріз Сх-Д Turbo HRU синій ", 
    sizes: "D230-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Сх-Д Turbo HRU синій D230-PF 550грн.jpg", 
    price: 550 
},
{ 
    id: "954", 
    name: "Сухоріз Сх-Д Turbo WAVE HRU срібний ", 
    sizes: "D230-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Сх-Д Turbo WAVE HRU срібний D230-PF 400грн.jpg", 
    price: 400 
},
{ 
    id: "955", 
    name: "Сухоріз Схід діамант червоний сегментований ", 
    sizes: "D125-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Схід діамант червоний сегментований D125-PF 130грн.jpg", 
    price: 130 
},
{ 
    id: "956", 
    name: "Сухоріз Схід Діамант  бірюзовий ", 
    sizes: "D115-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Схід Діамант D115-PF бірюзовий 300грн.jpg", 
    price: 300 
},
{ 
    id: "957", 
    name: "Сухоріз чорний гл різ з фл ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз чорний гл різ D125 з фл 850грн.jpg", 
    price: 850 
},
{ 
    id: "958", 
    name: "Сухоріз Baumesser ", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Baumesser D115 265грн.jpg", 
    price: 265 
},
{ 
    id: "959", 
    name: "Сухоріз Blade з флянцем червоний ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Blade D125 з флянцем червоний 400грн.jpg", 
    price: 400 
},
{ 
    id: "960", 
    name: "Сухоріз CEDIMA гайка сегментований ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Blade D125 з флянцем червоний 400грн.jpg", 
    price: 110 
},
{ 
    id: "961", 
    name: "Сухоріз China D115 гайка зелений  ", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз China D115 гайка зелений 320грн.jpg", 
    price: 320 
},
{ 
    id: "962", 
    name: "Сухоріз COBRA technology гайка Німеччина  ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз COBRA technology D230 гайка Німеччина 1600грн.jpg", 
    price: 1600 
},
{ 
    id: "963", 
    name: "Сухоріз мрамур з флянцем  ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D-230 мрамур з флянцем 1050грн.jpg", 
    price: 1050 
},
{ 
    id: "964", 
    name: "Сухоріз  мрамур PF ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D-230 мрамур PF 950грн.jpg", 
    price: 950 
},
{ 
    id: "965", 
    name: "Сухоріз рожевий тонкий гайка S ", 
    sizes: "D100", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D100 рожевий тонкий гайка S 515 грн.jpg", 
    price: 515 
},
{ 
    id: "966", 
    name: "Сухоріз штраборіз FY ", 
    sizes: "D113", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D113 штраборіз FY 830грн.jpg", 
    price: 830 
},
{ 
    id: "967", 
    name: "Сухоріз Rodex ВП ", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D115 Rodex ВП 130грн.jpg", 
    price: 130 
},
{ 
    id: "968", 
    name: "Сухоріз  Європа ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D125 Європа 180грн.jpg", 
    price: 180 
},
{ 
    id: "969", 
    name: "Сухоріз з флянцем синій мрамур граніт ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D125 з флянцем синій мрамур граніт 950грн.jpg", 
    price: 950 
},
{ 
    id: "970", 
    name: "Сухоріз зачисний чорний (алб) ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D125 зачисний чорний (алб) 800грн.jpg", 
    price: 800 
},
{ 
    id: "971", 
    name: "Сухоріз турбо F чорний(Алб) ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D125 турбо F чорний(Алб) 450грн.jpg", 
    price: 450 
},
{ 
    id: "972", 
    name: "Сухоріз Distar turbo elite duo ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D125 Distar turbo elite duo 700грн.jpg", 
    price: 700 
},
{ 
    id: "973", 
    name: "Сухоріз НЕЗЛАМНИЙ ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз D230 НЕЗЛАМНИЙ 500грн.jpg", 
    price: 500 
},
{ 
    id: "974", 
    name: "Сухоріз Distar ADTnS Turbo з флянцем ", 
    sizes: "D65", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D65 ADTnS Turbo з флянцем 550грн.jpg", 
    price: 550 
},
{ 
    id: "975", 
    name: "Сухоріз Distar ADTnS Turbo з флянцем ", 
    sizes: "D85", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D85 ADTnS Turbo з флянцем 620грн.jpg", 
    price: 620 
},
{ 
    id: "976", 
    name: "Сухоріз Distar незламний ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar d125 незламний 230грн.jpg", 
    price: 230 
},
{ 
    id: "977", 
    name: "Сухоріз Distar Baumesser Stein PRO під фл ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 Baumesser Stein PRO під фл 305грн.jpg", 
    price: 305 
},
{ 
    id: "978", 
    name: "Сухоріз Distar Elite turbo під флянець ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 Elite turbo під флянець 580грн.jpg", 
    price: 580 
},
{ 
    id: "979", 
    name: "Сухоріз Distar Elite Ultra гайка", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 Elite Ultra гайка 605грн.jpg", 
    price: 605 
},
{ 
    id: "980", 
    name: "Сухоріз Distar Elite Ultra гайка", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 Elite Ultra гайка 605грн.jpg", 
    price: 605 
},
{ 
    id: "981", 
    name: "Сухоріз Distar MULTIGRES 7D", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 MULTIGRES 7D 845грн.jpg", 
    price: 845 
},
{ 
    id: "982", 
    name: "Сухоріз Distar Turbo Duplex з фл ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 Turbo Duplex з фл 1230грн.jpg", 
    price: 1230 
},
{ 
    id: "983", 
    name: "Сухоріз Distar Turbo Master Жовтий ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D125 Turbo Master Жовтий 410грн.jpg", 
    price: 410 
},
{ 
    id: "984", 
    name: "Сухоріз Distar A1RSS з фл зелений ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 A1RSS з фл зелений 1130 грн.jpg", 
    price: 1130 
},
{ 
    id: "985", 
    name: "Сухоріз Distar aser CTH ADTnS оранж литий з фл ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 aser CTH ADTnS оранж литий з фл 1025 грн.jpg", 
    price: 1025 
},
{ 
    id: "986", 
    name: "Сухоріз Distar Baumesser Rapid PRO G  ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Baumesser Rapid PRO G 780грн.jpg", 
    price: 780 
},
{ 
    id: "987", 
    name: "Сухоріз Distar Elite Active зелений литий флянець ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Elite Active зелений литий флянець 960грн.jpg", 
    price: 960 
},
{ 
    id: "988", 
    name: "Сухоріз Distar  Elite Aero під флянець", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Elite Aero під флянець 780грн.jpg", 
    price: 780 
},
{ 
    id: "989", 
    name: "Сухоріз Distar Expert 5D БЕТОН", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Expert 5D БЕТОН 798грн.jpg", 
    price: 798 
},
{ 
    id: "990", 
    name: "Сухоріз Distar Laser CTH ADTnS оранж литий з фл", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Laser CTH ADTnS оранж литий з фл 1025грн.jpg", 
    price: 1025 
},
{ 
    id: "991", 
    name: "Сухоріз Distar Maestro 7D", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Maestro 7D 2295грн.jpg", 
    price: 2295 
},
{ 
    id: "992", 
    name: "Сухоріз Distar Turbo Master жовтий гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Distar D230 Turbo Master жовтий гайка 588грн.jpg", 
    price: 588 
},
{ 
    id: "993", 
    name: "Сухоріз Drimalati литий флянець", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Drimalati D230 литий флянець 2300грн.jpg", 
    price: 2300 
},
{ 
    id: "994", 
    name: "Сухоріз EHWA рожевий з флянцем", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз EHWA рожевий D115 з флянцем 850грн.jpg", 
    price: 850 
},
{ 
    id: "995", 
    name: "Сухоріз EHWA рожевий з флянцем ", 
    sizes: "D150", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз EHWA рожевий D150 з флянцем 1200грн.jpg", 
    price: 1200 
},
{ 
    id: "996", 
    name: "Сухоріз EHWA під флянець", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз EHWA D125 під флянець 400грн.jpg", 
    price: 400 
},
{ 
    id: "997", 
    name: "Сухоріз EHWA Elite", 
    sizes: "D230-G", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз EHWA D230-G Elite 730грн.jpg", 
    price: 730 
},
{ 
    id: "998", 
    name: "Сухоріз ELITE чорний   з флянцем ", 
    sizes: "D85", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE чорний D85  з флянцем 670грн.jpg", 
    price: 670 
},
{ 
    id: "999", 
    name: "Сухоріз ELITE під флянець зачисний чорний", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D 125 під флянець зачисний чорний 700грн.jpg", 
    price: 700 
},
{ 
    id: "1000", 
    name: "Сухоріз ELITE EHWA з флянцем", 
    sizes: "D65", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D65 EHWA з флянцем 880грн.jpg", 
    price: 880 
},
{ 
    id: "1001", 
    name: "Сухоріз ELITE зачисний PF", 
    sizes: "D75", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D75 зачисний PF 455грн.jpg", 
    price: 455 
},
{ 
    id: "1002", 
    name: "Сухоріз ELITE EHWA Multi з флянцем рожевий", 
    sizes: "D85", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D85 EHWA Multi з флянцем рожевий 1250грн.jpg", 
    price: 1250 
},
{ 
    id: "1003", 
    name: "Сухоріз ELITE під фл", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D105 під фл 400грн.jpg", 
    price: 400 
},
{ 
    id: "1004", 
    name: "Сухоріз ELITE EHWA з флянцем рожевий", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D105 EHWA з флянцем рожевий 810грн.jpg", 
    price: 810 
},
{ 
    id: "1005", 
    name: "Сухоріз ELITE EHWA MULTI З флянцем рожевий", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D105 EHWA MULTI З флянцем рожевий 1450грн.jpg", 
    price: 1450 
},
{ 
    id: "1006", 
    name: "Сухоріз ELITE", 
    sizes: "D105-F", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D105-F 520грн.jpg", 
    price: 520 
},
{ 
    id: "1007", 
    name: "Сухоріз ELITE синій PF", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D125 синій PF 480Грн.jpg", 
    price: 480 
},
{ 
    id: "1008", 
    name: "Сухоріз ELITE з флянцем", 
    sizes: "D125-F", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D125-F з фл 675грн.jpg", 
    price: 675 
},
{ 
    id: "1009", 
    name: "Сухоріз ELITE", 
    sizes: "D125-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D125-PF 525 грн.jpg", 
    price: 525 
},
{ 
    id: "1010", 
    name: "Сухоріз ELITE", 
    sizes: "D150-G", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D150-G 800грн.jpg", 
    price: 800 
},
{ 
    id: "1011", 
    name: "Сухоріз ELITE під флянець", 
    sizes: "D150-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D150-PF під фл 680грн.jpg", 
    price: 680 
},
{ 
    id: "1012", 
    name: "Сухоріз ELITE", 
    sizes: "D180-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D180-Pf 900грн.jpg", 
    price: 900 
},
{ 
    id: "1013", 
    name: "Сухоріз ELITE синій глиб.різ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D230 синій глиб.різ 1400грн.jpg", 
    price: 1400 
},
{ 
    id: "1014", 
    name: "Сухоріз ELITE синій", 
    sizes: "D230-F", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D230-F синій 1220 грн.jpg", 
    price: 1220 
},
{ 
    id: "1015", 
    name: "Сухоріз ELITE Segment", 
    sizes: "D230-PF", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D230-PF Segment 1100грн.jpg", 
    price: 1100 
},
{ 
    id: "1016", 
    name: "Сухоріз ELITE PF (синій)", 
    sizes: "D230-Turbo", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ELITE D230-Turbo PF (синій) 1100грн.jpg", 
    price: 1100 
},
{ 
    id: "1017", 
    name: "Сухоріз FY хуан чанг турбо синій з флянцем", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY хуан чанг турбо синій  D230 з фл 1100 грн.jpg", 
    price: 1100 
},
{ 
    id: "1018", 
    name: "Сухоріз Fy хуан чанг турбо синій з флянцем", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Fy хуан чанг турбо синій D125 з фл 650грн.jpg", 
    price: 650 
},
{ 
    id: "1019", 
    name: "Сухоріз FY штроборіз гайка", 
    sizes: "D-90", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D-90 штроборіз гайка 850грн.jpg", 
    price: 850 
},
{ 
    id: "1020", 
    name: "Сухоріз FY флянець", 
    sizes: "D65", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D65 флянець 480грн.jpg", 
    price: 480 
},
{ 
    id: "1021", 
    name: "Сухоріз FY з флянцем", 
    sizes: "D80", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D80 з флянцем 520грн.jpg", 
    price: 520 
},
{ 
    id: "1023", 
    name: "Сухоріз FY штраборіз (червоний)", 
    sizes: "D110", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D110 штраборіз (червоний) 450грн.jpg", 
    price: 450 
},
{ 
    id: "1024", 
    name: "Сухоріз FY гайка мрамур", 
    sizes: "D180", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D180 гайка мрамур 600грн.jpg", 
    price: 600 
},
{ 
    id: "1025", 
    name: "Сухоріз FY Гайка мрамур", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D230 Гайка мрамур 900грн.jpg", 
    price: 900 
},
{ 
    id: "1026", 
    name: "Сухоріз FY Гайка мрамур", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D230 Гайка мрамур 900грн.jpg", 
    price: 900 
},
{ 
    id: "1027", 
    name: "Сухоріз FY  гайка сегмент срібний", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY d230 гайка сегмент срібний 830грн.jpg", 
    price: 830 
},
{ 
    id: "1028", 
    name: "Сухоріз FY зачисний чорний", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D230 зачисний чорний 950грн.jpg", 
    price: 950 
},
{ 
    id: "1029", 
    name: "Сухоріз FY мрамур M14 зачисний", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D230 мрамур M14 зачисний 1700грн.jpg", 
    price: 1700 
},
{ 
    id: "1030", 
    name: "Сухоріз FY Benefit з фл синій глибокий різ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D230 Benefit з фл синій глибокий різ 1200грн.jpg", 
    price: 1200 
},
{ 
    id: "1031", 
    name: "Сухоріз FY хвиля зелений", 
    sizes: "D230F", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз FY D230F хвиля зелений 1000грн.jpg", 
    price: 1000 
},
{ 
    id: "1032", 
    name: "Сухоріз H.S.T", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз H.S.T D230 530грн.jpg", 
    price: 530 
},
{ 
    id: "1033", 
    name: "Сухоріз HESS з-F", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз HESS D125 з-F 500грн.jpg", 
    price: 500 
},
{ 
    id: "1034", 
    name: "Сухоріз Huang Chang глиб.різ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Huang Chang D230 глиб.різ 1400грн.jpg", 
    price: 1400 
},
{ 
    id: "1035", 
    name: "Сухоріз Huang Chang синій зачисний", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Huang Chang D230 синій зачисний 1400грн.jpg", 
    price: 1400 
},
{ 
    id: "1036", 
    name: "Сухоріз HuangChang синій гл різ з флянцем", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз HuangChang синій гл різ D125 з фл 850грн.jpg", 
    price: 850 
},
{ 
    id: "1037", 
    name: "Сухоріз HuangChang MULTI синій", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз HuangChang MULTI D125 синій 900грн.jpg", 
    price: 900 
},
{ 
    id: "1038", 
    name: "Сухоріз ITC гайка ", 
    sizes: "D150", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз ITC D150 гайка 500грн.jpg", 
    price: 500 
},
{ 
    id: "1039", 
    name: "Сухоріз MULTI з флянцем зачисний", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз MULTI D125 з флянцем зачисний 1700грн.jpg", 
    price: 1700 
},
{ 
    id: "1040", 
    name: "Сухоріз MULTI з флянцем протектор", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз MULTI D230 з флянцем протектор 2850грн.jpg", 
    price: 2850 
},
{ 
    id: "1041", 
    name: "Сухоріз MULTI FY з флянцем бірюза", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз MULTI FY D125 з флянцем бірюза 1150грн.jpg", 
    price: 1150 
},
{ 
    id: "1042", 
    name: "Сухоріз MULTI FY з флянцем зачисний", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз MULTI FY D230 з фл.зачисний 1800грн.jpg", 
    price: 180 
},
{ 
    id: "1043", 
    name: "Сухоріз NORMAN AVATAR", 
    sizes: "D100", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз NORMAN AVATAR D100 450грн.jpg", 
    price: 450 
},
{ 
    id: "1044", 
    name: "Сухоріз NORMAN Segment", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз NORMAN D115 Segment 250грн.jpg", 
    price: 250 
},
{ 
    id: "1045", 
    name: "Сухоріз NORMAN Segment ", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз NORMAN D230 Segment 800грн.jpg", 
    price: 800 
},
{ 
    id: "1046", 
    name: "Сухоріз NORMAN MARS ", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз NORMAN MARS D125 320грн.jpg", 
    price: 320 
},
{ 
    id: "1047", 
    name: "Сухоріз NORMAN SATURN ", 
    sizes: "D100", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз NORMAN SATURN D100 350грн.jpg", 
    price: 350 
},
{ 
    id: "1048", 
    name: "Сухоріз Palmina з флянцем", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Palmina D105 з флянцем 430грн.jpg", 
    price: 430 
},
{ 
    id: "1049", 
    name: "Сухоріз Palmina синій", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Palmina D125 синій 250грн.jpg", 
    price: 250 
},
{ 
    id: "1050", 
    name: "Сухоріз Palmina гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Palmina D230 гайка 1100грн.jpg", 
    price: 1100 
},
{ 
    id: "1051", 
    name: "Сухоріз Palmina гайка сегмент KXG", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Palmina D230 гайка сегмент KXG 1870грн.jpg", 
    price: 1870 
},
{ 
    id: "1052", 
    name: "Сухоріз Palmina під флянець", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Palmina D230 під флянець 1100грн.jpg", 
    price: 1100 
},
{ 
    id: "1053", 
    name: "Сухоріз Palmina під флянець зелений", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Palmina D230 під флянець зелений 700грн.jpg", 
    price: 700 
},
{ 
    id: "1054", 
    name: "Сухоріз PENTAX мрамур гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз PENTAX D230 мрамур гайка 1000грн.jpg", 
    price: 1000 
},
{ 
    id: "1055", 
    name: "Сухоріз PENTAX Maxi Per Kormer гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз PENTAX D230 Maxi Per Kormer гайка 1550грн.jpg", 
    price: 1550 
},
{ 
    id: "1056", 
    name: "Сухоріз S гайка конкейв", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз S 10 D230 гайка конкейв 750грн.jpg", 
    price: 750 
},
{ 
    id: "1057", 
    name: "Сухоріз S мрамур двохсторонній з фл", 
    sizes: "D180", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз S мрамур двохсторонній D180 з фл 1750грн.jpg", 
    price: 1750 
},
{ 
    id: "1058", 
    name: "Сухоріз Sait з флянцем червоний", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sait D125 з флянцем червоний 650грн.jpg", 
    price: 650 
},
{ 
    id: "1059", 
    name: "Сухоріз Sankyo гайка конкейв", 
    sizes: "D150", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo D150 гайка конкейв 1990Грн.jpg", 
    price: 1990 
},
{ 
    id: "1060", 
    name: "Сухоріз Sankyo гайка конкейв", 
    sizes: "D180", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo D180 гайка конкейв 2160грн.jpg", 
    price: 2160 
},
{ 
    id: "1061", 
    name: "Сухоріз Sankyo DDE мрамур гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo DDE мрамур D230 гайка 1695грн.jpg", 
    price: 1695 
},
{ 
    id: "1062", 
    name: "Сухоріз Sankyo DDE мрамур з флянцем", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo DDE мрамур D230 з флянцем 2120 грн.jpg", 
    price: 2120 
},
{ 
    id: "1063", 
    name: "Сухоріз Sankyo RI-4.5 З флянцем", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo RI-4.5 D115 З флянцем 900грн.jpg", 
    price: 900 
},
{ 
    id: "1064", 
    name: "Сухоріз Sankyo Ri-9 гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo Ri-9 D230 гайка 1990грн.jpg", 
    price: 1990 
},
{ 
    id: "1065", 
    name: "Сухоріз Sankyo Ri-9 під флянець", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo Ri-9 D230 під флянець 2175грн.jpg", 
    price: 2175 
},
{ 
    id: "1066", 
    name: "Сухоріз Sankyo RS-9 з флянцем", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo RS-9 D230 з флянцем 1990грн.jpg", 
    price: 1990 
},
{ 
    id: "1067", 
    name: "Сухоріз Sankyo Spartan з флянцем", 
    sizes: "D150", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo Spartan D150 з флянцем 890грн.jpg", 
    price: 890 
},
{ 
    id: "1068", 
    name: "Сухоріз Sankyo Spartan гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo Spartan D230 гайка 925грн.jpg", 
    price: 925 
},
{ 
    id: "1069", 
    name: "Сухоріз Sankyo Spartan з флянцем", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo Spartan D230 з флянцем 1240 грн.jpg", 
    price: 1240 
},
{ 
    id: "1070", 
    name: "Сухоріз Sankyo Titan з флянцем", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sankyo Titan D230 з флянцем 1530грн.jpg", 
    price: 1530 
},
{ 
    id: "1071", 
    name: "Сухоріз Sen-D з флянцем турбо", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Sen-D D230 з флянцем турбо 1400грн.jpg", 
    price: 1400 
},
{ 
    id: "1072", 
    name: "Сухоріз STALKER FLASH-PF", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER FLASH-PF D 105 250грн.jpg", 
    price: 250 
},
{ 
    id: "1073", 
    name: "Сухоріз STALKER Force Гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER Force D230 Гайка 720грн.jpg", 
    price: 720 
},
{ 
    id: "1074", 
    name: "Сухоріз STALKER Force-F", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER Force-F D230 830грн.jpg", 
    price: 830 
},
{ 
    id: "1075", 
    name: "Сухоріз STALKER GABBRO-F", 
    sizes: "D100", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER GABBRO-F D100 500грн.jpg", 
    price: 500 
},
{ 
    id: "1076", 
    name: "СухоРіз STALKER SATURN-F", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/СухоРіз STALKER SATURN-F D115 400грн.jpg", 
    price: 400 
},
{ 
    id: "1077", 
    name: "Сухоріз STALKER SHRED-F MASTERLINE", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER SHRED-F MASTERLINE D105 370грн.jpg", 
    price: 370 
},
{ 
    id: "1078", 
    name: "Сухоріз STALKER SHRED-F MASTERLINE", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER SHRED-F MASTERLINE D125 440грн.jpg", 
    price: 440 
},
{ 
    id: "1079", 
    name: "Сухоріз STALKER SPRINTER гайка", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER SPRINTER D230 гайка 980грн.jpg", 
    price: 980 
},
{ 
    id: "1080", 
    name: "Сухоріз STALKER SPRINTER-F", 
    sizes: "D115",
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER SPRINTER-F D115 535Грн.jpg", 
    price: 535 
},
{ 
    id: "1081", 
    name: "Сухоріз STALKER SPRINTER-F", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER SPRINTER-F D125 710грн.jpg", 
    price: 710 
},
{ 
    id: "1082", 
    name: "Сухоріз STALKER UPITER-F", 
    sizes: "D100", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER UPITER-F D100 550грн.jpg", 
    price: 550 
},
{ 
    id: "1083", 
    name: "Сухоріз STALKER WAVE-F", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER WAVE-F D125 620грн.jpg", 
    price: 620 
},
{ 
    id: "1084", 
    name: "Сухоріз STALKER WIND-F", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER WIND-F D115 430грн.jpg", 
    price: 430 
},
{ 
    id: "1085", 
    name: "Сухоріз STALKER WIND-F", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STALKER WIND-F D125 450грн.jpg", 
    price: 450 
},
{ 
    id: "1086", 
    name: "Сухоріз STERN Austria гайка сірий", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз STERN Austria D115 гайка сірий 100грн.jpg", 
    price: 100 
},
{ 
    id: "1087", 
    name: "Сухоріз StoneCraft з фл", 
    sizes: "D105", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз StoneCraft D105 з фл 600грн.jpg", 
    price: 600 
},
{ 
    id: "1088", 
    name: "Сухоріз StoneCraft криворіз", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз StoneCraft D125 криворіз 850грн.jpg", 
    price: 850 
},
{ 
    id: "1089", 
    name: "Сухоріз StoneCraft гайка конкейв", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз StoneCraft D230 гайка конкейв 1500грн.jpg", 
    price: 1500 
},
{ 
    id: "1090", 
    name: "Сухоріз Zenesis з флянцем", 
    sizes: "D125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Zenesis D125 з флянцем 1025 грн.jpg", 
    price: 1025 
},
{ 
    id: "1091", 
    name: "Сухоріз Zenesis з флянцем", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз Zenesis D230 з флянцем 1900грн.jpg", 
    price: 1900 
},
{ 
    id: "1092", 
    name: "Сухоріз-штроборіз рожевий", 
    sizes: "D115", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз-штроборіз D115 рожевий 960грн.jpg", 
    price: 960 
},
{ 
    id: "1093", 
    name: "Сухоріз(пила) ELITE", 
    sizes: "D250x25,4", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Сухоріз(пила) ELITE D250x25,4 775грн.jpg", 
    price: 775 
},
{ 
    id: "1094", 
    name: "Флянець на 4 гвинта", 
    sizes: "D105-125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Флянець D105-125 на 4 гвинта 155грн.jpg", 
    price: 155 
},
{ 
    id: "1095", 
    name: "Флянець на 6 отв Сх.Діам", 
    sizes: "D105-125", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Флянець D105-125 на 6 отв Сх.Діам 120грн.jpg", 
    price: 120 
},
{ 
    id: "1096", 
    name: "Флянець Norman", 
    sizes: "D105-230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Флянець D105-230 Norman 220грн.jpg", 
    price: 220 
},
{ 
    id: "1097", 
    name: "Флянець Схід Діамант", 
    sizes: "D230", 
    category: Tools, 
    subCategory: DryCuts, 
    urlImg: "/images/Флянець D230 Схід Діамант 170грн.jpg", 
    price: 170 
},
{
    id: "1098",
    name: "Шар габро б.н",
    sizes: "D10",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар D10 габро б.н 290грн.jpg",
    price: 290
},
{
    id: "1099",
    name: "Шар ток",
    sizes: "D10",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар ток D10 400грн.jpg",
    price: 400
},
{
    id: "1100",
    name: "Шар покостівка",
    sizes: "D12",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар покостівка D12 360грн.jpg",
    price: 380
},
{
    id: "1101",
    name: "Шар маславка",
    sizes: "D12",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар маславка D12 450грн.jpg",
    price: 500
},
{
    id: "1102",
    name: "Шар лізник",
    sizes: "D15",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар лізник D15 800грн.jpg",
    price: 800
},
{
    id: "1103",
    name: "Шар лізник б.н",
    sizes: "D10",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар лізник б.н D10 330грн.jpg",
    price: 330
},
{
    id: "1104",
    name: "Шар лєзнік",
    sizes: "D12",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар лєзнік D12 450грн.jpg",
    price: 520
},
{
    id: "1105",
    name: "Шар лєзнік",
    sizes: "D10",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар лєзнік D10 400грн.jpg",
    price: 450
},
{
    id: "1106",
    name: "Шар габро",
    sizes: "D12",
    category: DecorativeProductsGranite,
    subCategory: Spheres,
    urlImg: "/images/Шар габро D12 330грн.jpg",
    price: 380
},
{
    id: "1107",
    name: "Пила FY шумка",
    sizes: "D600x20",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила FY D600x20 шумка 6500грн.jpg",
    price: 6500
},
{
    id: "1108",
    name: "Пила FY тиха PREMIUM",
    sizes: "D600",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила FY D600 тиха PREMIUM 7000 грн.jpg",
    price: 7000
},
{
    id: "1109",
    name: "Пила FY тиха",
    sizes: "D500",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила FY D500 тиха 5000грн.jpg",
    price: 5000
},
{
    id: "1110",
    name: "Пила ELITE шумка",
    sizes: "D466",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила ELITE D466 шумка 4500грн.jpg",
    price: 4500
},
{
    id: "1111",
    name: "Пила ELITE безшумка",
    sizes: "D466",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила ELITE D466 безшумка 6700грн.jpg",
    price: 6700
},
{
    id: "1112",
    name: "Пила Distar мрамур",
    sizes: "D400",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D400 мрамур 3180грн.jpg",
    price: 3180
},
{
    id: "1113",
    name: "Пила Distar граніт Premium 7D",
    sizes: "D400",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D400 граніт Premium 7D 2920грн.jpg",
    price: 2920
},
{
    id: "1114",
    name: "Пила Distar граніт 5D",
    sizes: "D400",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D400 граніт 5D 2920грн.jpg",
    price: 2920
},
{
    id: "1115",
    name: "Пила Distar мрамур",
    sizes: "D350",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D350 мрамур 2950грн.jpg",
    price: 2950
},
{
    id: "1116",
    name: "Пила Distar граніт 5D",
    sizes: "D350",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D350 граніт 5D 2700грн.jpg",
    price: 2700
},
{
    id: "1117",
    name: "Пила Distar 7D граніт",
    sizes: "D350",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D350 7D граніт 2697грн.jpg",
    price: 2697
},
{
    id: "1118",
    name: "Пила Distar мрамур",
    sizes: "D300",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D300 мрамур 2297 грн.jpg",
    price: 2297
},
{
    id: "1119",
    name: "Пила Distar мрамур",
    sizes: "D250",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D250 мрамур 1380грн.jpg",
    price: 1380
},
{
    id: "1120",
    name: "Пила Distar граніт 7D",
    sizes: "D250",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D250 граніт 7D 1250Грн.jpg",
    price: 1250
},
{
    id: "1121",
    name: "Пила Distar граніт Premium 7d",
    sizes: "D230",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D230 граніт Premium 7d 1130грн.jpg",
    price: 1130
},
{
    id: "1122",
    name: "Пила Distar граніт 5D",
    sizes: "D230",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D230 граніт 5D 1130грн.jpg",
    price: 1130
},
{
    id: "1123",
    name: "Пила Distar 5D",
    sizes: "D200",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D200 5D 979грн.jpg",
    price: 979
},
{
    id: "1124",
    name: "Пила Distar граніт преміум",
    sizes: "D180",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила Distar D180 граніт преміум 767 грн.jpg",
    price: 767
},
{
    id: "1125",
    name: "Пила тиха ВР",
    sizes: "500x20",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила D500x20 тиха ВР 5500грн.jpg",
    price: 5500
},
{
    id: "1126",
    name: "Пила ХМ",
    sizes: "D400",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила D400 ХМ 1200грн.jpg",
    price: 1200
},
{
    id: "1127",
    name: "Пила Хм",
    sizes: "D350",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила D350 Хм 1100грн.jpg",
    price: 1100
},
{
    id: "1128",
    name: "Пила безшумка (м'який-середній граніт)",
    sizes: "D430x40",
    category: Tools,
    subCategory: Saws,
    urlImg: "/images/Пила безшумка 430х40(м_який-середній граніт) 3500грн.jpg",
    price: 3500
},
{
        id: "1130",
        name: "Лампадка габро квадратна скло",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Lamps,
        urlImg: ["/images/Лампадка_квадратна_габро_скло_1700грн_1.jpg", "/images/Лампадка_квадратна_габро_скло_1700грн_2.jpg", "/images/Лампадка_квадратна_габро_скло_1700грн_3.jpg", "/images/Лампадка_квадратна_габро_скло_1700грн_4.jpg", "/images/Лампадка_квадратна_габро_скло_1700грн_5.jpg"],
        price: 1700
},
{
    id: "1131",
    name: "Лампадка цільна лізник",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка цільна кольорова(маславка, корнин) 1300грн (1).jpg",
    price: 1800
},
{
    id: "1132",
    name: "Лампадка цільна корнин",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка цільна кольорова (маславка, корнин) 1300грн.jpg",
    price: 1500
},
{
    id: "1133",
    name: "Лампадка покостівка кругла з хрестиком",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: ["/images/Лампадка покостівка кругла з таблеткою та хрестиком 1700грн (1).jpg", "/images/Лампадка покостівка кругла з таблеткою та хрестиком 1700грн(2).jpg]"],
    price: 1700
},
{
    id: "1134",
    name: "Лампадка Львів кольорова",
    sizes: "Висота 25см Ширина 15,5см",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: ["/images/1134.jpg", "/images/1134 (1).jpg", "/images/1134 (2).jpg", "https://i.imgur.com/XK7B9v6.mp4"],
    price: 700
},
{
    id: "1135",
    name: "Лампадка Львів кольорова",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка Львів кольорова 650грн.jpg",
    price: 700
},
{
    id: "1136",
    name: "Лампадка Львів кольорова",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка Львів кольорова 650грн (2).jpg",
    price: 700
},
{
    id: "1137",
    name: "Лампадка Львів кольорова",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка Львів кольорова 650грн (1).jpg",
    price: 700
},
{
    id: "1138",
    name: "Лампадка квадратна ток",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна ток 1300грн.jpg",
    price: 1500
},
{
    id: "1139",
    name: "Лампадка квадратна покостовка з хрестом",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна покостовка з хрестіком  1550грн.jpg",
    price: 1700
},
{
    id: "1140",
    name: "Лампадка квадратна лізник",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна лізник 1300грн.jpg",
    price: 1500
},
{
    id: "1141",
    name: "Лампадка квадратна капуста",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна капуста 1300грн.jpg",
    price: 1500
},
{
    id: "1142",
    name: "Лампадка габро кругла з хрестиком",
    sizes: "Висота 30см Ширина 16,5см",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: ["/images/1142.jpg", "/images/1142 (2).jpg", "/images/1142 (1).jpg", "https://i.imgur.com/XEvXkbc.mp4"],
    price: 1600
},
{
    id: "1143",
    name: "Ступка",
    sizes: "",
    category: DecorativeProductsGranite,
    subCategory: Stupki,
    urlImg: "/images/Ступка 1400грн.jpg",
    price: 1400
},

{
    id: "1144",
    name: "Цукерниця габро",
    sizes: "",
    category: DecorativeProductsGranite,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця габро 1200грн.jpg",
    price: 1400
},

{
    id: "1145",
    name: "Цукерниця габро на ніжці",
    sizes: "",
    category: DecorativeProductsGranite,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця габро на ніжці 1500грн.jpg",
    price:  1500
},

{
    id: "1146",
    name: "Цукерниця кольорова",
    sizes: "",
    category: DecorativeProductsGranite,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця кольорова 1300грн.jpg",
    price: 1500
},
{
    id: "1147",
    name: "Буква Італія",
    sizes: "3см",
    category: BronzeProducts,
    subCategory: Letters,
    urlImg: "/images/Буква 3см Італія 85грн.jpg",
    price: 95
},
{
    id: "1148",
    name: "Буква Італія",
    sizes: "5см",
    category: BronzeProducts,
    subCategory: Letters,
    urlImg: "/images/Буква 5см Італія 135грн.jpg",
    price: 150
},
{
    id: "1149",
    name: "Знаки Італія",
    sizes: "",
    category: BronzeProducts,
    subCategory: Letters,
    urlImg: "/images/Знаки Італія 60грн.jpg",
    price: 65
},
{
    id: "1150",
    name: "Цифра Італія",
    sizes: "3см",
    category: BronzeProducts,
    subCategory: Letters,
    urlImg: "/images/Цифра 3см Італія 85 грн.jpg",
    price: 95
},
{
    id: "1151",
    name: "Цифра Італія",
    sizes: "3см",
    category: BronzeProducts,
    subCategory: Letters,
    urlImg: "/images/Цифра 3см Італія 85грн(1).jpg",
    price: 95
},
{
    id: "1152",
    name: "Цифра Італія",
    sizes: "3см",
    category: BronzeProducts,
    subCategory: Letters,
    urlImg: "/images/Цифра 3см Італія 85грн (2).jpg",
    price: 95
},
{
        id: "1153",
        name: "Лампадка квадратна габро",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Lamps,
        urlImg: "/images/Лампадка квадратна габро 1300грн.jpg",
        price: 1400
},
{
    id: "1154",
    name: "Лампадка цільна кольорова(маславка, корнин)",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка цільна кольорова(маславка, корнин) 1300грн.jpg",
    price: 1800
},
{
    id: "1155",
    name: "Ваза габро прорізна",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза габро прорізна 30см 1500грн.jpg",
    price: 1500
},
{
    id: "1156",
    name: "Ваза прорізна габро",
    sizes: "Висота 25см Ширина 15см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: ["/images/1156 (3).jpg", "/images/1156.jpg", "/images/1156 (1).jpg", "https://i.imgur.com/8Vhn11A.mp4"],
    price: 1200
},
{
    id: "1157",
    name: "Ваза прорізна маславка",
    sizes: "30см",
    category: DecorativeProductsGranite,
    subCategory: Vases,
    urlImg: "/images/Ваза прорізна маславка 30см 1600грн.jpg",
    price: 1600
},
{
    id: "1158",
    name: "Клей Jolly Liquido Nero Jolly прозорий рідкий",
    sizes: "1л",
    category: Perm,
    subCategory: Glue,
    urlImg: "/images/Клей Jolly 1л Liquido Nero Jolly прозорий рідкий 300грн.jpg",
    price: 300
},
{
    id: "1159",
    name: "Лампадка квадратна габро з хрестиком",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна габро з хрестіком 1600грн.jpg",
    price: 1700
},
{
    id: "1160",
    name: "Лампадка квадратна маславка",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: ["/images/Лампадка квадратна маславка 1500грн (1).jpg", "/images/Лампадка квадратна маславка 1500грн (2).jpg", "/images/Лампадка квадратна маславка 1500грн (3).jpg"],
    price: 1500
},
{
    id: "1161",
    name: "Лампадка квадратна покостовка",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна покостовка 1400грн.jpg",
    price: 1400
},
{
    id: "1162",
    name: "Лампадка квадратна лабродорит",
    sizes: " ",
    category: DecorativeProductsGranite,
    subCategory: Lamps,
    urlImg: "/images/Лампадка квадратна лабродорит 1400грн.jpg",
    price: 1400
},
{
    id: "1163",
    name: "Полір порошок Brillux",
    sizes: "1кг",
    category: Perm,
    subCategory: Powder,
    urlImg: "/images/Полір порошок Brillux 1кг 220грн.jpg",
    price: 260
},
{
    id: "1164",
    name: "Пропітка Easywet TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Easywet 0,250мл TENAX 620грн.jpg",
    price: 660
},
{
    id: "1165",
    name: "Пропітка Easywet TENAX",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Easywet 1л TENAX 1350грн.jpg",
    price: 1350
},
{
    id: "1166",
    name: "Пропітка HYDREX TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка HYDREX 0,250ml TENAX 420грн.jpg",
    price: 400
},
{
    id: "1167",
    name: "Пропітка Proseal TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Proseal 0,250ml TENAX 750грн.jpg",
    price: 710
},
{
    id: "1168",
    name: "Пропітка Uniblack 1 TENAX",
    sizes: "1л",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Uniblack 1 1л TENAX 1570грн.jpg",
    price: 1630
},
{
    id: "1169",
    name: "Пропітка Uniblack 2 TENAX",
    sizes: "250мл",
    category: Perm,
    subCategory: Impregnations,
    urlImg: "/images/Пропітка Uniblack 2 0,250ml TENAX 400uah.jpg",
    price: 375
},
{
    id: "1170",
    name: "Сухоріз Схід Діамант",
    sizes: "D80",
    category: Tools,
    subCategory: DryCuts,
    urlImg: "/images/Сухоріз Схід Діамант D80-PF 250грн.jpg",
    price: 240
},
{
    id: "1171",
    name: "Сухоріз Схід Діамант",
    sizes: "D105",
    category: Tools,
    subCategory: DryCuts,
    urlImg: "/images/Сухоріз Схід Діамант D105-PF 270грн.jpg",
    price: 250
},
{
    id: "1172",
    name: "Сухоріз Схід Діамант",
    sizes: "D115",
    category: Tools,
    subCategory: DryCuts,
    urlImg: "/images/Сухоріз Схід Діамант D115-PF 300грн.jpg",
    price: 270
},
{
    id: "1173",
    name: "Сухоріз Схід Діамант",
    sizes: "D150",
    category: Tools,
    subCategory: DryCuts,
    urlImg: "/images/Сухоріз Схід Діамант D150-PF 460грн.jpg",
    price: 490
},
{
    id: "1174",
    name: "Сухоріз Схід Діамант",
    sizes: "D180",
    category: Tools,
    subCategory: DryCuts,
    urlImg: "/images/Сухоріз Схід Діамант D180-PF 550грн.jpg",
    price: 550
},
{
    id: "1175",
    name: "Сухоріз Схід Діамант",
    sizes: "D230",
    category: Tools,
    subCategory: DryCuts,
    urlImg: "/images/Сухоріз Схід Діамант D230-PF 800грн.jpg",
    price: 850
},
{
    id: "1176",
    name: "Очищувач BRAVO",
    sizes: "1л",
    category: Perm,
    subCategory: Cleaner,
    urlImg: "/images/Очищувач BRAVO 1л 500грн.jpg",
    price: 575
},
{
    id: "1177",
    name: "Очищувач BRIOACTION",
    sizes: "1л",
    category: Perm,
    subCategory: Cleaner,
    urlImg: "/images/Очищувач BRIOACTION 1л 1000грн.jpg",
    price: 1000
},
{
    id: "1178",
    name: "Тризуб базальт",
    sizes: "18х10см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб 18х10см базальт 650грн.jpg",
    price: 650
},
{
    id: "1179",
    name: "Тризуб габро на підставці",
    sizes: "20х10х2",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: ["/images/Тризуб габро 20х10х2 на підставці 1100грн (1).jpg","/images/Тризуб габро 20х10х2 на підставці 1100грн.jpg"],
    price: 1100
},
{
    id: "1180",
    name: "Тризуб габро ЧПУ",
    sizes: "25х15см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро 25х15см ЧПУ 1400грн.jpg",
    price: 1400
},
{
    id: "1181",
    name: "Тризуб габро на ніжці",
    sizes: "27х15х5см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро 27х15х5 на ніжці 2200грн.jpg",
    price: 2200
},
{
    id: "1182",
    name: "Тризуб габро",
    sizes: "40х24см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро 40х24 см 1700грн.jpg",
    price: 1700
},
{
    id: "1183",
    name: "Тризуб габро на ніжці",
    sizes: "40х25х5см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро 40х25х5 на ніжці 4300грн.jpg",
    price: 4300
},
{
    id: "1184",
    name: "Тризуб габро",
    sizes: "50х30х3",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро 50х30х3 3200грн.jpg",
    price: 3200
},
{
    id: "1185",
    name: "Тризуб габро на ніжці",
    sizes: "40х20х3.5см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро на ніжці 40х20х3.5 см ЧПУ 2300грн.jpg",
    price: 2300
},
{
    id: "1186",
    name: "Тризуб габро на ніжці",
    sizes: "60х32см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб габро на ніжці 60х32см 4200грн.jpg",
    price: 4200
},
{
    id: "1187",
    name: "Тризуб гранітний ЧПУ",
    sizes: "30х18см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб гранітний 30х18см ЧПУ 1500грн.jpg",
    price: 1500
},
{
    id: "1188",
    name: "Тризуб лізник",
    sizes: "40х25х3см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб лізник 40х25х3 4500грн.jpg",
    price: 4500
},
{
    id: "1189",
    name: "Тризуб лізник на ніжці",
    sizes: "40х25х3см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб лізник 40х25х3 на ніжці 5000грн.jpg",
    price: 5000
},
{
    id: "1190",
    name: "Тризуб лізник на ніжці",
    sizes: "40х25х5см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб лізник 40х25х5 на ніжці 6000грн.jpg",
    price: 6000
},
{
    id: "1191",
    name: "Тризуб на ніжці",
    sizes: "75х40см",
    category:DecorativeProductsGranite ,
    subCategory: Tridents,
    urlImg: "/images/Тризуб на ніжці 75х40см 7000грн.jpg",
    price: 7000
},
{
    id: "1192",
    name: "Хрест габро ЗСУ ЧПУ",
    sizes: "55х35х8см",
    category:DecorativeProductsGranite ,
    subCategory: CrossesGranite,
    urlImg: "/images/Хрест габро ЗСУ ЧПУ 55х35х8 5000грн.jpg",
    price: 5500
},
{
    id: "1194",
    name: "Цукерниця габро на ніжці глибока",
    sizes: " ",
    category:DecorativeProductsGranite ,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця габро на ніжці глибока 1300грн.jpg",
    price: 1400
},
{
    id: "1195",
    name: "Цукерниця з капустинського граніту",
    sizes: " ",
    category:DecorativeProductsGranite ,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця з капустнинського граніту 1400грн.jpg",
    price: 1400
},
{
    id: "1196",
    name: "Цукерниця лізник на ніжці",
    sizes: " ",
    category:DecorativeProductsGranite ,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця лізник на ніжці глибока 1400грн.jpg",
    price: 1500
},
{
    id: "1197",
    name: "Цукерниця покостівка на ніжці",
    sizes: " ",
    category:DecorativeProductsGranite ,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця покостівка на ніжці глибока 1300грн.jpg",
    price: 1400
},
{
    id: "1198",
    name: "Цукерниця ток",
    sizes: " ",
    category:DecorativeProductsGranite ,
    subCategory: Stupki,
    urlImg: "/images/Цукерниця ток велика 1400грн.jpg",
    price: 1500
},
{
    id: "1199",
    name: "Ікона Ангел",
    sizes: "22х13",
    category:DecorativeProductsGranite ,
    subCategory: Icons,
    urlImg: "/images/Ікона Ангел 22х13 2300грн.jpg",
    price: 2300
},
{
    id: "1200",
    name: "Ікона емистрільна",
    sizes: "30х16",
    category:DecorativeProductsGranite ,
    subCategory: Icons,
    urlImg: "/images/Ікона емистрільна 30х16 2300грн.jpg",
    price: 2300
},
{
    id: "1201",
    name: "Ікона Святий Микола",
    sizes: " ",
    category:DecorativeProductsGranite ,
    subCategory: Icons,
    urlImg: "/images/Ікона Святий Микола 3000грн.jpg",
    price: 3000
},
{
    id: "1202",
    name: "Ікона Тайна вечеря",
    sizes: "40х20",
    category:DecorativeProductsGranite ,
    subCategory: Icons,
    urlImg: "/images/Ікона Тайна вечеря 40х20 9000грн.jpg",
    price: 9000
},
{
    id: "1203",
    name: "Карта України",
    sizes: "35х20х5",
    category:DecorativeProductsGranite ,
    subCategory: Maps,
    urlImg: "/images/Карта України 35х20.5 1000грн.jpg",
    price: 1200
},
{
    id: "1204",
    name: "Карта України",
    sizes: "45х30х3",
    category:DecorativeProductsGranite ,
    subCategory: Maps,
    urlImg: "/images/Карта України 45х30х3 габро 2300грн.jpg",
    price: 2500
},
{
    id: "1205",
    name: "Карта України габро з областями",
    sizes: "50х35",
    category:DecorativeProductsGranite ,
    subCategory: Maps,
    urlImg: "/images/Карта України 50х35 габро з областями 2800грн.jpg",
    price: 3000
},
{
    id: "1206",
    name: "Карта України габро",
    sizes: "60х40х3",
    category:DecorativeProductsGranite ,
    subCategory: Maps,
    urlImg: "/images/Карта України 60х40х3 габро 2600грн.jpg",
    price: 3500
},
{
    id: "1207",
    name: "Картина Вікінг",
    sizes: "30х20",
    category:DecorativeProductsGranite ,
    subCategory: Paintings,
    urlImg: "/images/Картина Вікінг 30х20 1800грн.jpg",
    price: 1800
},
{
    id: "1208",
    name: "Картина Вовк",
    sizes: "30х20",
    category:DecorativeProductsGranite ,
    subCategory: Paintings,
    urlImg: "/images/Картина Вовк 30х20 1800грн.jpg",
    price: 1800
},
{
    id: "1209",
    name: "Картина Ісус Христос",
    sizes: "",
    category:DecorativeProductsGranite ,
    subCategory: Paintings,
    urlImg: "/images/Картина Ісус Xристос 1800грн.jpg",
    price: 1800
},
{
    id: "1210",
    name: "Картина Ісус Христос",
    sizes: "",
    category:DecorativeProductsGranite ,
    subCategory: Paintings,
    urlImg: "/images/Картина Ісус Христос 1800грн.jpg",
    price: 1800
},
{
    id: "1211",
    name: "Картина Тайна Вечеря",
    sizes: "55х29",
    category:DecorativeProductsGranite ,
    subCategory: Paintings,
    urlImg: "/images/Картина тайна вечеря 55х29 5000грн.jpg",
    price: 5000
},
{
    id: "1212",
    name: "Лампадка Львів габро",
    sizes: "",
    category:DecorativeProductsGranite ,
    subCategory: Lamps,
    urlImg: "/images/Лампадка Львів габро 650грн(1).jpg",
    price: 650
},
{
    id: "1214",
    name: "М'яч волейбольний на ніжці",
    sizes: "D22",
    category:DecorativeProductsGranite ,
    subCategory: Balls,
    urlImg: "/images/М'яч волейбольний D22 на ніжці 3250грн.jpg",
    price: 3250
},
{
    id: "1215",
    name: "М'яч футбольний на ніжці",
    sizes: "D15",
    category:DecorativeProductsGranite ,
    subCategory: Balls,
    urlImg: "/images/М'яч футбольний D15 на ніжці 1800грн.jpg",
    price: 1800
},
{
    id: "1217",
    name: "Розп'яття білий, античний",
    sizes: "46см",
    category:BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп'яття 46 см білий, античний 1500грн.jpg",
    price: 1500
},
{
    id: "1218",
    name: "Розп'яття бронза",
    sizes: "46см",
    category:BronzeProducts,
    subCategory: Crucifixes,
    urlImg: "/images/Розп'яття 46см бронза 1600грн(1).jpg",
    price: 1600
},
{
    id: "1221",
    name: "Cувенір Долар",
    sizes: "22х9",
    category:DecorativeProductsGranite,
    subCategory: Souvenirs,
    urlImg: "/images/Сувенір Долар 22х9 900грн.jpg",
    price: 900
},
{
    id: "1222",
    name: "Cувенір Долар габбро",
    sizes: "18х7",
    category:DecorativeProductsGranite,
    subCategory: Souvenirs,
    urlImg: "/images/Сувенір Долар габбро 18х7 800грн.jpg",
    price: 800
    },
    {
        id: "1223",
        name: "Ваза габро спіраль",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза 25см габро спіраль 1600грн (1).jpg", "/images/Ваза 25см габро спіраль 1600грн (2).jpg"],
        price: 1600
    },
    {
        id: "1224",
        name: "Ваза габро спіраль",
        sizes: "30см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза 30см габро спіраль 1900грн (1).jpg", "/images/Ваза 30см габро спіраль 1900грн (2).jpg"],
        price: 1900
    },
    {
        id: "1225",
        name: "Ваза габро",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 25см 500грн (1).jpg", "/images/Ваза габро 25см 500грн (2).jpg"],
        price: 550
    },
    {
        id: "1227",
        name: "Ваза габро",
        sizes: "Висота 30см Ширина 11,5см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1227 (2).jpg", "/images/1227 (1).jpg", "/images/1227 (3).jpg", "https://i.imgur.com/LahQrzK.mp4"],
        price: 600
    },
    {
        id: "1228",
        name: "Ваза габро",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 40см 750грн (1).jpg", "/images/Ваза габро 40см 750грн (2).jpg"],
        price: 850
    },
    {
        id: "1229",
        name: "Ваза габро квітка",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 40см квітка 1100грн (1).jpg", "/images/Ваза габро 40см квітка 1100грн (2).jpg"],
        price: 1200
    },
    {
        id: "1230",
        name: "Ваза габро спіраль",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 40см спіраль 2500грн (1).jpg", "/images/Ваза габро 40см спіраль 2500грн (2).jpg"],
        price: 2500
    },
    {
        id: "1231",
        name: "Ваза габро",
        sizes: "40х20",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 40х20 1600грн (1).jpg", "/images/Ваза габро 40х20 1600грн (2).jpg"],
        price: 2200
    },
    {
        id: "1232",
        name: "Ваза габро",
        sizes: "50см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 50см 1000грн (1).jpg", "/images/Ваза габро 50см 1000грн (2).jpg"],
        price: 1100
    },
    {
        id: "1233",
        name: "Ваза габро",
        sizes: "50х20",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза габро 50х20 2100грн (1).jpg", "/images/Ваза габро 50х20 2100грн (2).jpg"],
        price: 2400
    },
    {
        id: "1234",
        name: "Ваза дідковичі",
        sizes: "30см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/ваза дідковичі 30см 750грн (1).jpg", "/images/ваза дідковичі 30см 750грн (2).jpg"],
        price: 800
    },
    {
        id: "1235",
        name: "Ваза капуста",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза капуста 40см 1000грн (1).jpg", "/images/Ваза капуста 40см 1000грн (2).jpg"],
        price: 1000
    },
    {
        id: "1236",
        name: "Ваза капуста",
        sizes: "50х20",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза капуста 50х20см 3000грн (1).jpg", "/images/Ваза капуста 50х20см 3000грн (2).jpg"],
        price: 3000
    },
    {
        id: "1237",
        name: "Ваза квадратна комбінована з накладкою",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1237 (1).jpg", "/images/1237 (2).jpg", "/images/1237 (3).jpg", "https://i.imgur.com/HEbcTnj.mp4"],
        price: 850
    },
    {
        id: "1239",
        name: "Ваза лабрадорит",
        sizes: "Висота 25см Ширина 14см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1239.jpg", "/images/1239 (1).jpg", "/images/1239 (2).jpg", "https://i.imgur.com/MwWthjS.mp4"],
        price: 600
    },
    {
        id: "1240",
        name: "Ваза лабрадорит",
        sizes: "30см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лабрадорит 30см 700грн (1).jpg", "/images/Ваза лабрадорит 30см 700грн (2).jpg"],
        price: 700
    },
    {
        id: "1242",
        name: "Ваза лізник",
        sizes: "20см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лізник 20см 550грн (1).jpg", "/images/Ваза лізник 20см 550грн (2).jpg"],
        price: 650
    },
    {
        id: "1243",
        name: "Ваза лізник К",
        sizes: "30см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лізник 30см К 700грн (1).jpg", "/images/Ваза лізник 30см К 700грн (2).jpg"],
        price: 800
    },
    {
        id: "1244",
        name: "Ваза лізник К",
        sizes: "30см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лізник 30см К 700грн (3).jpg", "/images/Ваза лізник 30см К 700грн (4).jpg"],
        price: 800
    },
    {
        id: "1245",
        name: "Ваза лізник",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лізник 40см 1100грн (1).jpg", "/images/Ваза лізник 40см 1100грн (2).jpg"],
        price: 1350
    },
    {
        id: "1246",
        name: "Ваза лізник",
        sizes: "40х20",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лізник 40х20 2700грн (1).jpg", "/images/Ваза лізник 40х20 2700грн (2).jpg"],
        price: 2700
    },
    {
        id: "1247",
        name: "Ваза лізник",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза лізник 700грн (1).jpg", "/images/Ваза лізник 700грн (2).jpg"],
        price: 750
    },
    {
        id: "1248",
        name: "Ваза межеричка",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза межеричка 700грн (1).jpg", "/images/Ваза межеричка 700грн (2).jpg"],
        price: 800
    },
    {
        id: "1249",
        name: "Ваза покостівка",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза покостівка 25см 550грн (1).jpg", "/images/Ваза покостівка 25см 550грн (2).jpg"],
        price: 550
    },
    {
        id: "1250",
        name: "Ваза покостівка",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза покостівка 40см 750грн (1).jpg", "/images/Ваза покостівка 40см 750грн (2).jpg"],
        price: 800
    },
    {
        id: "1251",
        name: "Ваза покостівка",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза покостівка 40см 750грн (3).jpg", "/images/Ваза покостівка 40см 750грн (4).jpg"],
        price: 850
    },
    {
        id: "1252",
        name: "Ваза покостівка",
        sizes: "50х20",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза покостівка 50х20 2200грн (1).jpg", "/images/Ваза покостівка 50х20 2200грн (2).jpg"],
        price: 2500
    },
    {
        id: "1253",
        name: "Ваза прорізна індійський граніт",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза прорізна індійський граніт 25см 1250грн (1).jpg", "/images/Ваза прорізна індійський граніт 25см 1250грн (2).jpg"],
        price: 1200
    },
    {
        id: "1254",
        name: "Ваза прорізна капуста",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза прорізна капуста 25см 1380грн (1).jpg", "/images/Ваза прорізна капуста 25см 1380грн (2).jpg"],
        price: 1300
    },
    {
        id: "1255",
        name: "Ваза прорізна лабрадорит",
        sizes: "Висота 25см Ширина 14см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1255.jpg", "/images/1255 (2).jpg", "/images/1255 (1).jpg", "https://i.imgur.com/6nY2VQG.mp4"],
        price: 1300
    },
    {
        id: "1256",
        name: "Ваза прорізна покостівка",
        sizes: "Висота 25см Ширина 14,5см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1256.jpg", "/images/1256 (1).jpg", "/images/1256 (2).jpg", "https://i.imgur.com/nmBU9CP.mp4"],
        price: 1300
    },
    {
        id: "1257",
        name: "Ваза ток",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза ток 25см 650грн (1).jpg", "/images/Ваза ток 25см 650грн (2).jpg"],
        price: 750
    },
    {
        id: "1258",
        name: "Ваза ток",
        sizes: "40см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза ток 40см 1000грн (1).jpg", "/images/Ваза ток 40см 1000грн (2).jpg"],
        price: 1000
    },
    {
        id: "1259",
        name: "Ваза ток",
        sizes: "50х20",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза ток 50х20 3000грн (1).jpg", "/images/Ваза ток 50х20 3000грн (2).jpg"],
        price: 3000
    },
    {
        id: "1260",
        name: "Ваза трапеція габро",
        sizes: "Висота 25см Ширина 17см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1260.jpg", "/images/1260 (2).jpg", "/images/1260 (1).jpg", "https://i.imgur.com/QUWW2nD.mp4"],
        price: 950
    },
    {
        id: "1261",
        name: "Ваза трапеція покостівка",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза трапеція покостівка 25см 950грн (2).jpg", "/images/Ваза трапеція покостівка 25см 950грн (3).jpg"],
        price: 950
    },
    {
        id: "1262",
        name: "Ваза тюльпан габро",
        sizes: "Висота 25см Ширина 14см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/1262.jpg", "/images/1262 (2).jpg", "/images/1262 (1).jpg", "https://i.imgur.com/L93HzDG.mp4"],
        price: 950
    },
    {
        id: "1263",
        name: "Ваза тюльпан покостівка",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/Ваза тюльпан покостівка 25см 950грн (1).jpg", "/images/Ваза тюльпан покостівка 25см 950грн (2).jpg"],
        price: 950
    },
    {
        id: "1265",
        name: "Лампадка квадратна габро ELITE",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Lamps,
        urlImg: ["/images/Лампадка квадратна габро ELITE 1200грн (1).jpg", "/images/Лампадка квадратна габро ELITE 1200грн (2).jpg"],
        price: 1200
    },
    {
        id: "1266",
        name: "Лампадка квадратна дідковичі",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Lamps,
        urlImg: ["/images/Лампадка квадратна дідковичі 1500грн (1).jpg", "/images/Лампадка квадратна дідковичі 1500грн (2).jpg", "/images/Лампадка квадратна дідковичі 1500грн (3).jpg"],
        price: 1500
    },
    {
        id: "1267",
        name: "Лампадка квадратна лізник ELITE",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Lamps,
        urlImg: ["/images/Лампадка квадратна лізник ELITE 1250грн (1).jpg", "/images/Лампадка квадратна лізник ELITE 1250грн(2).jpg", "/images/Лампадка квадратна лізник ELITE 1250грн (3).jpg"],
        price: 1250
    },
    {
        id: "1269",
        name: "Лампадка мармур",
        sizes: " ",
        category: DecorativeProductsGranite,
        subCategory: Lamps,
        urlImg: ["/images/Лампадка мрамур 1600грн (1).jpg", "/images/Лампадка мрамур 1600грн (2).jpg", "/images/Лампадка мрамур 1600грн (3).jpg"],
        price: 1600
    },
    {
        id: "1271",
        name: "Ваза мармур",
        sizes: "20см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/ваза мармур 20см 2300грн (1).jpg", "/images/ваза мармур 20см 2300грн (2).jpg", "/images/ваза мармур 20см 2300грн (3).jpg", "https://i.imgur.com/5BLxTQo.mp4"],
        price: 2300
    },
    {
        id: "1272",
        name: "Ваза мармур",
        sizes: "25см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/ваза мармур 25см 3700грн (1).jpg", "/images/ваза мармур 25см 3700грн (2).jpg", "/images/ваза мармур 25см 3700грн (3).jpg", "https://i.imgur.com/5z2omvv.mp4"],
        price: 3700
    },
    {
        id: "1273",
        name: "Ваза мармур",
        sizes: "30см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/ваза мармур 30см 4000грн (1).jpg", "/images/ваза мармур 30см 4000грн (2).jpg", "/images/ваза мармур 30см 4000грн (3).jpg", "https://i.imgur.com/WTZhRYc.mp4"],
        price: 4000
    },
    {
        id: "1274",
        name: "Ваза прорізна мармур",
        sizes: "23см",
        category: DecorativeProductsGranite,
        subCategory: Vases,
        urlImg: ["/images/ваза прорізна італійський камінь 23см 2000грн (3).png", "/images/ваза прорізна італійський камінь 23см 2000грн (1).png", "/images/ваза прорізна італійський камінь 23см 2000грн (2).png", "https://i.imgur.com/1jNuNLN.mp4"],
        price: 2000
    },
]