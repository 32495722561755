import React from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';

const MainPage = () => {
    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="top-container">
                    <div className="wrapper">
                        <div className="text">
                            <h1>
                                <span style={{ color: 'black' }}>Ласкаво просимо в магазин алмазних інструментів</span><br />
                                <span style={{ color: '#912a36' }}>DIATOOLS</span>
                            </h1>
                        </div>
                        {/* <div className="banner">
                            <img src='images/banner1.jpg' />
                        </div> */}
                    </div>
                    <div className="line">

                    </div>
                </div>
                <div className="container">
                    <h2>Найкраща якість інструментів для продуктивної роботи</h2>
                    <p>Ми - надійний партнер справжніх майстрів та професіоналів. У нас ви можете знайти інструменти не тільки для обробки каменю, а й для ремонтних та будівельних робіт. 
Вони відповідають найвищим стандартам якості та виготовлені за найкращими технологіями. </p><br/>

<p>Ми підберемо інструменти для будь-яких ваших завдань для ефективної роботи. Ми пишаємося своєю репутацією вже багато років і наші клієнти це відчувають. Можете довіритися нам для вражаючих результатів. 

Відкрийте для себе світ алмазних інструментів з нами сьогодні і робіть вашу роботу ефективнішою!</p>

                </div>
                <div className="image-container">
                    <img src="images/banner2.jpg" width={'80%'}></img>
                </div>
            </CommonLayout>
        </>
    )
}

export default MainPage;