import './style.scss';

const Icon = ({iconUrl, width, height}) => {
    return (
        <>
            <div className="icon">
                <img src={iconUrl} width={width} height={height}/>
            </div>
        </>
    )
}

export default Icon;