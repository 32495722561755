import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';

const ThanksPage = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="thanks-page-container">                    
                    <p className="thanks-text-title">Дякуємо за замовлення</p>
                    <p className="thanks-text">Найближчим часом з вами зв'яжиться менеджер з продажу</p>
                </div>

            </CommonLayout>
        </>
    )
}

export default ThanksPage;