import './style.scss';

const IconButton = ({iconUrl, width, height}) => {
    return (
        <>
            <button className="icon-button" >
                <img src={iconUrl} width={width} height={height}/>
            </button>
        </>
    )
}

export default IconButton;