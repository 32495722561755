import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const ProductCart = ({ id, urlImg, price, name, sizes, addProductToCart }) => {
    const [imageCount, setImageCount] = useState(0)

    const AddToCart = (e) => {
        e.preventDefault();
        addProductToCart({ id, urlImg, price, name, sizes, count: 1 })
    }

    return (
        <>
            <Link class="product-cart-wrapper" to={id}>
                <div className="product-cart">
                    <div className="product-image">
                        <img src={typeof (urlImg) == typeof ([]) ? urlImg[imageCount] : urlImg} />
                    </div>
                    <div className="product-price">{typeof(price) == typeof(0) ? `${price} грн` : `${price}`}</div>
                    <div className="product-desc">
                        <div className="row">
                            <div className="row-name">Назва</div>
                            <div className="row-value">{name}</div>
                        </div>
                        <div className="row">
                            <div className="row-name">Розміри</div>
                            <div className="row-value">{sizes}</div>
                        </div>
                    </div>
                    <div className="buttons">
                        {/* <div className="in-cart">
                        <IconButton iconUrl={'icons/black-cart.svg'} width={'30px'} height={'30px'}></IconButton>
                    </div> */}
                        <button className="primary-button order-by" onClick={AddToCart}>Додати в кошик</button>
                    </div>
                </div>
            </Link>

        </>
    )
}

export default ProductCart;