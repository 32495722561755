import { Link } from 'react-router-dom';
import './style.scss';
import { Addition, Points, Scraps, Fickerts, DecorativeProducts, DiamondDrills, Tridents, DiamondSharpeningWheels, Roses, Letters, Crucifixes, Impregnations, Frames, ForArtisticWorks, DecorativeProductsGranite, Tools, PersonalEquipment, Faces, BronzeProducts, Butchers, Layers, Books, Beets, Matrices, PolishingProducts, Urns, Plastic, Velcro, Buffs, Felts, DiamondCupCutter, DiamondCutter, Sharpeners, EngravingMachines, Chisels, Perm, Glue, Crystallizers, Polishes, Powder, Paint, Lamps, Vases, Spheres, CrossesGranite, Balls, Corners, Icons, BasaltTiles, Overlays, Statuettes, Glasses, Respirators, Aprons, Gloves, Headphones, Overalls, DryCuts, Saws, Catalogs, PackagingMaterials, Drills, Crosses, Others, Cleaner, Stupki, Maps, Paintings, Souvenirs } from '../../../logic/const/constants';
import { useState } from 'react';
import IconButton from '../IconButton/button';

const Catalog = ({ isOpen, onClose }) => {

    const [selectedCategory, setSelectedCategory] = useState(BronzeProducts)

    const setCategory = (category) => {
        setSelectedCategory(category)
    }

    return (
        <>
            <div className={`catalog-background ${!isOpen ? 'close' : ''}`} onClick={onClose}>
            </div>
            {/* <div className={`catalog-pc ${!isOpen ? 'close' : ''}`}>

                <div className="list" gridArea={'A'}>
                    <div className="title"><Link to={`catalog?category=${BronzeProducts}`} onClick={onClose}>Вироби з бронзи, латуні</Link></div>
                    <ul>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Crosses}`} onClick={onClose}>Хрести</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Roses}`} onClick={onClose}>Троянди</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Faces}`} onClick={onClose}>Лики</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Crucifixes}`} onClick={onClose}>Розп'яття</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Tridents}`} onClick={onClose}>Тризуби</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Frames}`} onClick={onClose}>Рамки</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Letters}`} onClick={onClose}>Букви</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Statuettes}`} onClick={onClose}>Статуетки</Link></li>
                        <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Overlays}`} onClick={onClose}>Накладки</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'B'}>
                    <div className="title"><Link to={`catalog?category=${PolishingProducts}`} onClick={onClose}>Інструменти для полірування та шліфування</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Plastic}`} onClick={onClose}>Пластмаса</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Scraps}`} onClick={onClose}>Обдіри</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Velcro}`} onClick={onClose}>Липучки</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Buffs}`} onClick={onClose}>Баффи</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Felts}`} onClick={onClose}>Войлоки</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Matrices}`} onClick={onClose}>Матриці,Основи</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Layers}`} onClick={onClose}>Шарошки</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Fickerts}`} onClick={onClose}>Фікерти</Link></li>
                        <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${DiamondCutter}`} onClick={onClose}>Фреза алмазні</Link></li>

                    </ul>
                </div>
                <div className="list" gridArea={'C'}>
                    <div className="title"><Link to={`catalog?category=${ForArtisticWorks}`} onClick={onClose}>Для художніх робіт</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Points}`} onClick={onClose}>Спиці побідітові</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Butchers}`} onClick={onClose}>Закольники, бучарди</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Chisels}`} onClick={onClose}>Скарпелі</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${EngravingMachines}`} onClick={onClose}>Гравірувальні машинки</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Beets}`} onClick={onClose}>Бурики, голки алмазні</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Sharpeners}`} onClick={onClose}>Чертилки</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${DiamondSharpeningWheels}`} onClick={onClose}>Алмазні круги для заточки</Link></li>
                        <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${DiamondDrills}`} onClick={onClose}>Алмазні сверла</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'D'}>
                    <div className="title"><Link to={`catalog?category=${ForArtisticWorks}`} onClick={onClose}>Хімія</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${Perm}&subCategory=${Glue}`} onClick={onClose}>Клея, герметики</Link></li>
                        <li><Link to={`catalog?category=${Perm}&subCategory=${Impregnations}`} onClick={onClose}>Пропітки</Link></li>
                        <li><Link to={`catalog?category=${Perm}&subCategory=${Crystallizers}`} onClick={onClose}>Кристалізатори та лаки</Link></li>
                        <li><Link to={`catalog?category=${Perm}&subCategory=${Polishes}`} onClick={onClose}>Поліролі, Віск</Link></li>
                        <li><Link to={`catalog?category=${Perm}&subCategory=${Powder}`} onClick={onClose}>Порошок</Link></li>
                        <li><Link to={`catalog?category=${Perm}&subCategory=${Paint}`} onClick={onClose}>Фарба</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'A'}>
                    <div className="title"><Link to={`catalog?category=${DecorativeProductsGranite}`} onClick={onClose}>Декоративні вироби з граніту</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Vases}`} onClick={onClose}>Вази</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Spheres}`} onClick={onClose}>Шари</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Lamps}`} onClick={onClose}>Лампадки</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${CrossesGranite}`} onClick={onClose}>Хрести</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Balls}`} onClick={onClose}>М'ячі</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Corners}`} onClick={onClose}>Кути</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Books}`} onClick={onClose}>Книги</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Icons}`} onClick={onClose}>Ікони</Link></li>
                        <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${BasaltTiles}`} onClick={onClose}>Плитки базальтові</Link></li>

                    </ul>
                </div>
                <div className="list" gridArea={'B'}>
                    <div className="title"><Link to={`catalog?category=${PersonalEquipment}`} onClick={onClose}>Засоби індивідального захисту</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Glasses}`} onClick={onClose}>Окуляри</Link></li>
                        <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Respirators}`} onClick={onClose}>Респіратори</Link></li>
                        <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Aprons}`} onClick={onClose}>Фартухи</Link></li>
                        <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Gloves}`} onClick={onClose}>Рукавиці</Link></li>
                        <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Headphones}`} onClick={onClose}>Навушники, Бірюши</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'C'}>
                    <div className="title"><Link to={`catalog?category=${Tools}`} onClick={onClose}>Інструменти</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${Tools}&subCategory=${DryCuts}`} onClick={onClose}>Сухорізи, флянці</Link></li>
                        <li><Link to={`catalog?category=${Tools}&subCategory=${Saws}`} onClick={onClose}>Пили</Link></li>
                        <li><Link to={`catalog?category=${Tools}&subCategory=${Drills}`} onClick={onClose}>Сверла, коронки, струпцини</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'D'}>
                    <div className="title"><Link to={`catalog?category=${Others}`} onClick={onClose}>Інше</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${Others}&subCategory=${Catalogs}`} onClick={onClose}>Каталоги</Link></li>
                        <li><Link to={`catalog?category=${Others}&subCategory=${PackagingMaterials}`} onClick={onClose}>Матеріали для пакування</Link></li>
                    </ul>
                </div>
            </div> */}
            <div className={`catalog-mobile ${!isOpen ? 'close' : ''}`}>
                <div class="links">
                    <p>DIATOOLS</p>
                    <div className="links-wrapper">
                        <a href="viber://chat?number=+380665109085"><IconButton width={'24px'} height={'24px'} iconUrl={'/icons/viber-icon-dark.svg'} /></a>
                        <a href="https://instagram.com/dia.tools?igshid=OGQ5ZDc2ODk2ZA=="><IconButton width={'24px'} height={'24px'} iconUrl={'/icons/inst-icon-dark.svg'} /></a>
                    </div>
                </div>

                <div className="catalog-wrapper">
                    <div className="title-column">
                        <div className="title" onClick={() => setCategory(BronzeProducts)}>Вироби з бронзи, латуні</div>
                        <div className="title" onClick={() => setCategory(PolishingProducts)}>Інструменти для полірування та шліфування</div>
                        <div className="title" onClick={() => setCategory(ForArtisticWorks)}>Для художніх робіт</div>
                        <div className="title" onClick={() => setCategory(Perm)}>Хімія</div>
                        <div className="title" onClick={() => setCategory(DecorativeProductsGranite)}>Декоративні вироби з граніту</div>
                        <div className="title" onClick={() => setCategory(PersonalEquipment)}>Засоби індивідального захисту</div>
                        <div className="title" onClick={() => setCategory(Tools)}>Інструменти</div>
                        <div className="title" onClick={() => setCategory(Others)}>Інше</div>
                        <div className="title page-link" onClick={() => setCategory(Others)}>
                            <Link to={`delivery`} onClick={onClose}>Доставка і оплата</Link>
                        </div>
                        <div className="title page-link" onClick={() => setCategory(Others)}>
                            <Link to={`contacts`} onClick={onClose}>Контакти</Link>
                        </div>
                    </div>
                    <div className="list" style={{ display: selectedCategory == BronzeProducts && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Crosses}`} onClick={onClose}>Хрести</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Roses}`} onClick={onClose}>Троянди</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Faces}`} onClick={onClose}>Лики</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Crucifixes}`} onClick={onClose}>Розп'яття</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Tridents}`} onClick={onClose}>Тризуби</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCateghory=${Frames}`} onClick={onClose}>Рамки</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Letters}`} onClick={onClose}>Букви</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Statuettes}`} onClick={onClose}>Статуетки</Link></li>
                            <li><Link to={`catalog?category=${BronzeProducts}&subCategory=${Overlays}`} onClick={onClose}>Накладки</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == PolishingProducts && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Plastic}`} onClick={onClose}>Пластмасса</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Scraps}`} onClick={onClose}>Обдіри</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Velcro}`} onClick={onClose}>Липучки</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Buffs}`} onClick={onClose}>Баффи</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Felts}`} onClick={onClose}>Войлоки</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Matrices}`} onClick={onClose}>Матриці,Основи</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Layers}`} onClick={onClose}>Шарошки</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${Fickerts}`} onClick={onClose}>Фікерти</Link></li>
                            <li><Link to={`catalog?category=${PolishingProducts}&subCategory=${DiamondCutter}`} onClick={onClose}>Фреза алмазні</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == ForArtisticWorks && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Points}`} onClick={onClose}>Спиці побідітові</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Butchers}`} onClick={onClose}>Закольники, бучарди</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Chisels}`} onClick={onClose}>Скарпелі</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${EngravingMachines}`} onClick={onClose}>Гравірувальні машинки</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Beets}`} onClick={onClose}>Бурики, голки алмазні</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${Sharpeners}`} onClick={onClose}>Чертилки</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${DiamondSharpeningWheels}`} onClick={onClose}>Алмазні круги для заточки</Link></li>
                            <li><Link to={`catalog?category=${ForArtisticWorks}&subCategory=${DiamondDrills}`} onClick={onClose}>Алмазні сверла</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == Perm && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Glue}`} onClick={onClose}>Клея, герметики</Link></li>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Impregnations}`} onClick={onClose}>Пропітки</Link></li>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Crystallizers}`} onClick={onClose}>Кристалізатори та лаки</Link></li>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Polishes}`} onClick={onClose}>Поліролі, Віск</Link></li>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Powder}`} onClick={onClose}>Порошок</Link></li>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Paint}`} onClick={onClose}>Фарба</Link></li>
                            <li><Link to={`catalog?category=${Perm}&subCategory=${Cleaner}`} onClick={onClose}>Очищувачі</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == DecorativeProductsGranite && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Vases}`} onClick={onClose}>Вази</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Spheres}`} onClick={onClose}>Шари</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Lamps}`} onClick={onClose}>Лампадки</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${CrossesGranite}`} onClick={onClose}>Хрести</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Balls}`} onClick={onClose}>М'ячі</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Corners}`} onClick={onClose}>Кути</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Books}`} onClick={onClose}>Книги</Link></li>

                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Icons}`} onClick={onClose}>Ікони</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${BasaltTiles}`} onClick={onClose}>Плитки базальтові</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Stupki}`} onClick={onClose}>Ступки та цукерниці</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Maps}`} onClick={onClose}>Карти</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Paintings}`} onClick={onClose}>Картини</Link></li>
                            <li><Link to={`catalog?category=${DecorativeProductsGranite}&subCategory=${Souvenirs}`} onClick={onClose}>Сувеніри</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == PersonalEquipment && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Glasses}`} onClick={onClose}>Окуляри</Link></li>
                            <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Respirators}`} onClick={onClose}>Респіратори</Link></li>
                            <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Aprons}`} onClick={onClose}>Фартухи</Link></li>
                            <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Gloves}`} onClick={onClose}>Рукавиці</Link></li>
                            <li><Link to={`catalog?category=${PersonalEquipment}&subCategory=${Headphones}`} onClick={onClose}>Навушники, Бірюши</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == Tools && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${Tools}&subCategory=${DryCuts}`} onClick={onClose}>Сухорізи, флянці</Link></li>
                            <li><Link to={`catalog?category=${Tools}&subCategory=${Saws}`} onClick={onClose}>Пили</Link></li>
                            <li><Link to={`catalog?category=${Tools}&subCategory=${Drills}`} onClick={onClose}>Сверла, коронки, струпцини</Link></li>
                        </ul>
                    </div>
                    <div className="list" style={{ display: selectedCategory == Others && 'block' }}>
                        <ul>
                            <li><Link to={`catalog?category=${Tools}&subCategory=${Catalogs}`} onClick={onClose}>Каталоги</Link></li>
                            <li><Link to={`catalog?category=${Tools}&subCategory=${PackagingMaterials}`} onClick={onClose}>Матеріали для пакування</Link></li>
                        </ul>
                    </div>

                </div>
            </div>
        </>

    )
}

export default Catalog;