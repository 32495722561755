import './style.scss';

const PrimaryButton = ({width, height, text}) => {
    return (
        <>
            <button className="component-button primary-button" style={{width: width, height: height}}>
                {text}
            </button>
        </>
    )
}

export default PrimaryButton;