//Category
export const BronzeProducts = "bronzeProducts";
export const PolishingProducts = "polishingProducts";
export const ForArtisticWorks = "forArtisticWorks";
export const Perm = "perm";
export const DecorativeProductsGranite = "decorativeProductsGranite";
export const PersonalEquipment = "personalEquipment";
export const Tools = "tools";
export const Others = "others";

//SubCategory BronzeProducts

export const Crosses = "crosses";
export const Roses = "roses";
export const Faces = "faces";
export const Crucifixes = "crucifixes";
export const Tridents = "tridents";
export const Frames = "frames";
export const Letters = "letters";
export const Statuettes = "statuettes";
export const Overlays = "overlays"

export const BronzeProductsForFilterBlock = [
    [Crosses, "Хрести"],
    [Roses, "Троянди"],
    [Faces, "Лики"],
    [Crucifixes, "Розп'яття"],
    [Tridents, "Тризуби"],
    [Frames, "Рамки"],
    [Letters, "Букви"],
    [Statuettes, "Статуетки"],
    [Overlays, "Накладки"]
];

//SubCategory PolishingProducts

export const Plastic = "plastic";
export const Scraps = "scraps";
export const Velcro = "velcro";
export const Buffs = "buffs";
export const Felts = "felts";
export const Matrices = "matrices";
export const Layers = "layers";
export const Fickerts = "fickerts";
export const DiamondCupCutter = "diamondCupCutter";
export const DiamondCutter = "diamondCutter";

export const PolissingProductsForFilterBlock = [
    [Plastic, "Пластмасса"],
    [Scraps, "Обдіри"],
    [Velcro, "Липучки"],
    [Buffs, "Баффи"],
    [Felts, "Войлоки"],
    [Matrices, "Матриці, основи"],
    [Layers, "Шарошки"],
    [Fickerts, "Фікерти"],
    [DiamondCutter, "Фреза алмазні"]
];

//SubCategory ForArtisticWorks

export const Points = "points";
export const Butchers = "butchers";
export const Chisels = "chisels";
export const EngravingMachines = "engravingMachines";
export const Beets = "beets";
export const Sharpeners = "sharpeners";
export const DiamondSharpeningWheels = "diamondSharpeningWheels";
export const DiamondDrills = "diamondDrills";

export const ForArtisticWorksForFilterBlock = [
    [Points, "Шпиці побідітові"],
    [Butchers, "Закольники, бучарди"],
    [Chisels, "Скарпелі"],
    [EngravingMachines, "Гравірувальні машинки"],
    [Beets, "Бурики, голки алмазні"],
    [Sharpeners, "Чертилки"],
    [DiamondSharpeningWheels, "Алмазні круги для заточки"],
    [DiamondDrills, "Алмазні сверла"]
];

//SubCategory Perm

export const Glue = "glue";
export const Impregnations = "impregnations";
export const Crystallizers = "crystallizers";
export const Polishes = "polishes";
export const Powder = "powder";
export const Paint = "paint";
export const Cleaner = "cleaner";

export const PermForFilterBlock = [
    [Glue, "Клея, герметики"],
    [Impregnations, "Пропітки"],
    [Crystallizers, "Кристалізатори та лаки"],
    [Polishes, "Поліролі"],
    [Powder, "Порошок"],
    [Paint, "Фарба"],
    [Cleaner, "Очищувачі"],
];

//SubCategory DecorativeProductsGranite
export const Vases = "vases";
export const Spheres = "spheres";
export const Lamps = "lamps";
export const CrossesGranite = "crosses";
export const Balls = "balls";
export const Corners = "corners";
export const Books = "books";
export const Icons = "icons";
export const BasaltTiles = "basaltTiles";
export const Stupki = "stupki";
export const Maps = "maps";
export const Paintings = "paintings";
export const Souvenirs = "souvenirs";

export const DecorativeProductsGraniteForFilterBlock = [
    [Vases, "Вази"],
    [Spheres, "Шари"],
    [Lamps, "Лампадки"],
    [CrossesGranite, "Хрести"],
    [Balls, "М'ячі"],
    [Corners, "Кути"],
    [Books, "Книги"],
    [Icons, "Ікони"],
    [BasaltTiles, "Плитки базальтові"],
    [Stupki, "Ступки та цукерниці"],
    [Maps, "Карти"],
    [Paintings, "Картини"],
    [Souvenirs, "Сувеніри"],
];

//SubCategory PersonalEquipment
export const Glasses = "glasses";
export const Respirators = "respirators";
export const Aprons = "aprons";
export const Gloves  = "gloves";
export const Headphones = "headphones";
export const Overalls = "overalls";

export const PersonalEquipmentForFilterBlock = [
    [Glasses, "Окуляри"],
    [Respirators, "Распіратори"],
    [Aprons, "Фартухи"],
    [Gloves, "Рукавиці"],
    [Headphones, "Навушники, бірюши"]
];

//SubCategory Tools
export const DryCuts = "dryCuts";
export const Saws = "saws";

export const Drills = "drills";

export const ToolsForFilterBlock = [
    [DryCuts, "Сухорізи, флянці"],
    [Saws, "Пили"],
    [Drills, "Сверла, коронки, струпцини"]
];


//Others
export const Catalogs = "catalogs";
export const PackagingMaterials  = "packagingMaterials";

export const OthersForFilterBlock = [
    [Catalogs, "Каталоги"],
    [PackagingMaterials, "Матеріали для упаковки"]
];


