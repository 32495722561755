import React, { useEffect, useState } from 'react';
import './style.scss';
import ProductCart from '../ProductCart/product-cart';
import { CatalogData } from '../../../../data/catalog';
import { CartData } from '../../../../data/cart-content';

const ProductContainer = ({ products, cartData, setCartData }) => {
    const [renderItems, setRenderItems] = useState([]);

    const AddProductToCart = (object) => {
        let index = cartData.findIndex(x => x.id == object.id);
        debugger
        if (index != -1) {
            var newCart = [...cartData];
            newCart[index].count += 1
            setCartData(newCart)
        }else{
            setCartData(cartData => [...cartData, object]);
        }
    }

    const renderItem = () => {
        let renderProducts = products.map(x => {
            return <><ProductCart id={x.id} urlImg={x.urlImg} price={x.price} name={x.name} sizes={x.sizes} addProductToCart={AddProductToCart} /></>
        });
        setRenderItems(renderProducts)
    }

    useEffect(() => {
        renderItem()
    }, [products])

    return (
        <>
            <div className="product-container">
                {renderItems.length == 0 ? <div>У цій категорії немає товарів</div> : renderItems}
            </div>
        </>
    )
}

export default ProductContainer;