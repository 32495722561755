import React from 'react';
import './styles/style.css';
import MainPage from './pages/MainPage/main';
import Header from './components/HeaderComponent/header';
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import Footer from './components/FooterComponent/footer';
import CatalogPage from './pages/CatalogPage/catalog';
import DetailProduct from './pages/DetailProductCart/detail-product';
import OrderPage from './pages/OrderPage/order-page';
import { useState } from 'react';
import ContactsPage from './pages/ContactsPage/main';
import DeliveryPage from './pages/DeliveryPage/main';
import ThanksPage from './pages/ThanksPage/main';



function App() {

  const [cartData, setCartData] = useState([])

  return (
    <BrowserRouter>
      <div className="body">
        <Header cartData={cartData} setCartData={setCartData}/>
        <Routes>
          <Route path="/" element={<MainPage></MainPage>} />
          <Route path="/catalog" element={<CatalogPage cartData={cartData} setCartData={setCartData}></CatalogPage>} />
          <Route path="/catalog/:id" element={<DetailProduct cartData={cartData} setCartData={setCartData}></DetailProduct>} />
          <Route path="/order-confirm" element={<OrderPage cartData={cartData} setCartData={setCartData}></OrderPage>} />
          <Route path="/contacts" element={<ContactsPage cartData={cartData} setCartData={setCartData}></ContactsPage>} />
          <Route path="/delivery" element={<DeliveryPage cartData={cartData} setCartData={setCartData}></DeliveryPage>} />
          <Route path="/thanks-page" element={<ThanksPage cartData={cartData} setCartData={setCartData}></ThanksPage>} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
